import { postAction, getAction, queryImage } from './method'

export default {
    // 根据用户文件id查询文件
    userFileDetail(id: string) {
        return getAction('/star-doc/document/userFile/detail?id=' + id)
    },
    // 图片预览
    userFilePreview(data: any) {
        return queryImage('get', '/star-doc/file/preview', {}, data)
    },
    /* 收藏 */
    // 新增文件收藏
    fileCollectionAdd(data: any) {
        return postAction('/star-doc/document/fileCollection/add', data)
    },
    // 删除收藏的文件
    fileCollectionDelete(data: any) {
        return postAction('/star-doc/document/fileCollection/cancel', data)
    },
    // 文件收藏列表查询
    fileCollectionPageList(data: any) {
        return postAction('/star-doc/document/fileCollection/pageList', data)
    },
}