import { getAction, postAction, queryImage } from './method'

export default {
    // 取消分享
    shareCancel(data: any, header: any) {
        return postAction('/star-doc/document/share/cancel', {}, data, header)
    },
    // 创建分享
    shareCreateShare(data: any, header: any) {
        return postAction('/star-doc/document/share/createLink', data, {}, header)
    },
    // 查询下级文件
    shareNextFiles(data: any, header: any) {
        return postAction('/star-doc/document/share/nextFiles', {}, data, header)
    },
    // 查询分享文件
    sharePageShareFiles(data: any, header: any) {
        return postAction('/star-doc/document/share/pageFiles', data, {}, header)
    },
    // 查询分享列表
    sharePageShare(data: any, header: any) {
        return postAction('/star-doc/document/share/pageList', data, {}, header)
    },
    // 查询分享信息
    shareQueryShare(data: any, header: any) {
        return postAction('/star-doc/document/share/queryShare', {}, data, header)
    },
    // 记录分享操作
    shareRecord(data: any, header: any) {
        return postAction('/star-doc/document/share/record', data, {}, header)
    },
    // 更新分享
    shareUpdate(data: any, header: any) {
        return postAction('/star-doc/document/share/update', data, {}, header)
    },
    // 分享图片预览
    sharePreview(data: any) {
        return getAction('/star-doc/file/share/preview', data)
    },
}