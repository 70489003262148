import { message } from 'ant-design-vue'
import { DeepClone } from '@/interface/index'


message.config({
  duration: 3, // 自动关闭的延时，单位秒。设为 0 时不自动关闭。	默认3
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
});


export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9
    ? '早上好'
    : hour <= 11
      ? '上午好'
      : hour <= 13
        ? '中午好'
        : hour < 20
          ? '下午好'
          : '晚上好'
}

export function welcome() {
  const arr = [
    '休息一会儿吧',
    '准备吃什么呢?',
    '要不要打一把 DOTA',
    '我猜你可能累了',
  ]
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 获取UUID
 * @param {number} len UUID长度
 * @param {number} radix UUID类型，8：八进制，10：十进制，16：十六进制
 * @returns {string}
 */
export function uuid(len = 8, radix = 10) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
    ''
  )
  let uuid = [],
    i
  radix = radix || chars.length
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    let r
    // GUID 的格式为“xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx” 36位
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}

/** 防抖吐司
 * @param {string} key 吐司类型 参考antd-vue message
 * @param {string} text 吐司文本内容
 *
 */
let messageModle: any = message
export function messages() {
  let timer: any = null
  let isOk: Boolean = true
  return (key: string, text: string) => {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      isOk = true
      timer = null
    }, 2000)
    if (isOk) {
      isOk = false
      messageModle[key](text)
    }
  }
}
/**
 * 深拷贝
 * @param {any} obj
 * @return newObj
 */
function deepClone(obj: any) {
  let result: any = Array.isArray(obj) ? [] : {};
  let ownProperty = [...Object.keys(obj), ...Object.getOwnPropertySymbols(obj)];
  for (let i in ownProperty) {
    if (obj.hasOwnProperty(ownProperty[i])) {
      if (typeof obj[ownProperty[i]] === 'object' && obj[ownProperty[i]] != null) {
        result[ownProperty[i]] = deepClone(obj[ownProperty[i]]);
      } else {
        result[ownProperty[i]] = obj[ownProperty[i]];
      }
    }
  }
  return result;
}

/**
 * @deepClone 深拷贝对象
 * @param target 目标
 */
function deepCloneObj(target: any) {
  let root: any = {}
  let stack: DeepClone[] = []
  stack.push({
    parent: root,
    key: undefined,
    data: target,
  })
  while (!stack.length) {
    let node = stack.pop()
    let { data, key, parent }: any = node

    let res = parent
    if (typeof key !== 'undefined') {
      // 不是第一次就放到子元素
      res = parent[key] = {}
    }
    for (let k in data) {
      if (data.hasOwnProperty(k)) {
        if (isObject(data[k])) {
          stack.push({
            parent: res,
            key: k,
            data: data[k],
          })
        } else {
          res[k] = data[k]
        }
      }
    }
  }
  return root
}

const isObject = function (params: any) {
  return typeof params === 'object' && params != null
}


/**
 * @formateArr 处理树结构数组 (左侧侧边栏)
 * @param arr 数组
 *
 */
export function formateArr(arr: any) {
  arr = arr.map((item: any) => {
    return {
      id: item.docId, // 文件或文件夹id
      pId: item.pid, // 父id
      value: item.relationId, // 关系relationId
      title: item.docName, // 文案
      perId: item.permissionId, //权限id
    }
  })
  return arr
}

/** 格式化用户操作权限
 * @param {Array} arr 需要处理得数组
 * @param {string} relationId 判断是不是项目文件夹
*/

export function permissions(arr: any[] = [], relationId: string) {
  arr.forEach((v: any) => {
    let edits: any[] = []
    if (relationId == '1') { // 项目文件夹
      if (v.permissionD > 0) edits.push({ text: '设置权限', method: 'permission' })
      if (v.permissionId > 3) {
        edits.push({ text: '删除', method: 'delete' })
        edits.push({ text: '归档', method: 'archived' })
      }
    } else {
      if (v.permissionD > 0) edits.push({ text: '设置权限', method: 'permission' })
      if (v.permissionId > 0) edits.push({ text: '在线预览', method: 'preview' })
      if (v.permissionId > 1) {
        edits.push({ text: '下载', method: 'downLoad' })
        edits.push({ text: '分享链接', method: 'share' })
      }
      if (v.permissionId > 2) {
      }
      if (v.permissionId > 3) {
        edits.push({ text: '移至', method: 'move' })
        edits.push({ text: '删除', method: 'delete' })
        edits.push({ text: '复制', method: 'copy' })
      }
    }
    v.edits = edits
  })
}

export function setParentId(data: Array<any>) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i]
    if (item.userList) {
      item.userList.forEach((element: any) => {
        element.parentId = item.id
        element.key = element.username
        element.title = element.realname
      });
      item.children = item.children || []
      item.children.push(...item.userList)
      item.children.forEach((i: any) => {
        if (i.id) {
          i.isLeaf = false
        }
        i.scopedSlots = { title: 'title' }
        i.key = i.key || i.username
        i.title = i.title || i.realname
      })
      setParentId(item.children)
    } else {
      return true
    }
  }
}
/**
 *  @description 表单校验
 *  @author fuzhaoyang
 */
interface FileValidateProps {
  name: string;
  value: string;
  pattern?: RegExp;
  msg: string;
  length?: number;
  exclude?: [];
}
export function fileNameValidate(fileProps: FileValidateProps) {
  const {
    value,
    name,
    pattern,
    msg = `名称不允许包含特殊字符(空格?[]:*<>/|\+-。？！，、；：“”‘’（）《》〈〉【】『』「」﹃﹄〔〕…—～﹏￥）`,
    length = 25,
    exclude = []
  } = fileProps
  //排除文件特殊字符正则
  const regExp = pattern || /^[^\s\[\]\?\:\*\<\>\/\|\|\\\+|\t|\-\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5|\u3000|\u00A0]*$/;
  console.log(value)
  if (!value) {
    message.warning(`${name}不允许为空`)
    return false;
  }
  if (!regExp.test(value) || value.indexOf(`"`) != -1 || value.indexOf(`“`) != -1) {
    message.error(msg)
    return false;
  }
  for (let i = 0; i < exclude.length; i++) {
    if (value.indexOf(exclude[i]) != -1) {
      message.error(msg)
      return false;
    }
  }
  if (length && value.length > length) {
    message.error(`${name}长度不能超过${length}字符`)
    return false;
  }
  return true;
}

/**
 * 节流
 * @param {Function} func 要执行的回调函数 
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
let timer: any, flag: boolean;
export function throttle(func: any, wait = 300, immediate = true) {
  if (immediate) {
    if (!flag) {
      flag = true;
      // 如果是立即执行，则在wait毫秒内开始时执行
      typeof func === 'function' && func();
      timer = setTimeout(() => {
        flag = false;
      }, wait);
    }
  } else {
    if (!flag) {
      flag = true
      // 如果是非立即执行，则在wait毫秒内的结束处执行
      timer = setTimeout(() => {
        flag = false
        typeof func === 'function' && func();
      }, wait);
    }
  }
};

/**
 * 防抖
 * @param {Function} func 要执行的回调函数 
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行 
 * @return null  
 */
let timeout: any;
export function Debounce(func: any, wait = 300, immediate = false) {
  // 清除定时器
  if (timeout !== null) clearTimeout(timeout);
  // 立即执行，此类情况一般用不到
  if (immediate) {
    var callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = null;
    }, wait);
    if (callNow) typeof func === 'function' && func();
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function () {
      typeof func === 'function' && func();
    }, wait);
  }
}




/* 
 * 生成唯一标识
 * @return string  
*/
export function creatClientId(): string {
  let now = Date.now();
  var d = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return now + "-" + uuid;
}

/* 水印 */
export function canvasWM({
  // 使用 ES6 的函数默认值方式设置参数的默认取值
  // 具体参见 https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Functions/Default_parameters
  container = null,
  width = "518px",
  height = "426px",
  textAlign = "center",
  textBaseline = "middle",
  font = "64px microsoft yahei",
  fillStyle = "#4f5555",
  content = "暂未开放",
  rotate = 10,
  zIndex = 1000 } = {}): void {
  var canvas = document.createElement("canvas");
  canvas.setAttribute("width", width);
  canvas.setAttribute("height", height);
  var ctx: any = canvas.getContext("2d");
  var rectCenterPoint = {x: parseFloat(width) / 2, y: parseFloat(height) / 2}; // 矩形中心点
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.font = font;
  ctx.fillStyle = fillStyle;

  ctx.translate(rectCenterPoint.x, rectCenterPoint.y);
  ctx.rotate((Math.PI / 180) * rotate);
  ctx.translate(-rectCenterPoint.x, -rectCenterPoint.y);
  ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2);
  var base64Url = canvas.toDataURL();
  var exist: any = document.getElementById("watermark");
  if (exist) {
    exist.parentElement.removeChild(exist);
  }
  const watermarkDiv = document.createElement("div");
  watermarkDiv.setAttribute("id", "watermark");
  watermarkDiv.setAttribute(
    "style",
    `
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:${zIndex};
    pointer-events:none;
    background-repeat:repeat;
    background-image:url('${base64Url}')`
  );
  (container as any).insertBefore(watermarkDiv, (container as any).firstChild);
}




export default {
  uuid,
  deepClone,
  deepCloneObj,
  messages: messages(),
  setParentId,
  formateArr
}

// /**
//  * 触发 window.resize
//  */

// export function handleScrollHeader(callback: any) {
//   let timer = 0;

//   let beforeScrollTop = window.pageYOffset;
//   // tslint:disable-next-line
//   callback = callback || function() {};
//   window.addEventListener(
//     "scroll",
//     event => {
//       clearTimeout(timer);
//       timer = setTimeout(() => {
//         let direction = "up";
//         const afterScrollTop = window.pageYOffset;
//         const delta = afterScrollTop - beforeScrollTop;
//         if (delta === 0) {
//           return false;
//         }
//         direction = delta > 0 ? "down" : "up";
//         callback(direction);
//         beforeScrollTop = afterScrollTop;
//       }, 50);
//     },
//     false
//   );
// }

// /**
//  * Remove loading animate
//  * @param id parent element id or class
//  * @param timeout
//  */
// export function removeLoadingAnimate(id = "", timeout = 1500) {
//   if (id === "") {
//     return;
//   }
//   setTimeout(() => {
//     const el = document.getElementById(id);
//     if (el) {
//       document.body.removeChild(el);
//     }
//   }, timeout);
// }
