import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import { Modal } from "ant-design-vue";
import { ROUTER_PROJECT, ROUTER_BASE } from "@/common/utils/const";
import store from "@/store";
// @ts-ignore
import layout from "@/components/layouts/index.vue";
import UserLayout from "@/components/layouts/UserLayout.vue";
import apis from "@/apis/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    // redirect: { name: "login" },
    // redirect: { name: "TEST" },
    redirect: { name: "star" },
  },
  // {
  //   path: "/test",
  //   name: "TEST",
  //   component: () => import("@/views/test/index.vue"),
  // },
  {
    path: "/user",
    component: UserLayout,
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/User/Login.vue"),
      },
      {
        path: "privateRegister",
        name: "private-register",
        component: () => import("@/views/User/PrivateRegister.vue"),
      },
      {
        path: "enterpriseRegister",
        name: "enterprise-register",
        component: () => import("@/views/User/EnterpriseRegister.vue"),
      },
      {
        path: "retrievePassword",
        name: "retrievePassword-register",
        component: () => import("@/views/User/RetrievePassword.vue"),
      },
    ],
  },
  {
    path: "/about",
    component: () => import("@/views/about/index.vue"),
    redirect: { name: "home" },
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/about/home.vue"),
      },
      {
        path: "deploy",
        name: "deploy",
        component: () => import("@/views/about/deploy.vue"),
      },
      {
        path: "scenario",
        name: "scenario",
        component: () => import("@/views/about/scenario.vue"),
      },
    ],
  },
  {
    path: "/star",
    name: "star",
    component: layout,
    redirect: "star/mydoc",
    children: [
      {
        path: "mydoc",
        name: "VIEW_MY_DOCUMENT",
        component: () => import("@/views/mydoc/index.vue"),
      },
      {
        path: "knowledgeBase",
        name: "VIEW_KNOWLEDGEBASE",
        component: () => import("@/views/knowledgeBase/index.vue"),
      },
      // 编辑知识库
      {
        path: "editKnowledge",
        name: "editKnowledge",
        component: () => import("@/views/editKnowledge/index.vue"),
      },
      {
        path: "share",
        name: "VIEW_SHARE",
        component: () => import("@/views/Share/index.vue"),
      },
      {
        path: "cooperation",
        name: "VIEW_COOPERATION",
        component: () => import("@/views/Cooperation/index.vue"),
      },
      {
        path: "companyTeam",
        name: "VIEW_COMPANYTEAM",
        component: () => import("@/views/companyTeam/index.vue"),
        redirect: "companyTeam/table",
        children: [
          {
            path: "table",
            name: "VIEW_COMPANYTEAM_TABLE",
            component: () =>
              import("@/views/companyTeam/components/CompanyTeamTable.vue"),
          },
          {
            path: "document",
            name: "VIEW_COMPANYTEAM_DOCUMENT",
            component: () =>
              import("@/views/companyTeam/components/TeamDocument.vue"),
          },
        ],
      },
      {
        path: "recycle",
        name: "VIEW_RECYCLE_BIN",
        component: () => import("@/views/recycle/index.vue"),
      },
      {
        path: "favorite",
        name: "VIEW_MY_FAVORITE",
        component: () => import("@/views/Favorite/index.vue"),
      },
      {
        path: "fileImport",
        name: "VIEW_FILEIMPORT",
        component: () => import("@/views/FileImport/index.vue"),
      },
      {
        path: "attachment",
        name: "VIEW_ATTACHMENT",
        component: () => import("@/views/Attachment/index.vue"),
      },
      {
        path: "searchResult",
        name: "SEARCH_MY_DOCUMENT",
        component: () => import("@/views/SearchResult/index.vue"),
      },
      {
        path: "messageAll",
        name: "VIEW_MESSAGE_NOTICE",
        component: () => import("@/views/MessageAll/index.vue"),
      },
      {
        path: "tag",
        name: "VIEW_FILE_TAG",
        component: () => import("@/views/tagFile/index.vue"),
      },
      {
        path: "menu",
        name: "menu",
        component: () => import("@/views/System/index.vue"),
        redirect: "menu/userinfo",
        children: [
          {
            path: "userinfo",
            name: "VIEW_ACCOUNT_SECURITY",
            component: () => import("@/views/System/Userinfo/index.vue"),
          },
          {
            path: "loginRecord",
            name: "VIEW_LOGIN_RECORD",
            component: () => import("@/views/System/loginRecord/index.vue"),
          },
          {
            path: "feedback",
            name: "VIEW_FEEDBACK",
            component: () => import("@/views/System/Feedback/index.vue"),
          },
          {
            path: "task",
            name: "VIEW_TASK",
            component: () => import("@/views/System/Task/index.vue"),
          },
          {
            path: "announcement",
            name: "VIEW_ANNOUNCEMENT",
            component: () => import("@/views/AnnouncementAll/index.vue"),
          },
        ],
      },
      {
        path: "404",
        name: "404",
        component: () => import("@/views/exception/NoPage.vue"),
      },
    ],
  },
  {
    path: "/officeOnline",
    name: "VIEW_OFFICE_ONLINE",
    component: () => import("@/views/officeOnline/index.vue"),
  },
  {
    path: "/fileShare",
    name: "fileShare",
    component: () => import("@/views/Share/fileShare.vue"),
  },
  // 嵌套文件预览页面的
  {
    path: "/viewFile",
    name: "VIEW_File",
    component: () => import("@/views/viewFile/index.vue"),
  },

  // PDF编辑页
  {
    path: "/pdfEdit",
    name: "PDF_EDIT",
    component: () => import("@/views/pdfEdit/index.vue"),
  },
  // md编辑器页
  {
    path: "/mdEditor",
    name: "MD_EDITOR",
    component: () => import("@/views/mdEditor/index.vue"),
  },
  {
    path: "/knowledgeQuestionsAndAnswers",
    name: "knowledgeQuestionsAndAnswers",
    component: () => import("@/views/knowledgeQuestionsAndAnswers/index.vue"),
  },

  // ai创建文档的
  {
    path: "/AiCreationDocment",
    name: "VIEW_AiCreationDocment",
    component: () => import("@/views/AiCreationDocment/index.vue"),
  },
  {
    path: "/teamInvited",
    name: "teamInvited",
    component: () => import("@/views/TeamInvited/index.vue"),
  },
  {
    path: "/cooperationInvited",
    name: "cooperationInvited",
    component: () => import("@/views/Cooperation/CooperationInvited.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("@/views/Guide/index.vue"),
  },
  // {
  //   path: "/404",
  //   name: "404",
  //   redirect: "star/404",
  //   component: () => import("@/views/exception/NoPage.vue"),
  // },
  {
    path: "*",
    redirect: { name: "404" },
  },
];

const router = new VueRouter({
  // mode: "hash",
  mode: "history",
  // base: (window as any).__POWERED_BY_QIANKUN__ ? "/star-pan-web" : "/",
  base: "/star-pan-web",
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("进入了路由配置");
  nprogress.start();
  Modal.destroyAll();
  next();

  // debugger
  // // 移动端设备拦截引导页
  // if ((store.state as any).common.navigator !== 'Pc' && !to.path.includes('officeOnline') && !to.path.includes('guide') && !to.path.includes('fileShare')) {
  //   next({ path: "/guide" });
  //   // next();
  // } else {
  //   nprogress.start();
  //   Modal.destroyAll();
  //   // 单点登录进入页面
  //   if (window.location.href.includes('loginType=sso')) {
  //     apis.getCode().then((res: any) => {
  //       if (res.code === "200") {
  //         window.location.href = res.data;
  //       }
  //     });
  //   } else {
  //     // 获取用户令牌
  //     let token = (store.state as any).user.token;
  //     // const token= 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiI0NDQ1MTU1ODc4ODkwNDk2MDAiLCJleHAiOjE2OTUxMTM1MjUsImV4dEluZm8iOiJ7XCJjcmVhdGVUaW1lXCI6MTY4Mzc4ODc3NTAwMCxcImNyZWF0ZWRCeVwiOlwic3lzdGVtXCIsXCJkZXNjcmlwdGlvblwiOlwiXCIsXCJpZFwiOlwiNDQ0NTE1NTg3ODg5MDQ5NjAwXCIsXCJsYXN0TG9naW5JcFwiOlwiMTkyLjE2OC4xMDEuNVwiLFwibGFzdExvZ2luVGltZVwiOjE2OTQ4NDkwODMwMDAsXCJsb2dpbklkXCI6XCIxODU3MTgxMDk1OFwiLFwibWFpbFwiOlwiemhhbmduYWluY2hpQGNzaWkuY29tLmNuXCIsXCJuaWNrbmFtZVwiOlwi5byg5b-16amwXCIsXCJvcGVuaWRcIjpcIlwiLFwicGhvbmVcIjpcIjE4NTcxODEwOTU4XCIsXCJyZW1hcmtcIjpcIlwiLFwic3RhdHVzXCI6XCJlbmFibGVcIixcInVwZGF0ZVRpbWVcIjoxNjk0ODQ5MDgzMDAwLFwidXBkYXRlZEJ5XCI6XCLlvKDlv7XpqbBcIixcInVzZXJPcmlnaW5UeXBlXCI6XCJwZXJzb25cIn0ifQ.c5nU_iVv0ILnWlhiEcKMRrHDvP8nePrGjtxAPfgz7T4'
  //     let functionCodes = (store.state as any).user.functionCodes
  //     // 令牌存在跳过登录页面去到首页
  //     if (token) {
  //       if (to.path === "/user/login") {
  //         next({ path: "/star" });
  //         nprogress.done();
  //       } else
  //       // 功能码校验,不存在则跳转404
  //       // if (functionCodes.includes(to.name) || !to.name?.startsWith("VIEW")) {
  //       //   next()
  //       {

  //         // 调试微应用暂时注释
  //         // next({ name: "404" });
  //         // nprogress.done();

  //         next()

  //       }

  //     } else {
  //       // 单点登录重定向会携带code
  //       if (window.location.search.includes('code')) {
  //         let code = window.location.search.split('=')[1]
  //         // 进行code校验登录
  //         apis.thirdPartylogin({ code }).then((res: any) => {
  //           if (res.code === '200') {
  //             console.log(res)
  //             sessionStorage.setItem(
  //               "X-Access-Token",
  //               JSON.stringify(res.data)
  //             );
  //             localStorage.setItem("userId", res.data.userId);
  //             // store.commit('user/SET_USER', res.data)
  //             store.dispatch('user/GET_USERINFO', 'userinfo')
  //             window.location.replace(window.location.origin)
  //           }
  //         })
  //       }
  //       // @ts-ignore
  //       else if (to.path.startsWith("/star") && !window.__POWERED_BY_QIANKUN__) {
  //         // next({ path: "/user/login" });
  //         // nprogress.done();

  //         next();
  //       } else {
  //         next();
  //       }
  //     }
  //   }
  // }
});

router.afterEach((to, from) => {
  Modal.destroyAll();
  nprogress.done();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: string) {
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
