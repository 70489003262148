import { User } from "@/interface/index";
import { message } from "ant-design-vue";
import router from "@/router";
import apis from "@/apis";
import { trace } from "console";

const user = {
  namespaced: true,
  state: {
    isAudit: 0, // 0-否｜1-是
    isAdminFlag: false, // 是否是管理员
    personalMailboxEnterprise: false, // 是否是个人企业
    openOrNotStarDocFlag: true, // 用户是否开通了云文档
    capacityTimer: null, // 容量定时器的引用
    showAicreationFlag: false,
    container: null,
    projectInfo: null,
    token: "",
    aicreationmodulType: "",
    userId: "",
    name: "",
    email: "",
    welcome: "",
    avatar: "",
    lastLoginTime: "",
    phone: "",
    avatarImage: "",
    roles: [],
    info: {},
    functionCodes: [],
    personTotalStorage: "",
    personUseStorage: "",
    personStorageRate: 0,
    companyTotalStorage: "",
    companyUseStorage: "",
    companyStorageRate: 0,
    companyList: [],
    companyId: "person",
    accountPoint: "",
  },

  mutations: {
    setIsAuditValue(state: any, value: any) {
      state.isAudit = value;
    },

    startTimer(state: any) {
      if (state.capacityTimer) {
        return;
      }
      console.log("启用容量定时器");
      state.capacityTimer = setInterval(() => {
        apis.getUserCapacity().then((res: any) => {
          let list = res.data;
          (state.personTotalStorage = list.userCapacity),
            (state.personUseStorage = list.usedUseCapacity),
            (state.personStorageRate = list.userRatio),
            (state.companyTotalStorage = list.companyCapacity),
            (state.companyUseStorage = list.usedCompanyCapacity),
            (state.companyStorageRate = list.companyRatio);
        });
      }, 600000);
    },
    stopTimer(state: any) {
      if (state.capacityTimer) {
        console.log("销毁容量定时器");
        clearInterval(state.capacityTimer);
      }
      state.capacityTimer = null;
    },

    setShowAicreationFlag(state: any, value: any) {
      state.showAicreationFlag = value;
    },
    setProjectInfo(state: any, value: any) {
      state.projectInfo = value;
    },
    setAicreationmodulType(state: any, data: string) {
      state.aicreationmodulType = data;
    },
    setContainer(state: any, value: any) {
      state.container = value;
    },
    // LOG_OUT: (state: User): void => {
    //   // 清除token
    //   sessionStorage.removeItem("X-Access-Token")
    //   localStorage.removeItem('userId')
    //   state.token = ''
    //   state.userId = ''
    //   state.name = ''
    //   state.email = ''
    //   state.phone = ''
    //   state.lastLoginTime = ''
    //   state.functionCodes = []
    //   state.personTotalStorage = ''
    //   state.personUseStorage = ''
    //   state.personStorageRate = 0
    //   state.companyTotalStorage = ''
    //   state.companyUseStorage = ''
    //   state.companyStorageRate = 0
    //   state.avatarImage = ''
    //   state.companyId = ''
    //   state.accountPoint = ''
    // },

    // 文档个人容量和企业容量设置
    SET_CAPACITY(state: User, status: any) {
      (state.personTotalStorage = status.userCapacity),
        (state.personUseStorage = status.usedUseCapacity),
        (state.personStorageRate = status.userRatio),
        (state.companyTotalStorage = status.companyCapacity),
        (state.companyUseStorage = status.usedCompanyCapacity),
        (state.companyStorageRate = status.companyRatio);
    },

    SET_USER: async (state: User, payload: any): Promise<void> => {
      state.token = payload.X_Access_Token;
      state.userId = payload.login_userInfo.id;
      // state.name = payload.login_userInfo.username

      state.name = payload.login_userInfo.realname;

      state.email = payload.login_userInfo.email;
      state.phone = payload.login_userInfo.phone;
      (state.companyId = payload.tenant_Id),
        (state.companyList = payload.userTenants);

      // 1723928882215653377这个企业id是个人邮箱企业
      if (
        state.companyId == "1723928882215653377" ||
        state.companyId == "1705104022261792769"
      ) {
        state.personalMailboxEnterprise = true;
      } else {
        state.personalMailboxEnterprise = false;
      }

      // 下面这些主应用里没有，后续要去其他地方拿
      state.lastLoginTime = payload.lastLoginTime;
      state.functionCodes = payload.functionCodes;
      // state.personTotalStorage = payload.personTotalStorage
      // state.personUseStorage = payload.personUseStorage
      // state.personStorageRate = payload.personStorageRate
      // state.companyTotalStorage = payload.companyTotalStorage
      // state.companyUseStorage = payload.companyUseStorage
      // state.companyStorageRate = payload.companyStorageRate
      state.avatarImage = payload.avatarImage ? payload.avatarImage : "";
      state.accountPoint = payload.accountPoint;
      if (payload.userAppMenu && payload.userAppMenu.length) {
        state.openOrNotStarDocFlag = payload.userAppMenu.some(
          (i: { app: { appCode: any } }) => i.app.appCode == "star_pan_web"
        );
      }

      try {
        const res: any = await apis.userRole({}, {});
        // console.log("🚀初始化时获得的，我是管理员~ res:", res)
        if (res && res.data && res.data.isAdmin) {
          console.log("初始化时获得的，我是管理员");
          state.isAdminFlag = true;
        } else {
          state.isAdminFlag = false;
        }
      } catch (error) {
        console.log("是否是管理员权限获取失败", error);
      }
    },
  },

  actions: {
    async_setIsAuditValue(store: any) {
      apis
        .getIsAuditApi()
        .then((res) => {
          console.log("获取是否开启审核--res", res);
          store.commit("setIsAuditValue", res.data);
        })
        .catch((err) => {
          console.log("获取是否开启审核失败", err);
        });
    },

    GET_CAPACITY(store: any, companyId: string) {
      apis
        .getUserCapacity()
        .then((res: any) => {
          // console.log('用户容量信息',res.data);
          store.commit("SET_CAPACITY", res.data);
        })
        .finally(() => {});
    },

    USER_KEEP: (store: any): void => {
      // debugger
      // 初始化获取用户信息
      // let userInfo = sessionStorage.getItem('X-Access-Token')

      // 嵌入主应用了之后-没有了之前自己的用户体系了,去主站里拿其他信息组合其中一部分能用的，其余的走接口等
      function getValueFromLocalStorage(key: string): string | undefined {
        const proValue = localStorage.getItem(key);
        return proValue
          ? (JSON.parse(proValue) as { value: string }).value
          : undefined;
      }

      let userAppMenu: any[] = JSON.parse(
        localStorage.getItem("userAppMenu") || "[]"
      );

      const userInfo = {
        mailAcc: getValueFromLocalStorage("pro__mailAcc"),
        userTenants: getValueFromLocalStorage("pro__USERTENANTS"),
        tenant_Id: getValueFromLocalStorage("pro__TENANT_ID"),
        X_Access_Token: getValueFromLocalStorage("pro__Access-Token"),
        login_userInfo: getValueFromLocalStorage("pro__Login_Userinfo"),
        mailAccList: getValueFromLocalStorage("pro__MailAccList"),
        // appLang: getValueFromLocalStorage('LANG'),
        appLang: localStorage.getItem("LANG"),
        userAppMenu: userAppMenu,

        // 缺失的暂时跳过
      };

      // let mailAcc=JSON.parse(sessionStorage.getItem('X-Access-Token'))

      // 存储到vuex
      if (userInfo) {
        try {
          // userInfo = JSON.parse(userInfo)
          store.commit("SET_USER", userInfo);
          // store.dispatch('GET_USERINFO')
        } catch (error) {
          console.error(error);
        }
      } else {
        // debugger
        // if (router.currentRoute.path.startsWith('/star') && router.currentRoute.path !== '/') {
        //   message.warning("登录超时，请重新登录!");
        //   // 登出
        //   store.commit("LOG_OUT");
        //   // 去到登录页 并保存当前页面的路径方便登录重新返回
        //   router.push({
        //     name: "login",
        //     query: {
        //       path: router.currentRoute.fullPath,
        //     },
        //   });
        // }
      }
    },

    GET_COMPANYID: (store: any, userId: string): void => {
      // 调试微应用，暂时置空
      store.commit("SET_COMPANYLIST", []);
      // apis.companyList().then((res: any) => {
      //   if (res.code === '200') {
      //     store.commit('SET_COMPANYLIST', res.data)
      //   }
      // })
    },
  },
};

export default user;
