import { getAction, postAction, postActionStream, queryImage } from './method'

export default {


    // // 邮件往来附件的转存到个人文档
    // AttachmentFilesCopy(data: any, header: any) {
    //     return postAction('/studio/mail-client-api/mailInfo/savePersonFile', data, {}, header)
    // },


    // // 邮件账户列表
    // getMailMD5(data: any) {
    //     return getAction(`/studio/mail-client-api/mailInfo/getMD5?_t=${Date.now()}`, data)
    // },

    //  ai创建文档前掉认证用户接口
    getAuth() {
        return getAction(`/studio//ai-api/studio_chat/auth?_t=${Date.now()}`,)
        // return getAction(`//ai-api/studio_chat/auth?_t=${Date.now()}`,)
    },

    //  ai创建文档调用ai接口
    aicreateDoc(data: any, header: any, callback: Function) {

        return postAction('/studio//ai-api/chat_message/send', data, {}, header, callback)
    },

    //  ai创建文档调用ai接口
    saveCreateAiFile(data: any, header: any, ) {    
        return postAction('/star-doc/document/userFile/create', data, {}, header)
    },






    // // 邮件往来附件
    // userAttachmentFiles(data: any, header: any) {
    //     return postAction('/studio/mail-client-api/mailInfo/listAttachment', data, {}, header)
    // },

    // // 邮件账户列表
    // getMailAddress() {
    //     return getAction(`/studio/mail-client-api/mailAccount/listAll?_t=${Date.now()}`,)
    // },



}