/* assets/images下的图标 */
export const iconDoc = require("@/assets/images/word.png");
export const iconDefault = require("@/assets/images/default.png");
export const iconImg = require("@/assets/images/Img.png");
export const iconFolder = require("@/assets/images/folder.png");
export const iconMV = require("@/assets/images/MV.png");
export const iconZIP = require("@/assets/images/zip.png");
export const iconXls = require("@/assets/images/Excel.png");
export const iconPdf = require("@/assets/images/PDF.png");
export const iconPpt = require("@/assets/images/PPT.png");
export const iconText = require("@/assets/images/txt.png");

export const iconImage = require("./imgs/icon-image.png");
export const iconMusic = require("./imgs/icon-music.png");
export const iconOther = require("./imgs/icon-other.png");
export const iconCode = require("./imgs/icon-code.png");
export const iconVideo = require("./imgs/icon-video.png");
export const iconZip = require("./imgs/icon-zip.png");
export const folder = require("./imgs/folder.png");
export const filesIcon = require("@/assets/images/files.png");

export const gotoDocument = require("@/assets/icon/goto-document.png");
export const inform = require("@/assets/icon/inform.png");
export const regain = require("@/assets/icon/regain.png");
export const completeDel = require("@/assets/icon/completeDel.png");

/* 菜单图标 */
export const document = require("@/assets/icon/document.png");
export const share = require("@/assets/icon/share.png");
export const recently = require("./imgs/recently.png");
export const cooperation = require("@/assets/icon/cooperation.png");
export const recycle = require("@/assets/icon/recycle.png");
export const menuTeam = require("@/assets/icon/menu-team.png");
export const menuLoginRecord = require("@/assets/icon/menu-login-record.png");
export const menuFeedback = require("@/assets/icon/menu-feedback.png");
export const menuTask = require("@/assets/icon/menu-task.png");
export const cloud = require("@/assets/icon/cloud.png");
export const favorite = require("@/assets/icon/favorite.png");
export const knowledgeBase = require("./imgs/knowledgeBase.png");

/* 文件上传图表图标 */
export const uploader = require("@/assets/icon/uploader.png");
export const suspend = require("@/assets/icon/suspend.png");
export const goFolder = require("@/assets/icon/folder.png");
export const deleteFile = require("@/assets/icon/delete.png");
export const again = require("@/assets/icon/again.png");
export const details = require("@/assets/icon/details.png");

/* 其他图标 */
export const fileDetails = require("@/assets/icon/fileDetails.png");
export const announcement = require("@/assets/icon/announcement.png");
export const tagPlus = require("@/assets/icon/tag-plus.png");
export const tag = require("@/assets/icon/tag.png");
export const check = require("@/assets/icon/check.png");
export const feedback = require("@/assets/icon/feedback.png");
export const feedbackMenu = require("@/assets/icon/feedback-menu.png");
export const save = require("@/assets/icon/save.png");
export const download = require("@/assets/icon/download.png");
export const team = require("@/assets/icon/team.png");
export const person = require("@/assets/icon/person.png");
export const enterprise = require("@/assets/icon/enterprise.png");
export const set = require("@/assets/icon/set.png");

/* 积分任务图标 */
export const taskLogin = require("@/assets/icon/task-login.png");
export const taskShare = require("@/assets/icon/task-share.png");
export const taskUserinfo = require("@/assets/icon/task-userinfo.png");
export const task = require("@/assets/icon/task.png");
