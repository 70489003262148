const aiSearch = {
  state: {
    recText: '',
    connState: null,
    disabled: false
  },
  mutations: {
    SET_SEARCH_RECTEXT: (state: any, value: any) => {
      state.recText = value
    },
    SET_SEARCH_CONNSTATE: (state: any, value: any) => {
      state.connState = value
    },
    SET_SEARCH_DISABLED: (state: any, value: any) => {
      state.disabled = value
    }
  },
  getters: {
    getAiSearchRecText(state: any) {
      return state.recText
    },
    getAiSearchConnState(state: any) {
      return state.connState
    },
    getAiSearchDisabled(state: any) {
      return state.disabled
    }
  }
}

export default aiSearch