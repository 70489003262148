
import Vue from "vue";
import HeaderSearch from "./HeaderComponents/HeaderSearch.vue";
import HeaderUser from "./HeaderComponents/HeaderUser.vue";
import HeaderDocumentTool from "./HeaderComponents/HeaderDocumentTool.vue";
import Announcement from "./HeaderComponents/Announcement.vue";
import FeedbackModal from "./HeaderComponents/FeedbackModal.vue";
import Preload from "@/components/Preload/index.vue";
export default Vue.extend({
  name: "LayoutHeader",
  components: {
    HeaderSearch,
    HeaderDocumentTool,
    HeaderUser,
    Announcement,
    FeedbackModal,
    Preload,
  },
  props: {},
  data() {
    return {
      messageList: [],
      feedbackVisible: false,
      okVisible: false,
    };
  },
  computed: {
    // 企业列表
    companyList(): any[] {
      return this.$store.state.user.companyList;
    },
    companyId(): string {
      // 调试微应用 暂时写死为person
      //  return 'person'
      return this.$store.state.user.companyId;
    },
    // 显示企业
    userCompany(): string {
      let company = this.companyList.find((i) => i.id === this.companyId);
      return this.companyId === "person" ? "个人文档" : company?.companyName;
    },
  },
  created() {
    // this.queryAnnouncement();
  },
  mounted() {},
  methods: {
    // selectDocmentTool(val:any){
    //   this.$emit('selectDocmentTool',val)
    // },

    handleLogo() {
      this.$router.push({ path: "/star" });
    },
    // 切换企业
    changeCompany(company: any) {
      if (company.id === this.companyId) return;
      this.$apis
        .companyUpdateDefault({ companyId: company.id })
        .then(async (res: any) => {
          if (res.code === "200") {
            // await this.$store.dispatch("user/GET_USERINFO", "userinfo");
            this.$message.success(
              `操作成功，个人文档、协作、分享、收藏等数据即将切换到【${
                company.id === "person" ? "个人文档" : company.companyName
              }】`
            );
            setTimeout(() => {
              location.replace(`${location.origin}/#/star/mydoc`);
              window.location.reload();
            }, 500);
          }
        });
    },
    // 公告获取
    // queryAnnouncement() {
    //   this.$apis
    //     .bulletinPageList({
    //       isPage: 1,
    //     })
    //     .then((res: any) => {
    //       if (res.code === "200") {
    //         this.messageList = res.data.list;
    //         this.$nextTick(() => {
    //           (this.$refs as any)?.Announcement?.announcementModalShow();
    //         });
    //       }
    //     });
    // },
    // 意见反馈
    handleFeedback() {
      this.feedbackVisible = true;
    },
    // 意见反馈提交
    feedbackOk() {
      this.feedbackVisible = false;
      this.okVisible = true;
      if (this.$route.fullPath === "/star/menu/feedback?type=system") {
        this.$store.commit("common/SET_TRIGGERFEEDBACKQUERY");
      }
    },
    // 去到反馈列表
    goTOfeedbackList() {
      this.okVisible = false;
      this.$router.push({
        path: "/star/menu/feedback",
        query: { type: "system" },
      });
    },
  },
});
