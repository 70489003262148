import apis from "@/apis/index"

interface Menu {
    move: boolean
    moveCoordinate: any
    transferShow: boolean
    watchCoordinate: number
    commonFileList: Array<any>
    tagList: Array<any>
    teamTagList: Array<any>
    tagLoading: boolean
    modalType: string
}
const menu = {
    namespaced: true,
    state: {
        // 文件移动
        move: false,
        // 移动区域坐标
        moveCoordinate: {},
        // 传输记录显示
        transferShow: false,
        watchCoordinate: 0,
        // 常用列表
        commonFileList: [],
        // 标签列表
        tagList: [],
        // 团队标签列表
        teamTagList: [],
        // 标签加载
        tagLoading: false,
        // 标签类型
        modalType: 'doc'
    },

    mutations: {
        SET_WATCHCOORDINATE(state: Menu) {
            state.watchCoordinate++
        },
        SET_MOVE(state: Menu, status: boolean) {
            state.move = status
        },
        SET_TRANSFERSHOW(state: Menu, status: boolean) {
            state.transferShow = status
        },
        SET_MOVECOORDINATE(state: Menu, status: any) {
            state.moveCoordinate = status
        },
        SET_COMMONFILELIST(state: Menu, status: any) {
            state.commonFileList = status
        },
        SET_TAGLOADING(state: Menu, status: boolean) {
            state.tagLoading = status
        },
        SET_TAGLIST(state: Menu, status: any) {
            state.tagList = status
        },
        SET_TEAMTAGLIST(state: Menu, status: any) {
            state.teamTagList = status
        },
        SET_MODALTYPE(state: Menu, status: any) {
            state.modalType = status
        },
    },

    actions: {
        GET_COMMONFILELIST(store: any) {
            apis.commonFilePageList().then((res: any) => {
                if (res.code === '200') {
                    store.commit('SET_COMMONFILELIST', res.data.list)
                }
            })
        },
        GET_TAGLIST(store: any) {
            if(store.state.modalType === 'doc') store.commit('SET_TAGLOADING', true)
            apis.documentTagPageTag({ isPage: 0 }).then((res: any) => {
                if (res.code === '200') {
                    store.commit('SET_TAGLIST', res.data.list)
                }
            }).finally(() => {
                store.commit('SET_TAGLOADING', false)
            })
        },
        GET_TEAMTAGLIST(store: any, companyId: string) {
            if(store.state.modalType === 'team') store.commit('SET_TAGLOADING', true)
            apis.documentTagPageTag({ isPage: 0, companyId }).then((res: any) => {
                if (res.code === '200') {
                    store.commit('SET_TEAMTAGLIST', res.data.list)
                }
            }).finally(() => {
                store.commit('SET_TAGLOADING', false)
            })
        },
    }
};

export default menu;
