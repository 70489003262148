import { getAction, postAction, queryImage } from './method'

export default {
    // 多个文件新增标签
    documentTagAddFilesTag(data: any, header?: any) {
        return postAction('/star-doc/document/tag/addFilesTag', data, {}, header)
    },
    // 新增标签
    documentTagAddTag(data: any, header?: any) {
        return postAction('/star-doc/document/tag/addTag', data, {}, header)
    },
    // 批量删除文件标签
    documentTagDelFileTags(data: any, header?: any) {
        return postAction('/star-doc/document/tag/delFileTags', {}, data, header)
    },
    // 批量删标签
    documentTagDelTagByIds(data: any, header?: any) {
        return postAction('/star-doc/document/tag/delTagByIds', data,{}, header)
    },
    // 标签分页查询
    documentTagPageTag(data: any, header?: any) {
        return postAction('/star-doc/document/tag/pageTag', data, {}, header)
    },
    // 标签文件分页查询
    documentTagFiles(data: any, header?: any) {
        return postAction('/star-doc/document/tag/tagFiles', data, {}, header)
    },
    // 查询文件标签
    documentTagQueryFileTag(data: any, header?: any) {
        return postAction('/star-doc/document/tag/queryFileTag', {}, data, header)
    },
    // 更新文件标签
    documentTagUpdateFileTag(data: any, header?: any) {
        return postAction('/star-doc/document/tag/updateFileTag', data, {}, header)
    },
    // 更新标签
    documentTagUpdateTag(data: any, header?: any) {
        return postAction('/star-doc/document/tag/updateTag', data, {}, header)
    }
}