import { Vue } from "vue-property-decorator";
import Component from 'vue-class-component';
import { namespace } from "vuex-class";
const UserModule = namespace("user");


@Component({})
export default class IconRender extends Vue {
    @UserModule.State("userId") userId!: string;

    public monitorList: any[] = []
    public index: number = 0
    public url: undefined | string = undefined
    public websock: any = null
    public lockReconnect: boolean = false


    // 获取websocket地址
    // async queryWebsocketGetUrl() {
    //     await this.$apis.websocketGetUrl().then((res: any) => {
    //         if (res.code === '200') {
    //             this.url = res.msg
    //         }
    //     })
    // }

    // 初始化
    initWebSocket() {
        if (this.url) {
            setTimeout(() => {
                console.log("------------WebSocket链接获取成功");
                // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
                var userId = this.userId
                // var url = `ws://172.30.15.60:8184/console/socketserver/${userId}`
                this.websock = new WebSocket(this.url + '/' + userId);
                this.websock.onopen = this.websocketOnopen;
                this.websock.onerror = this.websocketOnerror;
                this.websock.onmessage = this.websocketOnmessage;
                this.websock.onclose = this.websocketOnclose;
            },3000)
        } else {
            console.log("------------WebSocket链接获取失败");
            return false
        }
    }

    websocketOnopen() {
        console.log("WebSocket连接成功");
    }
    websocketOnerror(e: any) {
        console.log("WebSocket连接发生错误");
        this.reconnect();
    }
    websocketOnclose(e?: any) {
        console.log('WebSocket连接已关闭')
    }

    websocketOnmessage(res: any) {
        try {
            this.handleResponse(res)
        } catch (err) {
            console.log('响应数据结构错误')
        }
    }

    handleResponse(res: any) {

    }


    websocketSend(text: any) {
        // 数据发送
        try {
            this.websock.send(text);
        } catch (err) {
            // @ts-ignore
            console.log("send failed (" + err.code + ")");
        }
    }

    reconnect() {
        var that = this;
        if (that.lockReconnect) return;
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        setTimeout(function () {
            console.info("尝试重连...");
            that.initWebSocket();
            that.lockReconnect = false;
        }, 5000);
    }

    // 挂载
    async mounted() {
        // 与后台通信
        // await this.queryWebsocketGetUrl()
        this.initWebSocket();
    }
    // 销毁
    destroyed() {
        // 离开页面生命周期函数
        this.websocketOnclose();
    }
}