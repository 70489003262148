<template>
  <!-- <svg id="icon-svg" aria-hidden="true">
    <use :xlink:href="getXlink"></use>
  </svg> -->
  <!-- <i :class="getClass"></i> -->
  <!-- <a-icon :type="getClass" class="file-icon" /> -->
  <!-- <a-icon :style="{color}" class="svg-icon" :component="this[getComponent]" /> -->
  <img
    :class="className"
    :src="this[getComponent]"
    :style="this.$attrs.style ||'width:24px;vertical-align: middle;'"
    mode="scaleToFill"
    @click="$emit('click')"
  />
</template>

<script>
import * as ICON from './iconq'

export default {
  name: 'h-icon',
  props: {
    iconName: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ...ICON
    }
  },
  computed: {
    // getClass() {
    //   let suffix = this.type
    //   const cs =  {
    //       // 'anticon': true,
    //       // 'file-icon': true,
    //       // 'file': true,
    //       'file-markdown': ['md'].includes(suffix),
    //       'code': ['java','class','css','php','js','vue','ts','tsc'].includes(suffix),
    //       'file-excel': ['csv','xls', 'xlsx'].includes(suffix),
    //       'file-image': ['png', 'jpg', 'jpeg', 'bmp', 'gif'].includes(suffix),
    //       'file-pdf': ['pdf'].includes(suffix),
    //       'file-ppt': ['ppt', 'pptx'].includes(suffix),
    //       'video-camera': ['mpeg', 'mp3', 'mpeg-4', 'midi', 'wma', 'mpeg','mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'].includes(suffix),
    //       'file-word': ['doc', 'docx'].includes(suffix),
    //       'file-zip': ['zip','tar','gz','rar'].includes(suffix)
    //     }
    //     var key = 'file'
    //     Object.entries(cs).forEach((item)=> {if(item[1]===true){key=item[0]}})

    //   return key
    // }
    getComponent() {
      let suffix = this.type
      if(this.iconName){
        const key = ICON[this.iconName]
        return key ? this.iconName : 'folder'
      }
      const cs =  {
          'folder': ["folder"].includes(suffix),
          'iconText': ['txt'].includes(suffix),
          'iconCode': ['java','class','css','php','js','vue','ts','tsc', 'md', 'html','htm'].includes(suffix),
          'iconXls': ['csv', 'xls', 'xlsx'].includes(suffix),
          'iconImage': ['png','PNG', 'jpg','JPG', 'jpeg','JPEG', 'bmp', 'gif','raw','tif','psd','pdd','svg'].includes(suffix),
          'iconPdf': ['pdf'].includes(suffix),
          'iconPpt': ['ppt', 'pptx'].includes(suffix),
          'iconMusic': ['mp3','wma', 'wav'].includes(suffix),
          'iconVideo': ['mpeg','mpeg-4', 'midi', 'mpeg','mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v', 'rm'].includes(suffix),
          'iconDoc': ['doc', 'docx'].includes(suffix),
          'iconZip': ['zip','tar','gz','rar', 'apz', 'ar', 'bz'].includes(suffix),
        }
        let key='iconOther'
        Object.entries(cs).forEach((item)=> {if(item[1]===true){key=item[0]}})

      return key
    }

  }
}
</script>

<style scoped>
/* #icon-svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  } */
.file-icon {
  color: #1890ff;
  margin-right: 5px;
  font-size: 25px;
}

.svg-icon {
  margin-right: 5px;
  font-size: 24px;
  vertical-align: middle;
}
</style>
