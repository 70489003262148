import HIcon from './icon/index.vue'
// @ts-ignore
import CsiiIcon from './icon/csii-icon'

// import AIcon from './JIconSub.vue'





const someComponent: any = {
  HIcon,
  CsiiIcon,
}

export default someComponent
