<template>
  <div class="confirmationDialog-box">
    <!-- centered 垂直居中 -->
    <a-modal
      wrapClassName="confirmationDialog"
      :visible="showConfirmationDialog"
      :confirm-loading="confirmLoading"
      :footer="null"
      :maskClosable="false"
      :closable="false"
    >
      <div class="confirmationDialog-title">
        <div class="title-icon" v-if="type != 'createOrRename'">!</div>
        <span class="title-text">{{ title }}</span>
      </div>
      <div class="confirmationDialog-ModalText">
        {{ ModalText }}
      </div>
      <div class="input_value" v-if="type == 'createOrRename'">
        <a-input :maxLength="maxLength" v-model="ipt" placeholder="请输入内容">
          <template slot="suffix"> {{ ipt.length }}/{{ maxLength }} </template>
        </a-input>
      </div>
      <div class="confirmationDialog-btn">
        <a-button style="margin-right: 10px" @click="confirmationDialog_Cancel"
          >取消</a-button
        >
        <a-button
          type="primary"
          @click="confirmationDialog_Ok"
          :disabled="getDisabled()"
          >确定</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "confirmationDialog",
  props: {
    maxLength: {
      type: Number,
      default: () => {
        return 15;
      },
    },
    iptValue: {
      type: String,
      default: () => {
        return "";
      },
    },
    type: {
      type: String,
      default: () => {
        return "";
      },
    },
    title: {
      type: String,
      default: () => {
        return "提示";
      },
    },
    showConfirmationDialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    confirmLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    ModalText: {
      type: String,
      default: () => {
        return "确定要删除吗?";
      },
    },
  },
  data() {
    return {
      ipt: "",
      // visible: false,
      // confirmLoading:false,
      // ModalText: "确定要删除吗？",
    };
  },
  created() {
    this.ipt = this.iptValue;
  },
  watch: {
    showConfirmationDialog: {
      handler(newVal) {
        console.log("----newVal", newVal);
      },
      immediate: true,
    },
  },
  methods: {
    getDisabled() {
      if (this.type == "createOrRename") {
        let strRes = "";
        if (this.iptValue) {
          if (typeof this.iptValue !== "undefined") {
            const str = this.iptValue;
            strRes = str.replace(/\s+/g, "");
          }
        }
        if (strRes == this.ipt || !this.ipt.length) {
          return true;
        } else {
          return false;
        }
      }
    },
    confirmationDialog_Ok() {
      if (this.type == "createOrRename") {
        this.$emit("confirmationDialog_Ok", this.ipt);
      } else {
        this.$emit("confirmationDialog_Ok");
      }
    },
    confirmationDialog_Cancel() {
      this.$emit("confirmationDialog_Cancel");
      // this.showConfirmationDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .confirmationDialog {
  .confirmationDialog-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .title-icon {
      width: 22px;
      height: 22px;
      border-radius: 10px;
      margin-right: 18px;
      background-color: #f5222d;
      color: #fff;
      display: flex;
      font-size: 18px;
      align-items: center;
      justify-content: center;
    }
    .title-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      // line-height: 24px;
      text-align: left;
      font-style: normal;
    }
  }
  .confirmationDialog-ModalText {
    margin-left: 40px;
    margin-top: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
    // text-align: left;
    font-style: normal;
  }
  .confirmationDialog-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .ant-modal-content {
    border-radius: 8px !important;
    .ant-modal-body {
    }
  }
}
</style>
