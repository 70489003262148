
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TransferList from "./TransferList.vue";

const UploadModule = namespace("upload");
const UserModule = namespace("user");
@Component({
  name: "Transfer",
  components: {
    TransferList,
  },
  filters: {
    //局部过滤器
  },
})
export default class Transfer extends Vue {
  @UploadModule.Mutation("SET_UPLOADERLISTSHOW") setUploaderListShow!: (
    flag: boolean
  ) => void;
  @UserModule.State("functionCodes") functionCodes!: string[]

  // 当前点击类型
  public actionType: string = "upload";
  // 上传计数
  public uploadCount: string | number = 0;
  // 下载计数
  public downloadCount: string | number = 0;

  handleDeletAll(): void {
    let that = this;
    this.$confirm({
      title: "提示",
      content: "是否要删除该记录 ?",
      okText: "是",
      cancelText: "否",
      onOk() {
        that.$apis.taskHistoryDeleteAll({ taskType:"download" }).then((res: any) => {
          if (res.code === "200") {
            that.$message.success("删除成功");
            that.downloadListRef.queryList();
          }
        });
      },
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  get upload(): string {
    if (this.uploadCount === 0 || this.uploadCount === "") {
      return "文件上传";
    } else {
      return `文件上传（${this.uploadCount}）`;
    }
  }
  get download(): string {
    if (this.downloadCount === 0 || this.downloadCount === "") {
      return "文件下载";
    } else {
      return `文件下载（${this.downloadCount}）`;
    }
  }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("downloadList")
  downloadListRef!: any;
  //创建前钩子函数
  created(): void {
    // this.functionCodes.includes('UPLOAD_RECORD') ? this.actionType = 'upload' : this.actionType = 'download'
  }
}
