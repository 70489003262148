
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Progress } from "ant-design-vue";

const UserModule = namespace("user");
@Component({
  name: "UserModal",
  components: {
    [Progress.name]: Progress,
  },
  filters: {
    //局部过滤器
    first(value: string): string {
      return value.substr(0, 1);
    },
  },
})
export default class UserModal extends Vue {
  @UserModule.Mutation("LOG_OUT") logOut!: () => void;
  @UserModule.State("email") email!: string;
  @UserModule.State("name") name!: string;
  @UserModule.State("companyId") companyId!: string;
  @UserModule.State("personTotalStorage") personTotalStorage!: string;
  @UserModule.State("personUseStorage") personUseStorage!: string;
  @UserModule.State("personStorageRate") personStorageRate!: number;
  @UserModule.State("companyTotalStorage") companyTotalStorage!: string;
  @UserModule.State("companyUseStorage") companyUseStorage!: string;
  @UserModule.State("companyStorageRate") companyStorageRate!: number;
  @UserModule.State("accountPoint") accountPoint!: number;

  public actionList: any[] = [
    {
      title: "账号信息",
      menuType: "system",
      iconType: "iconfont",
      icon: "#icon-xinshourenwu",
      path: "/star/menu/userinfo",
    },
    {
      title: "登录记录",
      menuType: "system",
      iconType: "iconfont",
      icon: "#icon-xinshoujiaocheng",
      path: "/star/menu/loginRecord",
    },
    {
      title: "我的反馈",
      menuType: "system",
      iconType: "h-icon",
      icon: "feedbackMenu",
      path: "/star/menu/feedback",
    },
    {
      title: "积分任务",
      menuType: "system",
      iconType: "iconfont",
      icon: "#icon-wodezhanghu",
      path: "/star/menu/task",
    },
  ];

  linkTo(item: any) {
    this.$router.push({ path: item.path, query: { type: item.menuType } });
  }

  // 退出登录
  handleLogOut(): void {
    let that = this;
    this.$confirm({
      title: "提示",
      content: "真的要注销登录吗 ?",
      okText: "是",
      cancelText: "否",
      onOk() {
        that.$apis.logOut().then((res: any) => {
          if (res.code === "200") {
            that.$message.success("退出登录成功");
            // 登出
            that.logOut();
            // 去到登录页
            that.$router.push({
              name: "login",
            });
          }
        });
      },
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
