
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import menu from "./json/systemMenuData";

interface menu {
  type: string;
  title: string;
  menuList: any[];
}

@Component({
  name: "SystemMenu",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class SystemMenu extends Vue {
  public menu: menu = {
    type: "system",
    title: "个人中心",
    menuList: [],
  };
  // 返回主页面
  back(): void {
    this.$router.push({ path: "/star/mydoc" });
  }

  // 点击跳转
  routerChange(route: any): void {
    this.$router.push({ path: route.path, query: { type: route.menuType } });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  // get currentMenu(): void {
  //   return "";
  // }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("$route", { deep: true, immediate: true })
  handler(newVal: any) {
    this.menu = menu.find((i) => i.type === this.$route.query.type) as menu;
    this.$nextTick(() => {
      this.menu.menuList.forEach(
        (i: any) => (i.action = newVal.path.includes(i.path))
      );
    });
  }

  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
