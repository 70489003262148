
import '@/assets/css/base.less'
import apis from '@/apis/index'

console.log('apis',apis);


import util from './utils/util'
import filters from './utils/filters'
import md5 from './utils/md5'
import './public-path'
import Components from '@/components/index'


import JIconSub from '../components/JIconSub.vue'

import {
  Slider,
  Result,
  Button,
  Modal,
  Layout,
  Input,
  Menu,
  Icon,
  Table,
  Tag,
  Divider,
  Spin,
  TreeSelect,
  Form,
  Upload,
  List,
  Progress,
  Popover,
  Breadcrumb,
  Select,
  Col,
  Row,
  Tree,
  message,
  notification,
  FormModel,
  Tooltip,
  Checkbox,
  ConfigProvider,
  Dropdown,
  Radio,
  Empty,
  Card

} from 'ant-design-vue'
import { log } from 'console'

message.config({
  duration: 3, // 自动关闭的延时，单位秒。设为 0 时不自动关闭。	默认3
  maxCount: 5, // 最大显示数, 超过限制时，最早的消息会被自动关闭
});

notification.config({
  duration: 3, // 自动关闭的延时，单位秒。设为 0 时不自动关闭。
  top: '64px' // 消息从顶部弹出时，距离顶部的位置，单位像素。
})

const AntComponents = [
  Slider,
  Result,
  Button,
  Modal,
  Layout,
  Button,
  Input,
  Menu,
  Icon,
  Table,
  Tag,
  Divider,
  Spin,
  TreeSelect,
  Form,
  Upload,
  List,
  Progress,
  Popover,
  Breadcrumb,
  Select,
  Col,
  Row,
  Tree,
  FormModel,
  Tooltip,
  Checkbox,
  ConfigProvider,
  Dropdown,
  Radio,
  Empty,
  Card
]

export default {
  install(vue: any) {
    // 引入自定义全局组件
    Object.keys(Components).forEach((v) => {
      vue.component(v, Components[v])
    })

    vue.component('AIcon', JIconSub)
    vue.prototype.$message = message;
    vue.prototype.$notification = notification;
    vue.prototype.$confirm = Modal.confirm;
    vue.prototype.$warning = Modal.warning;
    // 按需注册antd全局组件，用到antd组件的时候，在此引入，无需在组件中单独引入了
    // 主要适用于标签组件
    for (let componentIndex = AntComponents.length; componentIndex--;) {
      vue.use(AntComponents[componentIndex])
    }

    const defineProperties: { [key: string]: any } = {
      $apis: {
        value: apis
      },
      $utils: {
        value: util
      },
      $md5: {
        value: md5
      }
    }
    Object.defineProperties(vue.prototype, defineProperties)

    Object.keys(filters).forEach((name) => {
      vue.filter(name, filters[name])
    })
  },
}
