import { Share } from "@/interface/index";

const share = {
  namespaced: true,
  state: {
    list: [], // 分享数据列表
    total: 0,
    shareUser: {}, // 分享人信息
    pathId: "",
    current: '', // 当前所在目录
    securityData: {
      // 分享验证码数据
      shareCode: undefined,
      shareDate: undefined, // 剩余有效时间
      relationId: undefined, // 分享根id
    },
    loginShow: false, // 分享页面登录框显示
    officeLoginShow: false, // 文档查看页面登录框显示
  },
  mutations: {
    SET_LIST: (state: Share, payload: any) => {
      state.list = payload;
    },
    SET_SHARE_USER: (state: Share, payload: any) => {
      state.shareUser = payload;
    },
    SET_TOTAL: (state: Share, payload: any) => {
      state.total = payload;
    },
    SET_SEC_DATA: (state: Share, securityData: {}) => {
      state.securityData = securityData;
    },
    SET_PATH_ID: (state: Share, pathId: string) => {
      state.pathId = pathId;
    },
    SET_CURRENT: (state: Share, payload: string) => {
      state.current = payload;
    },
    SET_LOGINSHOW: (state: Share, payload: boolean) => {
      state.loginShow = payload
    },
    SET_OFFICELOGINSHOW: (state: Share, payload: boolean) => {
      state.officeLoginShow = payload
    }

  },
  actions: {},
};
export default share;
