<template>
  <div class="documentTool">
    <template>
      <div>
        <!-- title="Title" -->
        <a-modal
          ref="docToolModalRefs"
          :wrapClassName="getWrapClassName()"
          :width="1100"
          id="docToolModar"
          :mask="maskFlag"
          :visible="docTransformDialogFlag"
          :footer="null"
          :confirm-loading="confirmLoading"
          :maskClosable="false"
          :closable="false"
        >
          <div class="content">
            <div class="title">
              <span class="txt">文档工具</span>
              <div class="img_box">
                <!-- v-if="allTransferStatus == 'transferIng'" -->
                <img
                  @click="minPage"
                  class="min"
                  style="width: 22px; height: 22px"
                  src="../../assets/images/documentTool/min.png"
                  alt=""
                />
                <img
                  @click="closeDocTool"
                  style="width: 20px; height: 20px"
                  src="../../assets/svg/close.svg"
                  alt=""
                />
              </div>
            </div>
            <a-row>
              <a-col class="content-height" :span="4">
                <div class="left border-right---">
                  <div class="ment-title">转换</div>
                  <!-- 右上角统一入口的情况 -->
                  <div
                    class="radio-group-prf-box"
                    v-if="docToolFrom == 'topRightMenu'"
                  >
                    <div
                      v-for="item in menuList.filter(
                        (item) => item.type === 'transform'
                      )"
                      @click="changeActiveMenu(item.name)"
                      :key="item.name"
                      :value="item.name"
                      class="item"
                      :class="activeMenu == item.name ? 'active' : ''"
                    >
                      <img
                        style="width: 24px; height: 25px"
                        :src="item.imgPath"
                        alt=""
                      />
                      <span class="menuList-title"> {{ item.text }}</span>
                    </div>
                  </div>

                  <!-- 文件右键菜单进来的情况 -->
                  <div
                    class="radio-group-prf-box"
                    v-if="docToolFrom == 'fileMenu'"
                  >
                    <div
                      v-for="item in menuList.filter(
                        (item) => item.name == activeMenu
                      )"
                      @click="changeActiveMenu(item.name)"
                      :key="item.name"
                      :value="item.name"
                      class="item"
                      :class="activeMenu == item.name ? 'active' : ''"
                    >
                      <img
                        style="width: 24px; height: 25px"
                        :src="item.imgPath"
                        alt=""
                      />
                      <span class="menuList-title"> {{ item.text }}</span>
                    </div>
                  </div>

                  <div class="ment-title" v-if="docToolFrom == 'topRightMenu'">
                    PDF处理
                  </div>
                  <div
                    class="radio-group-prf-box"
                    v-if="docToolFrom == 'topRightMenu'"
                  >
                    <div
                      v-for="item in menuList.filter(
                        (item) => item.type === 'pdfEdit'
                      )"
                      @click="changeActiveMenu(item.name)"
                      :key="item.name"
                      :value="item.name"
                      class="item"
                      :class="activeMenu == item.name ? 'active' : ''"
                    >
                      <img
                        style="width: 24px; height: 25px"
                        :src="item.imgPath"
                        alt=""
                      />
                      <span class="menuList-title"> {{ item.text }}</span>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col class="content-height" :span="20">
                <div class="right">
                  <div class="table-box">
                    <div class="table-hasContent" v-if="conversionFiles.length">
                      <div class="table-content">
                        <a-table
                          :rowKey="(record) => record.id"
                          :columns="columns"
                          :pagination="false"
                          :scroll="{ y: 347 }"
                          :data-source="datas"
                        >
                          <!-- 自定义操作清除待转换的文件 -->
                          <template slot="close" slot-scope="val, row">
                            <div
                              v-if="
                                row.status == 'await' ||
                                !row.status ||
                                row.status == 'FAIL'
                              "
                              @click="deleteFile(row)"
                              style="
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                              "
                            >
                              <a-icon type="close" />
                            </div>
                          </template>
                          <!-- 自定义文件名称 -->
                          <template slot="name" slot-scope="val, row">
                            <div style="display: flex; align-items: center">
                              <h-icon
                                class="list-img"
                                :type="row.extensionName"
                                alt=""
                              />

                              <a-tooltip
                                placement="bottom"
                                overlayClassName="doc-tool-table-toolTip"
                              >
                                <template slot="title">
                                  <span>{{ val }}.{{ row.extensionName }}</span>
                                </template>
                                <span
                                  style="margin-left: 10px"
                                  class="name-text"
                                  >{{ val }}.{{ row.extensionName }}</span
                                >
                              </a-tooltip>
                            </div>
                          </template>

                          <!-- 自定义位置 -->
                          <template slot="actionFilePath" slot-scope="val, row">
                            <div
                              style="
                                display: flex;
                                width: 100%;
                                align-items: center;
                              "
                            >
                              <a-tooltip
                                placement="bottom"
                                overlayClassName="doc-tool-table-toolTip"
                              >
                                <template slot="title">
                                  <span>{{ row.actionFilePath }}</span>
                                </template>
                                <span
                                  style="margin-left: 10px"
                                  class="name-text"
                                  >{{ row.actionFilePath }}</span
                                >
                              </a-tooltip>
                            </div>
                          </template>

                          <!-- 自定义状态名称 -->
                          <template slot="statusObj" slot-scope="val, row">
                            <div style="display: flex; align-items: center">
                              <span
                                :style="{ color: getStatusColor(row.status) }"
                                >{{ getStatusText(row.status) }}</span
                              >
                            </div>
                          </template>
                          <!-- 自定义预览结果文件 -->
                          <template slot="viewFile" slot-scope="val, row">
                            <div
                              v-if="row.status == 'CONVERTING'"
                              style="
                                display: flex;
                                align-items: center;
                                margin-left: -22px;
                                cursor: pointer;
                              "
                            >
                              <a-spin style="width: 20px; height: 20px" />
                            </div>
                            <div
                              v-if="row.status == 'FAIL'"
                              style="
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                              "
                            >
                              <img
                                @click="retry(row)"
                                style="width: 20px; height: 20px"
                                src="@/assets/svg/docToolReload.svg"
                              />
                            </div>
                            <div
                              style="
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                margin-left: -15px;
                              "
                              v-if="row.status == 'SUCCESS'"
                            >
                              <img
                                @click="viewFile(row)"
                                style="width: 20px; height: 20px"
                                src="../../assets/images/documentTool/viewFile/success.png"
                              />

                              <img
                                @click="downFile(row)"
                                style="
                                  width: 20px;
                                  height: 20px;
                                  margin-left: 10px;
                                "
                                src="../../assets/svg/down.svg"
                              />
                            </div>
                          </template>
                        </a-table>
                      </div>
                      <div class="addFileBox" @click="addDocFile">
                        <a-button
                          type="dashed"
                          :disabled="allTransferStatus == 'transferIng'"
                          class="addFileBtn"
                          block
                        >
                          <img
                            v-if="allTransferStatus == 'transferIng'"
                            style="width: 20px; height: 20px"
                            src="../../assets/svg/addFile-disabled.svg"
                            alt=""
                          />
                          <img
                            v-else
                            style="width: 20px; height: 20px"
                            src="../../assets/images/documentTool/addFile.png"
                            alt=""
                          />
                          <span
                            :class="
                              allTransferStatus == 'transferIng'
                                ? 'addFileBtn-txt'
                                : ''
                            "
                            style="margin-left: 10px"
                            >添加云文档文件</span
                          ></a-button
                        >
                      </div>
                    </div>
                    <div class="table-empty" v-else>
                      <div class="empty-top">
                        <img
                          style="width: 120px; height: 120px"
                          :src="getImagePath"
                          alt=""
                        />
                      </div>
                      <div class="empty-center">
                        <span>{{ getTitle }}</span>
                      </div>
                      <!-- 未添加内容时的底部内容 -->
                      <div class="empty-footer" @click="addDocFile">
                        <img
                          style="width: 20px; height: 20px"
                          src="../../assets/images/documentTool/addFile.png"
                          alt=""
                        />
                        <span class="empty-footer-txt">添加云文档文件</span>
                      </div>
                    </div>
                  </div>
                  <div class="output-area">
                    <div class="output-area-show" v-if="conversionFiles.length">
                      <div class="area-left">
                        <div class="top" v-if="activeMenu === 'PDF_Word'">
                          <span>输出格式</span>
                          <div style="margin-left: 10px">
                            <a-radio-group
                              name="radioGroup"
                              :disabled="allTransferStatus == 'transferIng'"
                              v-model="pdfToWordRadio"
                            >
                              <a-radio value="DOCX">DOCX</a-radio>
                              <a-radio value="DOC"> DOC</a-radio>
                            </a-radio-group>
                          </div>
                        </div>
                        <div class="footer" style="margin-top: 4px">
                          <span>输出路径</span>
                          <span style="margin-left: 10px"
                            >个人文档/PDF工具</span
                          >
                          <span
                            @click="openFolder"
                            class="open-folder"
                            style="margin-left: 15px"
                            v-if="allTransferStatus == 'allOver'"
                            >打开文件夹</span
                          >
                        </div>
                      </div>
                      <div class="area-right">
                        <a-button
                          :disabled="getTransferBtnDisabled()"
                          @click="filesConversion"
                          :style="getTransferBtnStyle()"
                          type="primary"
                          block
                          >{{ gettransferBtnText() }}</a-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-modal>
      </div>
    </template>

    <!-- 子组件 -->
    <!-- 文档工具弹窗 -->
    <addFile
      v-if="showAddFile"
      :showAddFile="showAddFile"
      :addFileType="addFileType"
      @closeAddFile="closeAddFile"
      @addFilesFn="addFilesFn"
    />
    <!-- 确认切换和关闭弹窗 -->
    <confirmationDialog
      v-if="showConfirmationDialog"
      :showConfirmationDialog="showConfirmationDialog"
      :ModalText="ModalText"
      @confirmationDialog_Cancel="confirmationDialog_Cancel"
      @confirmationDialog_Ok="confirmationDialog_Ok"
    />
  </div>
</template>

<script>
import { downFile } from "@/apis/method";
import addFile from "./components/addFile/index";
import confirmationDialog from "@/components/confirmationDialog/index";
import {
  docToolMenuList,
  publicFormatSize,
  publicFileView,
  downloadDocFile,
} from "@/utils/index";
export default {
  name: "documentTool",
  components: {
    addFile,
    confirmationDialog,
  },
  // props: ["showDocumentTool"],
  data() {
    return {
      maskFlag: true,
      ceshiFlag: false,
      addFileType: "converter", // converter--文档转换 / pdfEdit--pdf编辑
      pdfToWordRadio: "DOCX",
      // disabledAddFileFlag: false,
      menuList: [],
      datas: [],
      allTransferStatus: "allAwait",
      statusObj: [
        {
          text: "待转换",
          value: "await",
          color: "#333333",
        },
        {
          text: "转换中",
          value: "CONVERTING",
          color: "#3271FF",
        },
        {
          text: "转换失败",
          value: "FAIL",
          color: "#fd6d6e",
        },
        {
          text: "转换完成",
          value: "SUCCESS",
          color: "#6D6D6D",
        },
      ],
      transferBtnObj: [
        {
          text: "立即转换",
          color: "#fff",
          bgColor: "#3271ff",
          disabled: false,
          value: "allAwait",
        },
        {
          text: "转换中...",
          color: "#fff",
          bgColor: "#91b3fa",
          disabled: true,
          value: "transferIng",
        },
        {
          text: "转换完成",
          color: "#fff",
          bgColor: "rgba(0, 166, 96,0.4)",
          disabled: true,
          value: "allOver",
        },
      ],

      columns: [
        {
          width: 30,
          title: "",
          dataIndex: "close",
          ellipsis: true,
          scopedSlots: { customRender: "close" },
          align: "center",
        },
        {
          // width: 200,
          title: "文件名称",
          dataIndex: "userFileName",
          // ellipsis: true,
          scopedSlots: { customRender: "name" },
          // align: "center",
        },
        {
          // width: 240,
          title: "位置",
          dataIndex: "actionFilePath",
          class: "actionFilePath-doc",
          scopedSlots: { customRender: "actionFilePath" },
          // ellipsis: true,
          // align: "center",
        },
        {
          width: 90,
          title: "文件大小",
          dataIndex: "fileSize",
          // ellipsis: true,
          // align: "center",
        },
        {
          width: 90,
          title: "状态",
          dataIndex: "statu",
          // align: "center",
          scopedSlots: { customRender: "statusObj" },
        },
        {
          width: 110,
          title: "预览结果文件",
          dataIndex: "viewFile",
          align: "center",
          scopedSlots: { customRender: "viewFile" },
        },
      ],
      ConfirmationDialogType: "changeMenu", // changeMenu和closeDocTool
      showConfirmationDialog: false,
      // activeValue: "PDF_Word",
      activeMenuTemp: "",
      showAddFile: false,
      ModalText:
        "切换后，当前列表中正在转换中的文件将会在后台进行转换，未转换的文件将直接移除",
      confirmLoading: false,
    };
  },
  created() {
    this.menuList = docToolMenuList;
  },
  watch: {
    // TODO 监听文档转换列表的状态--临时使用
    conversionFiles: {
      handler(newVal) {
        if (newVal.length) {
          console.log("监听到的列表中文件", newVal);
          // 检查newVal数组中的每个项是否状态都是"success"
          // status的值是CONVERTING代表转换中， SUCCESS表示转换完成，undefind或者await表示待转换
          // 检查转换状态并更新 this.allTransferStatus 和 transferBtnObj
          // if (newVal.every((item) => item.status === "SUCCESS")) {
          // 如果所有状态都是 SUCCESS / (开始没说有失败的情况，考虑失败的情况，只要没有还在转换中的文件切状态不是空值或带转哈，就是全部完成了) ，则整体状态为转换完成
          if (
            newVal.every(
              (item) =>
                item.status &&
                item.status !== "CONVERTING" &&
                item.status !== "allAwait"
            )
          ) {
            // 如果所有状态都是 SUCCESS，则整体状态为转换完成
            this.allTransferStatus = "allOver";
          } else if (newVal.some((item) => item.status === "CONVERTING")) {
            // 如果有至少一个状态是 CONVERTING，则整体状态为转换中
            this.allTransferStatus = "transferIng";
          } else {
            // 否则，整体状态为待转换
            this.allTransferStatus = "allAwait"; // 或者 undefined/await，根据你的逻辑来决定
          }
          // this.allTransferStatus=

          // 如果所有项的状态都是"success"，则停止轮询
          if (this.allTransferStatus == "allOver") {
            // 所有文件都转换完成了，那么2秒钟后停止轮训吧！
            setTimeout(() => {
              console.log("所有文件都转换完成了,那么500毫秒后停止轮训吧");
              // 如果需要取消轮询，可以在适当的时候调用
              this.$store.commit("docTool/stopPollingStatus");
            }, 500);
          }
          this.datas = newVal.map((item) => {
            return {
              ...item,
              fileSize: publicFormatSize(item.userFileSize),
              statu: item.status ? item.status : "await",
              viewFile: "--",
            };
          });
        } else {
          console.log("监听到的列表中文件为空", newVal);
        }
      },
      immediate: true,
      deep: true,
    },
    // activeValue: {
    //   handler(newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //     }
    //   },
    //   immediate: true,
    // },

    // 监听文档转换列表的状态
    conversionStatusInfo: {
      handler(newVal, oldVal) {
        if (newVal && newVal.length) {
          console.log("文件转换列表的状态----", newVal);
          // 获取当前的conversionFiles状态
          // const currentConversionFiles = this.$store.state.docTool.conversionFiles;

          // 计算合并了新属性后的新数组
          const updatedConversionFiles =
            this.conversionFiles &&
            this.conversionFiles.map((file) => {
              const statusObj = newVal.find(
                (obj) => obj.originalUserFileId == file.id
              );
              if (statusObj) {
                // 合并属性，这里使用Object.assign或者展开运算符来合并对象
                return { ...file, ...statusObj };
              }
              // 如果没有找到匹配的项，返回原对象
              return file;
            });

          this.$store.commit(
            "docTool/changeConversionFiles",
            updatedConversionFiles
          );
          // setTimeout(() => {
          //   // TODO 调试代码，给个一分钟超时时间，最长一分钟没转换完成就暂时停止轮训吧
          //   this.$store.commit("docTool/stopPollingStatus");
          // }, 1000 * 60 * 5);
        } else {
          // console.log("转换列表中没有文件", newVal);
          // setTimeout(() => {
          //   console.log("没有文件，那就停止轮训吧");
          //   // 如果需要取消轮询，可以在适当的时候调用
          //   this.$store.commit("docTool/stopPollingStatus");
          // }, 2000);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // 打开文档工具的入口来源
    docToolFrom() {
      return this.$store.state.docTool.docToolFrom;
    },
    // 文档转换列表的状态
    conversionStatusInfo() {
      return this.$store.state.docTool.conversionStatusInfo;
    },
    // 当前在操作的文档工具菜单
    activeMenu() {
      return this.$store.state.docTool.activeMenu;
    },
    getImagePath() {
      return require(`@/assets/svg/documentTool/actionMenu/${this.activeMenu}.svg`);
    },
    getTitle() {
      const item = this.menuList.find((menu) => menu.name === this.activeMenu);
      if (item && item.type === "transform") {
        return "添加文件到列表中，立刻开始转换";
      } else if (item && item.type === "pdfEdit") {
        return "添加文件到列表中，立刻开始编辑";
      }
    },
    // 文档转换列表的弹窗的显示隐藏
    docTransformDialogFlag() {
      return this.$store.state.docTool.docTransformDialogFlag;
    },

    // 已经添加到转换列表中的文件数组
    conversionFiles() {
      return this.$store.state.docTool.conversionFiles;
    },
    // 已经添加到转换列表中的文件id数组
    // conversionFilesIds() {
    //   if (this.$store.state.docTool.conversionFiles.length) {
    //     return this.$store.state.docTool.conversionFiles.map((item) => item.id);
    //   } else {
    //     return [];
    //   }
    // },
  },
  mounted() {
    // 如果需要取消轮询，可以在适当的时候调用
    // this.$store.commit("docTool/stopPollingStatus");
    console.log("文档工具入口来源是docToolFrom", this.docToolFrom);
  },
  methods: {
    getWrapClassName() {
      if (this.ceshiFlag) {
        return "documentTool-modal popout-box";
      } else {
        return "documentTool-modal";
      }
    },

    // 转换失败的重试
    async retry(row) {
      row.status = "CONVERTING";
      let temp = this.activeMenu == "PDF_Word" ? this.pdfToWordRadio : "PDF",
        params = {
          fileIds: [row.id],
          targetExtension: temp,
          converterSource: "PC",
        };

      setTimeout(async () => {
        try {
          const res = await this.$apis.converterApplyHttp(params);
          console.log("提交转换列表的返回", res);
        } catch (error) {
          console.log("提交转换失败", error);
        }
        // console.log("---重试转换的参数-------------", params);
        // this.disabledAddFileFlag = true; // 禁用添加
        // return;
        console.log("执行轮询-------------");
        // // 开始轮询状态
        this.$store.dispatch("docTool/async_startPollingStatus");
      }, 1500);
    },
    // 打开文件夹
    openFolder() {
      const targetParentId = this.conversionFiles[0].convertedParentId;
      let query = {
        id: targetParentId,
      };
      console.log("this.routeName是啥", "VIEW_MY_DOCUMENT");
      this.$router.push({ name: "VIEW_MY_DOCUMENT", query });
      // 点击文件夹添加id到vuex作为前进后退依据
      this.$store.commit("SET_addHistoryIds", query.id);
      this.minPage(); // 最小化一下悬浮窗
    },

    // 下载转换后的文件
    downFile(val) {
      downloadDocFile(val.convertedUserFileId);
    },

    // 预览结果文件
    viewFile(val) {
      // actionFileFullName:`${val.userFileName}.${val.userFileSize}`,
      let tempFile = {
        ...val,
        moduleType: "doc",
        actionFileid: val.convertedUserFileId,
        actionFileSize: publicFormatSize(val.userFileSize),
        actionFileFullName: `${val.convertedFileName}.${val.convertedExtensionName}`,
      };
      publicFileView(tempFile);
    },
    gettransferBtnText() {
      const transferBtnInfo = this.transferBtnObj.find(
        (item) => item.value === this.allTransferStatus
      );
      return transferBtnInfo ? transferBtnInfo.text : "立即转换";
    },
    getTransferBtnStyle() {
      const transferBtnInfo = this.transferBtnObj.find(
        (item) => item.value === this.allTransferStatus
      );
      return transferBtnInfo
        ? {
            color: transferBtnInfo.color,
            "background-color": `${transferBtnInfo.bgColor} !important`,
            height: "40px !important",
          }
        : {
            color: "#fff",
            "background-color": "#3271ff !important",
            height: "40px !important",
          };
    },
    getTransferBtnDisabled() {
      const transferBtnInfo = this.transferBtnObj.find(
        (item) => item.value === this.allTransferStatus
      );
      return transferBtnInfo ? transferBtnInfo.disabled : false;
    },

    getStatusText(status) {
      const statusObj = this.statusObj.find((item) => item.value === status);
      return statusObj ? statusObj.text : "待转换";
    },
    getStatusColor(status) {
      const statusObj = this.statusObj.find((item) => item.value === status);
      return statusObj ? statusObj.color : "#333333";
    },

    // 点击添加文件确认按钮
    addFilesFn() {
      console.log("添加了文件---");
    },
    // 点击立即转换
    async filesConversion() {
      let temp = this.activeMenu == "PDF_Word" ? this.pdfToWordRadio : "PDF";
      let fileItems = this.conversionFiles.filter(
        (item) => item.status == "await" || !item.status
      );
      let fileIds = fileItems.map((item) => item.id);
      const params = {
        fileIds,
        targetExtension: temp,
        converterSource: "PC",
      };
      try {
        const res = await this.$apis.converterApplyHttp(params);
        console.log("提交转换列表的返回", res);
      } catch (error) {
        console.log("提交转换失败", error);
      }

      console.log("转换的参数-------------", params);

      // this.disabledAddFileFlag = true; // 禁用添加

      // return;
      console.log("执行轮询-------------");
      // // 开始轮询状态
      this.$store.dispatch("docTool/async_startPollingStatus");
    },

    // 删除转换列表中的某个文件
    deleteFile(row) {
      console.log("要删的是哪个文件", row);
      this.$store.commit("docTool/filterConversionFilesById", row);
      this.datas = this.datas.filter((item) => item.id !== row.id);
    },
    // 关闭添加文件的弹窗
    closeAddFile() {
      this.showAddFile = false;
    },

    // 确认切换操作菜单
    confirmationDialog_Ok() {
      this.$store.dispatch("docTool/async_clearFileList");
      this.datas = [];
      this.showConfirmationDialog = false;
      //   // 确认切换操作菜单，而不是确认后关闭文档工具
      if (this.ConfirmationDialogType == "changeMenu") {
        this.$store.dispatch(
          "docTool/async_setActiveMenu",
          this.activeMenuTemp
        ); // 改变当前激活的菜单
        // 清空轮询计时器
        this.$store.commit("docTool/stopPollingStatus");
      } else {
        // 确认关闭文档工具，而不是确认后切换菜单
        this.$store.dispatch("docTool/async_clearFileList");
        // 初始化文档工具所有可维护数据及状态重置
        this.$store.commit("docTool/clearAllDocToolData");
      }
    },

    // 关闭提示弹窗
    confirmationDialog_Cancel() {
      this.showConfirmationDialog = false;
    },

    // 切换操作菜单cchangeActiveMenu
    changeActiveMenu(value) {
      if (this.activeMenu !== value) {
        if (this.datas.length) {
          this.showConfirmationDialog = true;
          this.ConfirmationDialogType = "changeMenu";
          this.activeMenuTemp = value;
        } else {
          this.$store.dispatch("docTool/async_setActiveMenu", value); // 改变当前激活的菜单
        }
      }
    },

    // 添加了文件之后返回到这里
    // addFilesFn(items, ids) {
    //   console.log("545454545");
    //   console.log("文件列表", items);
    //   console.log("id数组", ids);
    // },

    // 最小化界面
    minPage() {
      // let targetEl= this.$refs.docToolModalRefs

      // 添加关闭动画的类

      // const modal = document.getElementById("docToolModar");
      this.ceshiFlag = true;
      this.maskFlag = false;

      setTimeout(() => {
        this.$store.dispatch(
          "docTool/async_setShowDocToolMinimizedFloatingWindow",
          true
        ); // 显示文档工具弹窗的最小化悬浮窗口
        this.$store.dispatch(
          "docTool/async_changeDocTransformDialogFlag",
          false
        ); // 关闭文档转换列表的弹窗
        this.ceshiFlag = false;
        this.maskFlag = true;
      }, 300);
    },

    // 关闭文档工具弹窗
    closeDocTool() {
      if (
        this.conversionFiles.some(
          (item) =>
            item.status === "await" ||
            item.status === "CONVERTING" ||
            !item.status
        )
      ) {
        this.showConfirmationDialog = true;
        this.ConfirmationDialogType = "closeDocTool";
        this.ModalText =
          "关闭后，正在转换中的文件将会在后台进行转换，未转换的文件将直接移除，请知悉!";
      } else {
        this.$store.dispatch("docTool/async_clearFileList");
        // 初始化文档工具所有可维护数据及状态重置
        setTimeout(() => {
          this.$store.commit("docTool/clearAllDocToolData");
        }, 100);
      }
    },

    // 添加云文档文件弹窗
    addDocFile() {
      const item = this.menuList.find((menu) => menu.name === this.activeMenu);
      console.log("添加文件", item);
      if (item.type === "pdfEdit") {
        // alert("编辑");
        this.addFileType = "pdfEdit";
        setTimeout(() => {
          this.showAddFile = true;
        }, 200);
      } else {
        this.addFileType = "converter";
        setTimeout(() => {
          this.showAddFile = true;
        }, 200);
      }
    },
    // 工具左侧转换和编辑选项变化
    toolChange(e) {
      console.log("工具左侧转换和编辑选项变化", e.target.value);
    },
    showModal() {
      console.log("123");
    },
  },
};
</script>

<style scoped lang="less">
.documentTool {
  width: 100%;
  height: 100%;
}
</style>

<style lang="less">
// 表格文件名称和位置的悬浮提示
.doc-tool-table-toolTip {
  max-width: 300px !important;
}

.popout-box {
  // 将dialog的class绑定close_com动画，0.7s表示动画时间为0.7秒
  animation: close_com 0.3s linear 1;
  -webkit-animation: close_com 0.3s linear 1;
}

@-webkit-keyframes close_com {
  100% {
    -webkit-transform: translate(45%, 42%) scale(0);
  }
}

@keyframes close_com {
  100% {
    transform: translate(45%, 42%) scale(0);
  }
}

// 状态表头
.documentTool-modal .ant-table-thead th:nth-of-type(5) {
  // padding-left: 10px !important;
  // text-align: center !important;
  padding-left: 30px !important;
}
// 预览结果文件的表格
.documentTool-modal .ant-table-tbody .ant-table-row td:last-of-type {
  div {
    padding-left: 22px !important;
  }
}

.documentTool-modal {
  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-body {
    padding: 0 !important;
    border-radius: 16px !important;
  }
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    background-color: #f5f7f9 !important;
  }
  .ant-table-thead {
    // margin-left: 10px;
    background-color: #f5f7f9 !important;
    .actionFilePath-doc {
      padding-left: 28px !important;
    }

    th {
      // text-align: center;
      background-color: #f5f7f9 !important;
    }
  }
  .content {
    padding: 20px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: #f1f2f5 !important;
    border-radius: 16px !important;
    .title {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      justify-content: space-between;
      .img_box {
        margin-right: 10px;
        img {
          cursor: pointer;
        }
        .min {
          margin-right: 20px;
        }
      }
    }
    .content-height {
      // width: 100%;
      .border-right {
        border-right: 1px solid #ccc;
      }
      .left {
        padding: 10px;
        padding-left: 0 !important;
        padding-top: 0 !important;
        height: 100%;

        .ment-title {
          height: 36px;
          margin-top: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 12px;
          color: #111111;
          font-style: normal;
        }

        .radio-group-prf-box {
          .item {
            user-select: none;
            margin-top: 10px;
            cursor: pointer;
            width: 140px;
            height: 36px;
            border-radius: 5px;
            display: flex;
            padding-left: 15px;
            // padding-right: 20px;
            justify-content: flex-start;
            align-items: center;
            .menuList-title {
              margin-left: 15px;
            }
          }
          .active {
            background-color: #fff !important;
          }
        }
      }
      .right {
        height: 100%;
        padding: 10px;
        padding-bottom: 0 !important;
        display: flex;
        flex-direction: column;
        .table-box {
          height: 450px;
          background-color: #fff;
          border-radius: 10px !important;
          box-shadow: 0px 2px 2px 0px #f0f4f9;
          border: 1px solid rgba(0, 0, 0, 0.1);

          .table-hasContent {
            .table-content {
              user-select: none;
              min-height: 400px;
              // 表格文件名
              .name-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .addFileBox {
              cursor: pointer;
              // pointer-events: none;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top: 1px solid #e8e9eb;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              font-style: normal;
              .addFileBtn {
                display: flex;
                color: #3271ff !important;
                height: 100% !important;
                justify-content: center;
                align-items: center;
              }
              .addFileBtn-txt {
                opacity: 0.6;
              }
            }
          }

          .table-empty {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 102px 0;
            align-items: center;
            justify-content: space-between;
            .empty-top {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .empty-center {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
            }
            .empty-footer {
              user-select: none;
              cursor: pointer;
              margin-top: 10px;
              width: 220px;
              height: 48px;
              border-radius: 8px;
              border: 1px solid #3271ff;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 36px;
              .empty-footer-txt {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #3271ff;
                text-align: right;
                font-style: normal;
              }
            }
          }
        }
        .output-area {
          height: 64px;
          width: 100%;
          .output-area-show {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .area-left {
              .top {
                display: flex;
              }
              .open-folder {
                cursor: pointer;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #3271ff;
                text-align: right;
                font-style: normal;
              }
            }
            .area-right {
              // height: 50px;
              width: 150px;
            }
          }
        }
      }
    }
  }
}
</style>
