import { Upload } from '@/interface/index'
const user = {
    namespaced: true,
    state: {
        uploadClick: 0,
        changeType:0,
        uploadClickFolder: 0,
        uploaderListShow: false,
        params: {
            parentId: ''
        }
    },

    mutations: {
        SET_UPLOADCLICK(state: Upload, status: string): void {
            state.uploadClick++
            state.params.parentId = status
        },
        set_ChangeType(state:any){
            state.changeType++
        },
        SET_UPLOADCLICKFOLDER(state: Upload, status: string): void {
            state.uploadClickFolder++
            state.params.parentId = status
        },
        SET_UPLOADERLISTSHOW(state: Upload, status: boolean): void {
            state.uploaderListShow = status
        }
    },

    actions: {

    }
};

export default user;
