
import Vue from "vue";
import Transfer from "./Transfer.vue";
import Message from "./Message.vue";
import UserModal from "./UserModal.vue";
import { Badge } from "ant-design-vue";
import WebsocketMixin from "@/mixins/WebsocketMixin";

export default Vue.extend({
  name: "HeaderUser",
  components: {
    Transfer,
    Message,
    UserModal,
    [Badge.name]: Badge,
  },
  mixins: [WebsocketMixin],
  props: {},
  filters: {},
  data() {
    return {
      // 消息显示
      messageShow: false,
      // 用户信息显示
      userShow: false,
      // 未读
      unread: false,
    } as any;
  },
  computed: {
    // 传输显示
    transferShow(): boolean {
      return this.$store.state.menu.transferShow;
    },
    avatarImage(): string {
      return this.$store.state.user.avatarImage;
    },
  },
  created() {
    // this.inquireUnread();
  },
  mounted() {
    document.addEventListener("visibilitychange", this.againGetUser);
    // this.$store.dispatch("user/GET_USERINFO");
    // this.$store.dispatch("user/GET_COMPANYID");
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.againGetUser);
  },
  methods: {
    set_transferShow(show: boolean) {
      this.$store.commit("menu/SET_TRANSFERSHOW", show);
    },
    againGetUser() {
      if (document.hidden) {
        // 页面被挂起
        console.log("隐藏");
      } else {
        // 页面呼出
          // 改为微应用嵌入365，没有自己的用户体系，直接去掉
        this.$store.dispatch("user/USER_KEEP");
        if (localStorage.getItem("coopChange")) {
          this.$store.commit("cooperation/SET_TRIGGERLISTQUERY");
          localStorage.removeItem("coopChange");
        }
      }
    },
    // 查询未读消息
    // inquireUnread() {
    //   this.$apis.messageQueryUnRead().then((res: any) => {
    //     if (res.code === "200") {
    //       if (Object.keys(res.data).length > 0) {
    //         this.unread = true;
    //       }
    //     }
    //   });
    // },
    // WebSocket响应处理
    handleResponse(res: any) {
      if (res.data !== "socket连接成功") {
        this.unread = true;
        this.$notification.open({
          message: "消息通知",
          description: res.data,
          duration: 3,
        });
      }
    },
    // 点击显示用户数据
    handleUserShow() {
      this.$apis
        .taskQuery({
          userId: this.$store.state.user.userId,
        })
        .then((res: any) => {
          if (res.code === "200") {
            this.$store.commit("user/SET_ACCOUNTPOINT", res.data);
          }
        })
        .finally(() => {
          this.userShow = true;
        });
    },
    // 退出登录
    handleLogOut(): void {
      let that = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        okText: "是",
        cancelText: "否",
        onOk() {
          that.$apis.logOut().then((res: any) => {
            if (res.code === "200") {
              that.$message.success("退出登录成功");
              // 登出
              that.$store.commit("user/LOG_OUT");
              // 去到登录页
              that.$router.push({
                name: "login",
              });
            }
          });
        },
      });
    },
  },
});
