
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SparkMD5 from "spark-md5";

const UploadModule = namespace("upload");
const UserModule = namespace("user");
const UserCommon = namespace("common");

function createId() {
  let now = Date.now();
  var d = new Date().getTime();
  var uuid = "xxyyxxy".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 10) % 10 | 0;
    d = Math.floor(d / 10);
    return r.toString();
  });
  return now + uuid;
}

@Component({
  name: "GlobalUploader",
  components: {},
  filters: {
    //局部过滤器
    suffix(value: string): string {
      let arr = value.split(".");
      if (arr.length > 0) {
        return arr[arr.length - 1];
      } else {
        return "";
      }
    },
    calculateFileSize(size: number): string {
      const B = 1024;
      const KB = Math.pow(1024, 2);
      const MB = Math.pow(1024, 3);
      const GB = Math.pow(1024, 4);
      if (size === 0) {
        return "0KB";
      } else if (!size) {
        return "-";
      } else if (size < KB) {
        return `${(size / B).toFixed(0)}KB`;
      } else if (size < MB) {
        return (size / KB).toFixed(1) + "MB";
      } else if (size < GB) {
        return (size / MB).toFixed(2) + "GB";
      } else {
        return (size / GB).toFixed(3) + "TB";
      }
    },
    progress(value: number): string {
      return (value * 100).toFixed(1) + "%";
    },
  },
})
export default class GlobalUploader extends Vue {
  @UploadModule.State("uploadClick") uploadClick!: number;
  @UploadModule.State("uploadClickFolder") uploadClickFolder!: number;
  // @UserModule.State("token") token!: string;
  @UserModule.State("token") token!: string;
  @UserModule.State("isAudit") isAudit!: Number;
  @UserModule.Mutation("LOG_OUT") logOut!: () => void;
  @UserModule.State("companyId") companyId!: string;
  @UserModule.Action("GET_USERINFO") getUserinfo!: (value: string) => void;
  @UploadModule.State("uploaderListShow") uploaderListShow!: string;
  @UploadModule.State("params") uploaderParams!: {
    parentId: string;
  };
  @UploadModule.Mutation("SET_UPLOADERLISTSHOW") setUploaderListShow!: (
    flag: boolean
  ) => void;
  @UserCommon.Mutation("TO_RELOAD") toReload!: () => void;

  // 允许的文件类型列表
  public all_allowedFileTypes: any = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/bmp",
    "image/webp",
    "image/tiff",
    "image/svg+xml",
    "image/gif",
    "image/x-icon", // ICO
    "image/heic",
    "application/msword", // DOC
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
    "application/vnd.ms-powerpoint", // PPT
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
    "application/pdf",
    "application/vnd.ms-excel", // XLS
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "text/plain", // TXT
  ];

  // 上传组件配置项
  public options: any = {
    target: "/star-doc/file/upload", // 上传文件-目标 URL
    chunkSize: 1024 * 1024, //  每个分片的大小
    fileParameterName: "file", //  上传文件时文件的参数名，默认 file
    simultaneousUploads: 3, //  并发上传数，默认 3
    testChunks: true, //  是否开启分片已存在于服务器的校验
    testMethod: "POST",
    accept: this.all_allowedFileTypes.join(","),
    // 服务器分片校验函数，秒传及断点续传基础
    checkChunkUploadedByResponse: function (chunk: any, message: any) {
      let objMessage = JSON.parse(message);
      if (objMessage.code === "200") {
        let data = objMessage.data;
        if (data.status === "SUCCESS") {
          // 分片已存在于服务器中
          return true;
        }
        return (chunk.file.successNumber || []).indexOf(chunk.offset + 1) >= 0;
      }
    },
    query() {},
  };

  // 上传属性
  public attrs: any = {
    accept: "image/*",
  };
  // 上传列表折叠
  public collapse: boolean = false;
  // 全部上传的状态
  public uploaderAll: string = "uploader";
  // 文件状态文案映射
  public fileStatusText: any = {
    success: "上传成功",
    error: "上传失败",
    uploading: "上传中",
    paused: "暂停中",
    waiting: "等待中",
  };
  // 上传列表组件实例
  public uploaderListVm: any = null;
  // 文件夹上传详情
  public folderDetails: any[] = [];
  public folderDetailsShow: boolean = false;
  public isRender: boolean = true;
  public isShow: boolean = false;
  // 同步容量定时器
  public syncStorageTime: any = null;
  public routerName = "";

  handleFilesAdded(files: any[]) {
    console.log("files", files);

    let tempFiles = [];
    // 1 表示开启了审核
    if (this.isAudit == 1) {
      // 用于收集无效的文件索引
      // let invalidFiles: any = [];

      files.forEach((file: any, index: number) => {
        // 检查文件类型是否有效
        if (!this.isValidFileType(file.name)) {
          this.$message.error(
            `【${file.name}】上传失败,暂不支持该文件类型上传！`
          );
          // invalidFiles.push(index);
          this.$refs.uploader &&
            (this.$refs.uploader as any).uploader.removeFile(file);
        } else if (file.size > 1024 * 1024 * 200) {
          this.$message.error(`【${file.name}】上传失败,文件大小超过200M`);
          // invalidFiles.push(index);
          this.$refs.uploader &&
            (this.$refs.uploader as any).uploader.removeFile(file);
        } else {
          // 如果没有错误，则添加到tempFiles中

          // 如果是在编辑知识库和知识库问答界面上传，还需要判断是否是知识库受支持的文件类型

          if (
            (this.$route as any).name == "editKnowledge" ||
            (this.$route as any).name == "knowledgeQuestionsAndAnswers"
          ) {
            if (!this.isTargetKnowledeFileType(file.name)) {
              this.$message.error(
                `【${file.name}】上传失败,知识库只支持word,pdf,txt文件类型上传！`
              );
              this.$refs.uploader &&
                (this.$refs.uploader as any).uploader.removeFile(file);
            } else {
              tempFiles.push(file);
            }
          } else {
            tempFiles.push(file);
          }
        }
      });

      // 更新uploaderInstance.fileList，排除无效文件
      // this.uploaderInstance.fileList = this.uploaderInstance.fileList.filter(
      //   (file: any, index: number) => !invalidFiles.includes(index)
      // );
    } else {
      tempFiles = files;
    }

    if (this.$route.query.fileType) {
      console.log(this.$store.commit);
      this.$store.commit("upload/set_ChangeType");
    }
    this.setUploaderListShow(true);
    console.log("tempFiles", tempFiles);
    this.uploaderAll = "uploader";
    this.isRender = true;
    tempFiles.forEach((file: any) => {
      this.computeMD5(file);
    });
  }

  // 受支持的文件审核上传文件类型函数
  isValidFileType(fileName: any) {
    const allowedFileTypes = [
      "png",
      "jpg",
      "jpeg",
      "bmp",
      "webp",
      "tiff",
      "svg",
      "gif",
      "ico",
      "heic",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "pdf",
      "xls",
      "xlsx",
      "txt",
    ];
    const extension = fileName.split(".").pop().toLowerCase();
    return allowedFileTypes.includes(extension);
  }

  // 受支持的知识库上传文件类型函数
  isTargetKnowledeFileType(fileName: any) {
    const allowedFileTypes = ["doc", "docx", "pdf", "txt"];
    const extension = fileName.split(".").pop().toLowerCase();
    return allowedFileTypes.includes(extension);
  }

  /**
   * 计算md5，实现断点续传及秒传
   * @param {object} file 文件信息
   */
  computeMD5(file: any) {
    let fileReader = new FileReader();
    let blobSlice =
      File.prototype.slice ||
      (File.prototype as any).mozSlice ||
      (File.prototype as any).webkitSlice;
    let currentChunk = 0;
    const chunkSize = 1 * 1024 * 1024;
    let chunks = Math.ceil(file.size / chunkSize);
    let spark = new SparkMD5.ArrayBuffer();
    // 文件状态设为"计算MD5", 并绑定set get方法
    this.$set(file, "statusStr", "计算MD5");
    file.pause();
    loadNext();
    fileReader.onload = (e: any) => {
      spark.append(e.target.result);
      if (currentChunk < chunks) {
        currentChunk++;
        loadNext();
        // 实时展示MD5的计算进度
        file.statusStr = `校验MD5 ${((currentChunk / chunks) * 100).toFixed(
          0
        )}%`;
      } else {
        let md5 = spark.end();
        this.calculateFileMD5End(md5, file);
      }
    };
    fileReader.onerror = () => {
      this.$message.error(`文件${file.name}读取出错，请检查该文件`);
      file.cancel();
    };
    function loadNext() {
      let start = currentChunk * chunkSize;
      let end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
    }
  }

  /**
   * 文件MD5计算结束
   * @param {string} md5 文件 MD5 值
   * @param {object} file 文件对象
   */
  calculateFileMD5End(md5: string, file: any) {
    console.log("🚀 sd :", file);
    let params: any = {
      fileName: file.name,
      moduleType: this.$route.path.includes("companyTeam/document")
        ? "team"
        : "doc",
      companyId: this.$route.path.includes("companyTeam/document")
        ? this.companyId
        : "person",
    };
    if (params.moduleType === "team") {
      params.teamId = this.$route.query.teamId;
    }
    // 将自定义参数直接加载uploader实例的opts上
    Object.assign(this.uploaderInstance.opts, {
      query: {
        ...this.uploaderParams,
        ...params,
      },
      headers: {
        "X-Access-Token": this.token,
        "Tenant-Id": this.companyId,
        // Authorization: this.token,
        requestId: createId(),
        channelType: "web",
      },
    });
    file.uniqueIdentifier = md5;
    this.preUpload(file);
  }

  // 上传前像服务端进行确认
  preUpload(file: any, retry?: boolean) {
    // alert('666-777')
    // 销毁容量定时器
    this.$store.commit("user/stopTimer");

    let params: any = {
      ...this.uploaderParams,
      filename: file.name,
      relativePath: file.relativePath,
      totalSize: file.size,
      identifier: file.uniqueIdentifier,
      moduleType: this.$route.path.includes("companyTeam/document")
        ? "team"
        : "doc",
      companyId: this.$route.path.includes("companyTeam/document")
        ? this.companyId
        : "person",
    };
    if (params.moduleType === "team") {
      params.teamId = this.$route.query.teamId;
    }
    this.$apis.filePreUpload(params).then((res: any) => {
      if (res.code === "200") {
        if (res.data.historyId) {
          this.$set(file, "historyId", res.data.historyId);
          //  给上传实例对象新增属性
          this.uploaderInstance.opts.query.historyId = res.data.historyId;

          console.log("this.uploaderInstance.opts", this.uploaderInstance.opts);
        }

        if (res.data.skipUpload) {
          this.deleteFile(file);

          if (res.data.status === "UPLOADED") {
            this.$message.success("该文件已上传");
            // 在知识库界面上传的

            this.uploadSuccessForKnowledgeBase(res?.data?.fileId);
          } else {
            this.$message.success("上传成功");
            this.$set(file, "parentId", res.data.parentId);
            // this.$set(file, "ceshi", '545415145');
            this.pageRerender(file);
            // this.syncStorage();

            // 在知识库界面上传的

            this.uploadSuccessForKnowledgeBase(res?.data?.fileId);

            // 立即调用一次容量
            this.$store.dispatch("user/GET_CAPACITY");
            // 启用容量定时器
            this.$store.commit("user/startTimer");
          }
        } else {
          console.log("上传中-001");
          // this.syncStorage()
          retry ? file.retry() : file.resume();
          // 移除自定义状态
          this.$set(file, "statusStr", "上传中");
          this.$set(file, "successNumber", res.data.successNumber);
          // this.$set(file, "successNumber", 2);
          if (file.name === file.relativePath.split("/")[0]) {
            this.$set(file, "parentId", this.uploaderParams.parentId);
          } else {
            let fileRoot = file.getRoot();
            this.$set(fileRoot, "parentId", this.uploaderParams.parentId);
          }
        }
      }
    });
  }

  uploadSuccessForKnowledgeBase(id: any) {
    // 本地上传成功--到知识库
    if (
      (this.$route as any).name == "editKnowledge" ||
      (this.$route as any).name == "knowledgeQuestionsAndAnswers"
    )
      this.$store.commit(
        "knowledgeBase/set_uploadSuccessForKnowledgeBase",
        true
      );

    this.$store.commit(
      "knowledgeBase/set__uploadSuccessForKnowledgeBaseFileId",
      id
    );
  }

  // 上传之后在当前页进行重渲染
  pageRerender(file: any) {
    if (
      this.$route.path === "/star/mydoc" ||
      this.$route.path === "/star/companyTeam/document"
    ) {
      let fileRoot = file.getRoot();
      if (
        (this.$route.query.id === fileRoot.parentId ||
          (!this.$route.query.id && !fileRoot.parentId)) &&
        !fileRoot.noRender
      ) {
        this.$set(fileRoot, "noRender", true);
        this.isRender = false;
        this.toReload();
        return;
      }
      if (
        (this.$route.query.id === this.uploaderParams.parentId ||
          (!this.$route.query.id && !this.uploaderParams.parentId)) &&
        this.isRender
      ) {
        this.isRender = false;
        this.toReload();
        return;
      }
      if (
        this.$route.query.id === file.parentId ||
        (!this.$route.query.id && !file.parentId)
      ) {
        this.toReload();
      }
    }
  }

  handleFileSuccess(rootFile: any, file: any, response: any) {
    if (this.$route.query.fileType) {
      console.log(this.$store.commit);
      this.$store.commit("upload/set_ChangeType");
    }
    let result = JSON.parse(response);

    console.log("上传接口返回的结果", result);
    if (result.code !== "200") {
      // this.$message.error(result.msg);
      this.$notification.error({
        message: "错误提示",
        description: result.msg,
        duration: 3,
      });
    }

    // if (result.code === "9001" || result.code === "9006") {
    //   this.$message.warning("登录超时，请重新登录!");
    //   // 登出
    //   this.logOut();
    //   // 去到登录页 并保存当前页面的路径方便登录重新返回
    //   this.$router.push({
    //     name: "login",
    //     query: {
    //       path: this.$router.currentRoute.fullPath,
    //     },
    //   });
    // }
    let num = 0;
    let concant = 0;
    if (result?.data?.status === "SUCCESS") {
      num++;
      // alert('666')

      console.log("上传成功-------------------", num);

      // 立即调用一次容量
      this.$store.dispatch("user/GET_CAPACITY");
      // 启用容量定时器
      this.$store.commit("user/startTimer");

      // 在知识库界面上传的

      this.uploadSuccessForKnowledgeBase(result?.data?.fileId);

      file.statusStr = "上传成功";
      this.$message.success(`${file.name} - 上传成功`);
      this.$set(file, "parentId", result?.data?.parentId);
      this.$set(file, "fileId", result?.data?.userFileId);
      this.$set(file, "moduleType", result?.data?.moduleType);
      if (result?.data?.moduleType == "team") {
        this.$set(file, "teamId", result?.data?.teamId);
      }
      // this.$set(file, "parentId", '测试数据id');
      this.pageRerender(file);

      if (
        this.$route.name == "VIEW_MY_DOCUMENT" &&
        this.$route.query.fileType
      ) {
        this.$router.push({
          name: "VIEW_MY_DOCUMENT",
        });
      }
    } else if (
      result?.data?.status === "UNCOMPLETED" ||
      result?.data?.status === "FAIL"
    ) {
      file.statusStr = "上传失败";
      this.$message.error(`${file.name} - 上传失败,请稍后再试`);
    }

    if (rootFile.isFolder) {
      console.log("上传中-002");

      this.$set(rootFile, "statusStr", "上传中");
      this.recordUploaderEnd(rootFile);
    } else {
      console.log("上传中-003");
      // this.syncStorage();
    }
  }

  handleFileError(rootFile: any, file: any, response: any) {
    this.$set(file, "statusStr", "上传失败,请稍后再试");
    if (rootFile.isFolder) {
      this.recordUploaderEnd(rootFile);
    }
    console.log("请稍后再试---", response);

    this.$message.error(response);
  }

  // 上传结束文件统计
  recordUploaderEnd(rootFile: any): void {
    // 是否已经上传结束
    let isEnd = rootFile.files.every(
      (i: any) => i.statusStr === "上传成功" || i.statusStr === "上传失败"
    );
    // 上传成功文件
    let succeed = rootFile.files.filter((i: any) => i.statusStr === "上传成功");
    if (isEnd) {
      this.$set(rootFile, "step", `${succeed.length}/${rootFile.files.length}`);
      if (succeed.length === rootFile.files.length) {
        console.log("最终的上传成功------------------------------------");
        this.$set(rootFile, "statusStr", "上传成功");
      } else if (succeed.length === 0) {
        this.$set(rootFile, "statusStr", "上传失败");
      } else {
        this.$set(rootFile, "statusStr", "部分成功");
      }
      // this.syncStorage();
    } else {
      this.$set(rootFile, "step", `${succeed.length}/${rootFile.files.length}`);
    }
  }

  // 重新上传
  againUploader(file: any): void {
    this.isRender = true;
    if (file.isFolder) {
      let errorList: any[] = file.files.filter(
        (i: any) => i.statusStr === "上传失败"
      );
      this.$set(file, "statusStr", "上传中");
      errorList.forEach((i) => {
        this.preUpload(i, true);
      });
    } else {
      this.preUpload(file, true);
    }
  }

  // 查看文件夹详情
  handleFolder(file: any): void {
    if (file.isFolder) {
      this.folderDetails = file.files;
      this.folderDetailsShow = true;
    }
  }

  // 删除上传记录
  deleteFile(file: any): void {
    console.log(
      "🚀 ~ file: index.vue:693 ~ GlobalUploader ~ deleteFile ~ file:",
      file
    );
    let fileRoot = file.getRoot();
    console.log(
      "🚀 ~ file: index.vue:694 ~ GlobalUploader ~ deleteFile ~ fileRoot:",
      fileRoot
    );

    file.cancel();
    if (fileRoot.isFolder) {
      this.recordUploaderEnd(fileRoot);
      let vm: any = this.uploaderListVm.$children.filter(
        (i: any) => i.$options.name === "uploader-file"
      );
      vm = vm.find((i: any) => i.file.name === fileRoot.name);
      vm._fileProgress();
    }
  }

  // 继续上传
  continueFile(file: any): void {
    if (file.isFolder) {
      let pauseList: any[] = file.files.filter(
        (i: any) => i.statusStr === "上传中"
      );
      this.$set(file, "statusStr", "上传中");
      pauseList.forEach((i) => {
        this.preUpload(i);
      });
    } else {
      this.preUpload(file);
    }
  }

  // 暂停上传
  pauseFile(file: any): void {
    this.$set(file, "statusStr", "已暂停");
    file.pause();
  }

  // 全部继续
  continueFileAll() {
    this.folderDetails.forEach((i: any) => {
      if (i.statusStr === "已暂停") {
        this.preUpload(i);
        i.statusStr = "上传中";
      }
    });
  }

  // 全部暂停
  pauseFileAll() {
    this.folderDetails.forEach((i: any) => {
      if (i.statusStr === "上传中") {
        i.pause();
        i.statusStr = "已暂停";
      }
    });
  }

  // 去到所在文件夹
  goFolder(file: any): void {
    console.log("--去到所在目录----------file", file);
    this.folderDetailsShow = false;
    let params = {
      id: file.parentId,
      fileId: file.fileId,
      _t: Date.now(),
      goFolderFlag: true,
    };
    let data = {
      teamId: file.teamId,
      id: file.parentId,
      fileId: file.fileId,
      _t: Date.now(),
      goFolderFlag: true,
    };

    console.log("--去到所在目录----------params", params);

    // 这时候表明是在企业团队里
    if (file.moduleType == "team") {
      //@ts-ignore
      this.$router.push({ name: "VIEW_COMPANYTEAM_DOCUMENT", query: data });

      // this.$router.push({ path: "/star/mydoc", query: params });
    } else {
      //@ts-ignore
      this.$router.push({ path: "/star/mydoc", query: params });
    }
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  get uploaderInstance(): any {
    return this.uploaderHTML.uploader;
  }
  get progressAll(): any {
    if (this.filesList.length === 0) {
      return 100;
    } else {
      let succeed = this.filesList.filter((i) => i.statusStr === "上传成功");
      return Math.round((succeed.length / this.filesList.length) * 100);
    }
  }

  // 上传文件列表
  get filesList(): any[] {
    return this.uploaderListVm ? this.uploaderListVm.fileList : [];
  }

  get uploaderTitle(): string {
    if (this.filesList.length === 0) {
      return "上传列表";
    }
    let isEnd = this.filesList.every(
      (i) =>
        i.statusStr === "上传成功" ||
        i.statusStr === "上传失败" ||
        i.statusStr === "部分成功"
    );
    let succeed = this.filesList.filter((i) => i.statusStr === "上传成功");
    if (isEnd) {
      if (this.filesList.every((i) => i.statusStr === "上传成功")) {
        this.uploaderAll = "succeed";
      } else {
        this.uploaderAll = "warning";
      }
      return `上传完成 ${succeed.length}/${this.filesList.length}`;
    } else {
      return `正在上传 ${succeed.length}/${this.filesList.length}`;
    }
  }

  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("uploadClick")
  uploadClickChange() {
    const list = JSON.parse(sessionStorage.fileType);
    this.uploadBtnHTML.$el.children[0].setAttribute(
      "accept",
      list.find(
        (i: { fileType: string; typeList: string }) =>
          i.fileType === this.$route.query.fileType
      )?.typeList || "*/"
    );
    this.uploadBtnHTML.$el.click();
  }
  @Watch("uploadClickFolder")
  uploadClickFolderChange() {
    console.log("点击了文件夹上传");
    this.uploadFolderBtnHTML.$el.click();
  }
  //ref 引用元素 Ref('ref')
  @Ref("uploader")
  uploaderHTML: any;
  @Ref("uploadBtn")
  uploadBtnHTML: any;
  @Ref("uploadFolderBtn")
  uploadFolderBtnHTML: any;
  @Ref("uploaderList")
  uploaderListHTML: any;
  //创建前钩子函数
  created(): void {}
  mounted() {
    this.routerName = (this.$route as any).name;
    this.uploaderListVm = this.uploaderListHTML;
  }
}
