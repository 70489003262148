function emptyAction () {
}
// 设置一个用于通信的 action类



class Action {
  actions={
    onGlobalStateChange: emptyAction,
    setGlobalState: emptyAction
  }
  // eslint-disable-next-line no-useless-constructor
  constructor () {
    //
  }
  // 默认为空Action

  // 设置actions
  setActions (actions) {
    this.actions = actions
  }
  // 映射
  onGlobalStateChange (...args) {
    return this.actions.onGlobalStateChange(...args)
  }
  // 映射
  setGlobalState (...args) {
    return this.actions.setGlobalState(...args)
  }
}
const actions = new Action()



const initialState = {
  toLogin: false, // 默认为false或其他合适的初始值
  // 其他全局状态属性
};

actions.setGlobalState(initialState); // 在初始化时设置全局状态

export default actions
