import { postAction, getAction } from './method'

export default {
    /* 协作文件用户 */
    // 新增文件协作参与用户
    coopUserRelAdd(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/add', data, {}, header)
    },
    // 批量删除协作参与用户
    coopUserBatchDelete(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/batch/delete', data, {}, header)
    },
    //批量编辑协作参与用户
    coopUserBatchUpdate(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/batch/update', data, {}, header)
    },
    // 删除文件协作参与用户
    coopUserRelBatchDelete(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/delete', data, {}, header)
    },
    // 加入协作文件
    coopUserRelJoinCoop(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/joinCoop', {}, data, header)
    },
    // 根据协作文件主键，查询协作文件参与用户列表
    coopUserRelQuery(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/query', data, {}, header)
    },
    // 搜索协作添加人
    coopUserRelSearch(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/search', data, {}, header)
    },
    // 协作文件管理员转换权限给其他成员
    coopUserRelTranPower(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/tranPower', {}, data, header)
    },
    // 编辑文件协作参与用户
    coopUserRelUpdate(data: any, header?: any) {
        return postAction('/star-doc/coop/userRel/update', data, {}, header)
    },

    /* 协作-历史记录 */
    // 新增协作历史分页列表
    coopHistoryAdd(data: any, header?: any) {
        return postAction('/star-doc/coop/history/add', data, {}, header)
    },
    // 查询协作历史分页列表
    coopHistoryPageList(data: any, header?: any) {
        return postAction('/star-doc/coop/history/pageList', data, {}, header)
    },
    // 版本回退
    coopHistoryRollback(data: any, header?: any) {
        return postAction('/star-doc/coop/history/rollback', data, {}, header)
    },

    /* 协作-文件信息 */
    // 新增协作文件
    coopFileInfoAddFile(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/addFile', data, {}, header)
    },
    // 取消协作文件
    coopFileInfoCancel(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/cancel', {}, data, header)
    },
    // 删除协作文件
    coopFileInfoDelete(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/delete', {}, data, header)
    },
    // 查询加入协作文件详情
    coopFileInfoDetail(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/detail', {}, data, header)
    },
    // 退出协作文件
    coopFileInfoExit(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/exit', {}, data, header)
    },
    // 查询协作文件
    coopFileInfoQueryCoopFileList(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/queryCoopFileList', data, {}, header)
    },
    // 查询登陆用户发起的协作文件分页列表
    coopFileInfoQueryCoopList(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/queryCoopList', data, {}, header)
    },
    // 查询登陆用户参与的协作文件分页列表
    coopFileInfoQueryUserCoopList(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/queryUserCoopList', data, {}, header)
    },
    // 加载协作列表用户头像
    coopFileInfoQueryUserImageList(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/queryUserImageList', data, {}, header)
    },
    // 查询指定用户是否有对应权限的协作文件
    coopFileInfoQueryUserPrivilege(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/queryUserPrivilege', data, {}, header)
    },
    // 查看协作文件参与人员
    coopFileInfoQueryUsersByCoopId(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/queryUsersByCoopId', {}, data, header)
    },
    // 协作文件重命名
    coopFileInfoRename(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/rename', data, {}, header)
    },
    // 编辑协作文件
    coopFileInfoUpdate(data: any, header?: any) {
        return postAction('/star-doc/coop/fileInfo/update', data, {}, header)
    },
    // 协作文件另存为
    documentUserFileSaveAs(data: any, header?: any) {
        return postAction('/star-doc/document/userFile/saveAs', data, {}, header)
    },
}