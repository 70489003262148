// 处理文件大小 携带单位，用户可能给文件分享链接在移动端打开
// import { publicFormatSize } from "./publicFormat";

import store from "@/store";
import { message } from "ant-design-vue";
import { Base64 } from "js-base64";

// 定义一个包含支持查看的图片类型的数组
const supportedImageTypes = [
  "PNG",
  "JPG",
  "JPEG",
  "BMP",
  "WEBP",
  "TIFF",
  "SVG",
  "GIF",
  "ICO",
  "HEIC",
];

// 文档预览公共函数
export const publicFileView = (file) => {
  // 先判断是否开启了敏感文件审核
  if (store.state.user.isAudit === 1) {
    // 审核状态判断
    if (file?.auditStatus === 1 || file?.auditStatus === 2) {
      message.warning(
        "应国家净网要求，您上传的文件正在审核中，请您几分钟后再来看看"
      );
    } else if (file?.auditStatus === 4) {
      // 将文件扩展名转换为大写进行比较
      const extensionName = file.extensionName.toUpperCase();
      if (supportedImageTypes.includes(extensionName)) {
        // 如果扩展名在支持的图像类型中，但审核状态为4，则认为是敏感图片
        message.error("敏感图片不支持查看");
      } else {
        message.error("文件包含敏感信息，不支持查看");
      }
    } else {
      viewFileFn(file);
    }
  } else {
    viewFileFn(file);
  }
};

// 预览方法调用
const viewFileFn = (file) => {
  const originUrl = `${process.env.VUE_APP_KK_BASE_URL}/document/userFile/preview?userFileId=${file.actionFileid}&fileSize=${file.actionFileSize}&moduleType=${file.moduleType}`; // 要预览文件的访问地址
  const previewUrl = `${originUrl}&fullfilename=${encodeURIComponent(
    file.actionFileFullName
  )}`;
  const targetUrl = `${
    process.env.VUE_APP_KK_PREVIEW
  }/onlinePreview?url=${encodeURIComponent(Base64.encode(previewUrl))}`;
  // 直接跳转网页查看
  window.open(targetUrl);
};
