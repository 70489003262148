<template>
  <div class="j-icon">
      <!-- 兼容以前图片 -->
    <img :src="imgUrl"
         v-if="isImg"
         :style="{width:width,height:height}">
    <!-- 阿里iconfont自定义图标 -->
    <icon-font v-else-if="isIconfont"
               @click="$emit('click')"
               class="icon"
               :type="iconConfig.iconType"
               :style="{color:iconConfig.iconColor,backgroundColor:iconConfig.iconBgColor}"></icon-font>
    <!-- ant 自带图标 -->
    <ant-icon v-else
              class="icon"
              @click="$emit('click')"
              :component="component"
              :rotate="rotate"
              :type="iconConfig.iconType"
              :style="{color:iconConfig.iconColor,backgroundColor:iconConfig.iconBgColor}"
              :theme="theme"></ant-icon>
  </div>
</template>
<script>
import { Icon } from 'ant-design-vue'
// 阿里iconfont项目图标地址，添加新图标需要更新地址，项目中删除或者修改图标不用
// const IconFont = Icon.createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/font_2883118_ecoolinlfua.js' })
// const IconFont = Icon.createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/font_2883118_x4vtilqxfbs.js' })
// const IconFont = Icon.createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/font_2883118_hl0es535aa.js' })
const IconFont = Icon.createFromIconfontCN({ scriptUrl: '//at.alicdn.com/t/c/font_2883118_fmfj5duwhl9.js' })

export default {
  name: 'CustomIcon',
  props: {
    rotate: {
      default: 0,
      type: Number,
    },
    component: {},
    type: {
      default: '',
      type: String,
    },
    width: {
      default: '30px',
      type: String,
    },
    height: {
      default: '30px',
      type: String,
    },
    theme: {
      default: 'outlined',
      type: String,
    },
  },
  components: {
    AntIcon: Icon,
    IconFont,
  },
  computed: {
    iconConfig() {
      let typeAry = this.type ? this.type.split(',') : []
      return {
        iconType: typeAry[0] || 'none',
        iconColor: typeAry[1] || '',
        iconBgColor: typeAry[2] || '',
      }
    },
    isImg() {
      return this.type?.startsWith('/')
    },
    imgUrl() {
      return `/studio${this.type}`
    },
    isIconfont() {
      return this.type?.includes('icon-')
    },
  },
}
</script>

<style scoped lang="less">
.j-icon {
  display: inline-block;
  .icon {
    border-radius: 4px;
  }
}
</style>
