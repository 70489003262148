
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
@Component({
  name: "FeedbackModal",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class FeedbackModal extends Vue {
  // 提交加载
  public loading: boolean = false;
  // 反馈类型
  public feedBackType: any[] = [];
  // 表单数据
  public formData: any = {
    type: "documentAbnormal",
    description: "",
    contact: "",
  };
  // 校验规则
  public rules: any = {
    type: [{ required: true, message: "请选择反馈类型", trigger: "blur" }],
    description: [
      { required: true, message: "请输入您遇到的问题", trigger: "blur" },
    ],
    contact: [
      { required: true, message: "请留下您的联系方式", trigger: "blur" },
    ],
  };
  // 图片预览显示
  public previewVisible: boolean = false;
  // 文件列表
  public fileList: any[] = [];
  // 预览图
  public previewImage: string = "";

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false })
  readonly visible!: boolean;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("form")
  fromRef!: any;

  // 取消
  handleCancel() {
    this.formData = {
      type: "documentAbnormal",
      description: "",
      contact: "",
    };
    this.fileList = [];
    this.$emit("cancel");
  }
  // 确认
  handleOk() {
    this.fromRef.validate((valid: boolean) => {
      if (valid) {
        this.loading = true;
        console.log(this.fileList);
        let params = new FormData();
        this.fileList.forEach((file) => {
          params.append("files", file.originFileObj);
        });
        params.append("type", this.formData.type);
        params.append("description", this.formData.description);
        params.append("contact", this.formData.contact);
        params.append("process", "notProcessed");
        this.$apis
          .feedbackAdd(params)
          .then((res: any) => {
            if (res.code === "200") {
              this.$message.success("提交成功");
              this.formData = {
                type: "documentAbnormal",
                description: "",
                contact: "",
              };
              this.fileList = [];
              this.$emit("ok");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$message.warning("请检查输入，不能为空！");
      }
    });
  }

  // 图片上传
  handleRemove(file: any) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }
  beforeUpload(file: any) {
    console.log(file);
    if (!file.type.includes("image")) {
      this.$nextTick(() => {
        this.$message.warning("请上传图片格式");
        this.handleRemove(file);
      });
    } else if (file.size > 1024 * 1024) {
      this.$nextTick(() => {
        this.$message.warning("图片大小超过限制,请上传小于1M的图片");
        this.handleRemove(file);
      });
    } else {
      this.fileList = [...this.fileList, file];
    }
    return false;
  }
  async handlePreview(file: any) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  }
  handleChange({ fileList }: any) {
    this.fileList = fileList;
  }
  // 获取字典
  queryDict() {
    this.$apis
      .dictListDictByTypeId({ types: "feedback_type" })
      .then((res: any) => {
        if (res.code === "200") {
          this.feedBackType = res.data.feedback_type.sort(
            (a: any, b: any) => a.sort - b.sort
          );
        }
      });
  }

  //创建前钩子函数
  created(): void {
   // this.queryDict();
  }
}
