<template>
  <div>
    <a-dropdown placement="bottomRight" v-model="visible">
      <div class="headerDocumentTool">
        <img
          style="width: 22px; height: 22px"
          src="../../../assets/images/documentTool/header_right_top/left_icon.png"
          alt=""
        />
        <span class="title">文档工具</span>

        <img
          v-if="visible"
          style="width: 16px; height: 16px"
          src="../../../assets/images/documentTool/header_right_top/right_icon_unfold.png"
          alt=""
        />
        <img
          v-else
          style="width: 16px; height: 16px"
          src="../../../assets/images/documentTool/header_right_top/rigth_icon_fold.png"
          alt=""
        />
      </div>
      <a-menu
        slot="overlay"
        overlayClassName="document-tool-menu"
        class="document-tool-menu"
        data-content="转换"
      >
        <a-menu-item
          v-for="item in menuList"
          :key="item.name"
          @click="changeMenu(item.name)"
        >
          <!-- <img style="width: 32px; height: 32px" :src="item.imgPath" alt="" /> -->
          <img
            :src="item.imgPath"
            style="width: 32px; height: 32px"
            alt=""
          />
          <div class="title">{{ item.text }}</div>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
// import { docToolMenuList } from "@/utils/index";

export default {
  //   props: ["showAddFile"],
  data() {
    return {
      visible: false,
      menuList: [
        {
          type: "transform",
          text: "PDF转Word",
          name: "PDF_Word",
          func: "PDF_Word",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/PDF_Word.svg"),
        },
        {
          type: "transform",
          text: "Word转PDF",
          name: "Word_PDF",
          func: "Word_PDF",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/Word_PDF.svg")
        },
        {
          type: "transform",
          text: "Excel转PDF",
          name: "Excel_PDF",
          func: "Excel_PDF",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/Excel_PDF.svg")
        },
        {
          type: "transform",
          text: "PPT转PDF",
          name: "PPT_PDF",
          func: "PPT_PDF",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/PPT_PDF.svg")
        },
        {
          topLine: true,
          type: "pdfEdit",
          text: "插入图片",
          name: "pdf_edit_addImage",
          func: "pdf_edit_addImage",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_addImage.svg")
        },
        {
          type: "pdfEdit",
          text: "涂画标注",
          name: "pdf_edit_brush",
          func: "pdf_edit_brush",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_brush.svg")
        },
        {
          type: "pdfEdit",
          text: "PDF签名",
          name: "pdf_edit_signature",
          func: "pdf_edit_signature",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_signature.svg")
        },
        {
          type: "pdfEdit",
          text: "添加水印",
          name: "pdf_edit_watermark",
          func: "pdf_edit_watermark",
          disabled: false,
          imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_watermark.svg")
        },
      ],
      // 用于存储加载完成的图片路径
      loadedImgPaths: {},
    };
  },
  watch: {
    //   showAddFile: {
    //     handler(newVal) {
    //       if (newVal) {
    //         this.visible = true;
    //         this.getFilterFileTypeListFn();
    //       }
    //     },
    //     immediate: true,
    //   },
  },
  computed: {},
  created() {
    // this.menuList = docToolMenuList;
  },
  mounted() {
    // console.log("工具函数之文档工具菜单栏----", this.menuList);
  },
  methods: {
    changeMenu(val) {
      console.log("点击了某个菜单", val);
      this.$store.commit("docTool/changeDocToolFrom", "topRightMenu"); // 改变文档工具进入来源
      this.$store.dispatch("docTool/async_setActiveMenu", val); // 改变当前激活的菜单
      setTimeout(() => {
        this.$store.dispatch(
          "docTool/async_changeDocTransformDialogFlag",
          true
        ); // 显示文档转换列表的弹窗
      }, 200);
      this.$store.dispatch(
        "docTool/async_setShowDocToolMinimizedFloatingWindow",
        false // 关闭文档工具最小化悬浮窗
      );
    },
  },
};
</script>

<style lang="less">
.document-tool-menu::before {
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: 700;
  content: "转换"; /* 设置第一个伪元素的内容为“转换” */
  font-size: 16px;
}

.document-tool-menu::after {
  position: absolute;
  left: 20px;
  top: 170px;
  font-weight: 700;
  content: "PDF处理"; /* 设置第二个伪元素的内容为“PDF处理” */
  font-size: 16px;
}

.document-tool-menu {
  padding: 20px !important;
  padding-top: 0 !important;
  width: 480px;
  //   height: 300px;
  border-radius: 12px !important;
  display: flex;
  flex-wrap: wrap;
  .ant-dropdown-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 110px;
    border-radius: 6px !important;
    height: 88px;
    padding: 12px !important;
    margin-top: 60px;
  }
  .ant-dropdown-menu-item-active {
    background-color: #f1f2f5 !important;
  }
}

.headerDocumentTool {
  cursor: pointer;
  width: 146px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 18px;
  background-image: url("../../../assets/images/documentTool/header_right_top/btn.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .title {
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    text-shadow: 4px 4px 20px #424489;
    text-align: left;
    font-style: normal;
  }
}
</style>
@/utils/index
