import { getAction, postAction } from './method'

export default {



    // 邮件往来附件
    // userAttachmentFiles(data: any, header: any) {
    //     return postAction('/studio/mail-client-api/mailInfo/listAttachment', data, {}, header)
    // },

    // 查询所有部门
    getAllDepartment(data: any) {
        return getAction(`studio/auth/sys/sysDepart/queryTreeListWithUser`, data)
    },


    // 查询租户下的职位分组信息
    getJobGroupInfo(data: any) {
     return   getAction("studio/auth/sys/position/queryTreeList", data);
    },

    getPermissionGroupInfo (data: any) {
        return    getAction("studio/auth/sys/roleGroup/queryTreeList", data)
    },

    // 添加成员或者角色模糊查询
    searchMemberOrRole(data: any) {
        return getAction(`studio/auth/sys/user/member/search`, data)
    },

    // 添加成员或者角色模糊查询
    searchDepart(data: any) {
        return getAction(`studio/auth/sys/sysDepart/searchBy`, data)
    },






}