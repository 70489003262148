export const downloadDocFile = (fileId) => {
  console.log("点击了下载--fileId", fileId);
  const wrap = document.createElement("div");
  wrap.setAttribute("style", "display: none");
  document.body.appendChild(wrap);
  const frame = document.createElement("iframe"); //创建a对象
  frame.setAttribute("src", `/star-doc/file/download?userFileId=${fileId}`);
  frame.setAttribute("class", "download-iframe");
  wrap.appendChild(frame);
  setTimeout(() => {
    document.body.removeChild(wrap);
  }, 60000);
};
