
import Vue from "vue";
import PlusModal from "./MenuComponents/PlusModal.vue";
import MenuUserinfo from "./MenuComponents/MenuUserinfo.vue";
import SystemMenu from "./MenuComponents/SystemMenu.vue";
import GlobalDocTool from "@/components/GlobalDocTool/index.vue"; // 文档工具弹窗
import GlobalDocToolMinimizedFloatingWindow from "@/components/GlobalDocToolMinimizedFloatingWindow/index.vue"; // 文档工具最小化浮动窗口
// import GlobalUploader from "@/components/GlobalUploader/index.vue";

import TagList from "@/components/tag/TagList.vue";
import { Progress } from "ant-design-vue";
import { log } from "console";
import { Collapse } from "ant-design-vue";
const { Panel } = Collapse;

interface FileType {
  icon: string;
  title: string;
  extensionName: string;
}

const tagList: any[] = [];
const plusList: FileType[] = [
  {
    icon: "iconDoc",
    title: "新建文档 (wordx)",
    extensionName: "docx",
  },
  {
    icon: "iconXls",
    title: "新建表格 (excel)",
    extensionName: "xlsx",
  },
  // {
  //   icon: "iconText",
  //   title: "新建文本 (txt)",
  //   extensionName: "txt",
  // },
  {
    icon: "iconPpt",
    title: "新建幻灯片 (ppt)",
    extensionName: "pptx",
  },
];

export default Vue.extend({
  name: "Menu",
  components: {
    GlobalDocToolMinimizedFloatingWindow, // 全局文档工具--最小化浮窗
    GlobalDocTool, // 全局文档工具--添加文件列表&转换弹窗
    PlusModal,
    // GlobalUploader,
    MenuUserinfo,
    SystemMenu,
    TagList,
    "a-progress": Progress,
    "a-collapse": Collapse,
    [Panel.name]: Panel,
  },
  directives: {
    heightSize: {
      inserted(el, binding) {
        binding.value(el.offsetHeight);
      },
    },
  },
  props: {},
  data() {
    return {
      showAddTeamFlag: false,
      activeKey: ["1"] as any[],
      activeKeyPerson: ["1"] as any[],
      activeKeyTag: ["1"] as any[],
      plusList,
      // 新建数据
      plusData: {
        visible: false,
        addType: "",
        title: "",
        formModel: {
          value: "",
        },
      },
      UploaderList: [
        {
          icon: "shangchuanwenjian",
          title: "上传文件",
          functionCode: "UPLOAD_LOCAL_FILE",
          emit: (this as any).handleUpload,
        },
        {
          icon: "shangchuanwenjianjia",
          title: "上传文件夹",
          functionCode: "UPLOAD_LOCAL_DIR",
          emit: (this as any).handleUploadFolder,
        },
      ],

      // 菜单列表
      menuList: [
        {
          title: "个人文档",
          icon: "document",
          action: false,
          isUnderline: false,
          path: "/star/mydoc",
          routeNmae: "VIEW_MY_DOCUMENT",
          submenuList: [
            // {
            //   // id: '01',
            //   icon: 'iconFolder',
            //   action: true,
            //   title: '全部文件'
            // },
            {
              id: "doc",
              icon: "iconDoc",
              action: false,
              title: "文档",
            },
            {
              id: "video",
              icon: "iconMV",
              action: false,
              title: "音视频",
            },
            {
              id: "image",
              icon: "iconImg",
              action: false,
              title: "图片",
            },
            {
              id: "compress",
              icon: "iconZIP",
              action: false,
              title: "压缩包",
            },
            {
              id: "other",
              icon: "iconFolder",
              action: false,
              title: "其他",
            },
          ],
        },

        {
          title: "我的分享",
          icon: "share",
          action: false,
          isUnderline: false,
          path: "/star/share",
          routeNmae: "VIEW_SHARE",
        },
        // {
        //   title: "协作办公",
        //   icon: "cooperation",
        //   action: false,
        //   path: "/star/cooperation",
        //   routeNmae: "VIEW_COOPERATION",
        // },
        {
          title: "我的收藏",
          icon: "favorite",
          action: false,
          // isUnderline: true,
          isUnderline: false,
          path: "/star/favorite",
          routeNmae: "VIEW_MY_FAVORITE",
        },
        // {
        //   title: "来自:导入文件",
        //   icon: "favorite",
        //   action: false,
        //   isUnderline: false,
        //   path: "/star/fileImport",
        //   routeNmae: "VIEW_FILEIMPORT",
        // },
        {
          title: "邮件往来附件",
          icon: "document",
          action: false,
          isUnderline: false,
          path: "/star/attachment",
          routeNmae: "VIEW_ATTACHMENT",
        },
      ],

      // 无需折叠其他的菜单
      noFoldingMenuList: [
        {
          title: "知识库",
          icon: "knowledgeBase",
          action: false,
          isUnderline: false,
          path: "/star/knowledgeBase",
          routeNmae: "VIEW_KNOWLEDGEBASE",
        },

        {
          title: "最近查看",
          icon: "recently",
          action: false,
          isUnderline: false,
          path: "/star/mydoc?tabIndex=true",
          routeNmae: "VIEW_MY_DOCUMENT",
        },
        {
          title: "我的分享",
          icon: "share",
          action: false,
          isUnderline: false,
          path: "/star/share",
          routeNmae: "VIEW_SHARE",
          key: "",
        },
        {
          title: "我的收藏",
          icon: "favorite",
          action: false,
          // isUnderline: true,
          isUnderline: false,
          path: "/star/favorite",
          routeNmae: "VIEW_MY_FAVORITE",
        },
        {
          title: "邮件往来附件",
          icon: "document",
          action: false,
          isUnderline: false,
          path: "/star/attachment",
          routeNmae: "VIEW_ATTACHMENT",
        },
      ],

      // 其他菜单列表
      otherMenuList: [
        {
          title: "企业团队空间",
          icon: "menuTeam",
          action: false,
          path: "/star/companyTeam",
          routeNmae: "VIEW_COMPANYTEAM",
        },
        // {
        //   title: "云库空间",
        //   icon: "cloud",
        //   action: false,
        //   path: "/*",
        //   routeNmae: "",
        // },
      ],
      // 上传文件位置
      uploaderTop: "192px",
      // 标签列表
      tagList,
      // 菜单收缩
      menuShrink: false,
      // 菜单高度
      menuHeight: {
        otherMenu: "0px",
        teamList: "0px",
        menuList: "0px",
      },
      // 菜单高度是否变化
      change: false,
    };
  },
  computed: {
    // 文档转换列表的弹窗的显示隐藏
    docTransformDialogFlag() {
      return this.$store.state.docTool.docTransformDialogFlag;
    },
    // 文档工具转换列表的最小化悬浮窗口的显示隐藏
    showDocToolMinimizedFloatingWindow() {
      return this.$store.state.docTool.showDocToolMinimizedFloatingWindow;
    },

    actives() {
      return this.$route.fullPath;
    },
    // 是否是个人邮箱企业
    personalMailboxEnterprise(): any {
      return (this.$store as any).state.user.personalMailboxEnterprise;
    },

    // 个人总容量
    personTotalStorage(): any {
      return (this.$store as any).state.user.personTotalStorage;
    },
    // 个人使用容量
    personUseStorage(): any {
      return (this.$store as any).state.user.personUseStorage;
    },
    // 个人容量使用百分比
    personStorageRate(): any {
      return (this.$store as any).state.user.personStorageRate;
    },
    // 企业总容量
    companyTotalStorage(): any {
      return (this.$store as any).state.user.companyTotalStorage;
    },
    // 企业使用容量
    companyUseStorage(): any {
      return (this.$store as any).state.user.companyUseStorage;
    },
    // 企业容量百分比
    companyStorageRate(): any {
      return (this.$store as any).state.user.companyStorageRate;
    },

    moveShow(): boolean {
      return (this.$store as any).state.menu.move;
    },
    coordinate(): number {
      return (this.$store as any).state.menu.watchCoordinate;
    },
    commonFileList(): Array<any> {
      return (this.$store as any).state.menu.commonFileList;
    },
    otherMenuView(): boolean {
      return this.$route.path.includes("menu");
    },
    tagLoading(): boolean {
      return (this.$store as any).state.menu.tagLoading;
    },
    menuTagList(): Array<any> {
      return (this.$store as any).state.menu.tagList;
    },
    teamList(): Array<any> {
      return (this.$store as any).state.team.teamList;
    },
    // modalType(): string {
    //   return (this.$store as any).state.menu.modalType;
    // },
    // 标签高度
    // tagHeight(): String {
    //   console.log({ ...this.menuHeight });
    //   if (this.change) {
    //     return "30px";
    //   } else {
    //     let computHeight =
    //       parseInt(this.menuHeight.otherMenu) +
    //       parseInt(this.menuHeight.teamList) +
    //       parseInt(this.menuHeight.menuList) +
    //       "px";
    //     return `calc(100% - 104px - ${computHeight})`;
    //   }
    // },
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.menuList.forEach(
          (i: any) => (i.action = newVal.path.includes(i.path))
        );
        this.otherMenuList.forEach(
          (i: any) => (i.action = newVal.path.includes(i.path))
        );

        if (newVal.name == "VIEW_MY_DOCUMENT" && !newVal.query.fileType) {
          // @ts-ignore
          this.menuList[0].submenuList = this.menuList[0].submenuList.map(
            (item: { id: any }) => {
              if (!item.id) {
                return { ...item, action: true };
              } else {
                return { ...item, action: false };
              }
            }
          );
        } else if (newVal.name == "VIEW_MY_DOCUMENT" && newVal.query.fileType) {
          // @ts-ignore
          this.menuList[0].submenuList = this.menuList[0].submenuList.map(
            (item: { id: any }) => {
              if (item.id === newVal.query.fileType) {
                return { ...item, action: true };
              } else {
                return { ...item, action: false };
              }
            }
          );
        }

        console.log("value", newVal);
      },
      deep: true,
      immediate: true,
    },
    coordinate() {
      this.$nextTick(() => {
        this.queryCoordinate();
      });
    },
    menuTagList: {
      handler(newVal) {
        this.tagList = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true,
    },
    // teamList: {
    //   handler() {
    //     this.setMenuHeight();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // menuShrink: {
    //   handler() {
    //     this.setMenuHeight();
    //   },
    // },beforeDestroy
  },
  created() {
    this.$apis
      .userRole()
      .then((res: any) => {
        console.log("用户身份res", res);
        if (res && res.data && res.data.isAdmin) {
          this.showAddTeamFlag = true;
        } else {
          this.showAddTeamFlag = false;
        }
      })
      .catch((err: any) => {
        this.showAddTeamFlag = false;
        console.log(
          "🚀 ~ file: CompanyTeamTable.vue:363 ~ CompanyTeamTable ~ created ~ err:",
          err
        );
      });

    // if (this.$store.state.user.companyId !== "person") {

    // this.$store.dispatch(
    //   "team/GET_TEAMLIST",
    //   this.$store.state.user.companyId
    // );

    // this.queryTeamList()

    this.$store.dispatch("menu/GET_TAGLIST");
  },
  beforeDestroy() {
    // @ts-ignore
    window.removeEventListener("resize", this.adjustHeight);
  },
  mounted() {
    // @ts-ignore
    this.adjustHeight();
    // @ts-ignore
    window.addEventListener("resize", this.adjustHeight);
  },
  methods: {
    // 添加团队
    handlePlus(item: any) {
      this.routerChange(item);
      // 通过深沉查早其他兄弟组件方法 调用打开相应的新建团队弹窗
      setTimeout(() => {
        const that = this.$parent?.$parent?.$children[1]?.$children[0]
          ?.$children[0]?.$children[0] as any;
        that.handlePlus();
      }, 0);
    },

    adjustHeight() {
      const windowHeight = window.innerHeight;
      const logoHeight = 80;
      const buttonGroupHeight = 10;
      const capacityHeight = 164;
      const recycleBinHeight = 60;
      const totalFixedHeight =
        logoHeight + buttonGroupHeight + capacityHeight + recycleBinHeight;
      const maxHeightForFixedHeight = windowHeight - totalFixedHeight;

      // 使用Vue的样式绑定或直接在DOM上设置样式
      // @ts-ignore
      this.$refs.fixedHeight.style.maxHeight = `${
        maxHeightForFixedHeight - 25
      }px`;
      // @ts-ignore
      this.$refs.fixedHeight.style.overflowY = "auto";
    },

    emptyEvent() {
      console.log("置空");
    },
    togglePanel(value: boolean) {
      if (value) {
        this.activeKey = ["1"];
      } else {
        this.activeKey = [];
      }
    },
    togglePanelPerson(value: boolean) {
      if (value) {
        this.activeKeyPerson = ["1"];
      } else {
        this.activeKeyPerson = [];
      }
    },
    togglePanelTag(value: boolean) {
      if (value) {
        this.activeKeyTag = ["1"];
      } else {
        this.activeKeyTag = [];
      }
    },
    // queryTeamList() {
    //   // 先掉一个默认团队的接口
    //   let companyId = this.$store.state.user.companyId
    //   let companyList = this.$store.state.user.companyList
    //   const company = companyList.find((obj: { id: any; }) => obj.id == companyId);
    //   this.$apis
    //     .companyTeamDefaultPageList({
    //       companyId: this.$store.state.user.companyId,
    //       companyName: company.name
    //     })
    //     .then((res: any) => {
    //       if (res.code === "200") {
    //         this.$apis
    //           .companyTeamPageList({
    //             companyId: this.$store.state.user.companyId,
    //             isPage: 0,
    //           })
    //           .then((res: any) => {
    //             if (res.code === "200") {
    //               // this.teamList = res.data.list;
    //               this.$store.commit('team/SET_TEAMLIST', res.data.list)
    //               // console.log('企业团队res', this.teamList);
    //             }
    //           });

    //       }
    //     });
    // },

    handleAddFile(type: string | FileType) {
      if (type === "folder") {
        this.plusData = {
          title: "新建文件夹",
          addType: type,
          visible: true,
          formModel: { value: "" },
        };
      } else {
        this.plusData = {
          title: (type as FileType).title,
          addType: type as any,
          visible: true,
          formModel: { value: "" },
        };
      }
    },
    plusModalClose() {
      this.plusData = (this.$options as any).data().plusData;
    },
    // 点击上传文件
    handleUpload() {
      let id: string = (this.$route.query.id as string) || "";

      this.$store.commit("upload/SET_UPLOADCLICK", id);
    },
    // 点击上传文件夹
    handleUploadFolder() {
      let id: string = (this.$route.query.id as string) || "";
      console.log("点击上传文件夹--id", id);
      // this.$store.commit("upload/setUploadClickFolder", id);
      this.$store.commit("upload/SET_UPLOADCLICKFOLDER", id);
    },
    routerChange(route: any) {
      if (route.title == "企业团队") {
        // 个人文档的子菜单的选中状态移除
        // @ts-ignore
        this.menuList[0].submenuList = this.menuList[0].submenuList.map(
          (item: { id: any }) => {
            return { ...item, action: false };
          }
        );
      } else {
        console.log("route.title", route.title);

        if (route.title == "个人文档") {
          // window.sessionStorage.setItem('showAicreationFlag', 'false')
          this.$store.commit("user/setShowAicreationFlag", false);
        }

        // 选中个人文档时默认选中全部文件子菜单
        // @ts-ignore
        this.menuList[0].submenuList = this.menuList[0].submenuList.map(
          (item: { title: any }) => {
            if (item.title === "全部文件") {
              return { ...item, action: true };
            } else {
              return { ...item, action: false };
            }
          }
        );
      }
      this.$store.commit("user/setShowAicreationFlag", false);
      this.$router.push({ path: route.path });
    },
    // 点击企业下的团队左侧菜单
    goTeam(value: any) {
      // 个人文档的子菜单的选中状态移除
      // @ts-ignore
      this.menuList[0].submenuList = this.menuList[0].submenuList.map(
        (item: { id: any }) => {
          return { ...item, action: false };
        }
      );

      this.$router.push({
        path: "/star/companyTeam/document",
        query: { teamId: value.id },
      });
    },
    goDoc(value: any) {
      console.log("------value", value);

      // 关闭ai创建界面
      this.$store.commit("user/setShowAicreationFlag", false);

      // 假设 this.menuList.submenuList 是一个对象数组
      const idToMatch = value.id; // 要匹配的 id
      // @ts-ignore
      this.menuList[0].submenuList = this.menuList[0].submenuList.map(
        (item: { id: any }) => {
          if (item.id === idToMatch) {
            return { ...item, action: true };
          } else {
            return { ...item, action: false };
          }
        }
      );

      this.$router.push({
        path: "/star/mydoc",
        query: {
          fileType: value.id,
        },
      });
    },
    queryCoordinate() {
      let coordinate = (
        this.$refs.recycle as HTMLDivElement
      ).getBoundingClientRect();
      this.$store.commit("menu/SET_MOVECOORDINATE", coordinate);
    },
    deleteOftenFile(value: any) {
      let that = this;
      this.$confirm({
        title: "确认要删除该常用文件吗?",
        okText: "是",
        okType: "danger",
        cancelText: "否",
        onOk() {
          that.$apis.commonFileDlete({ id: value.id }).then((res: any) => {
            if (res.code === "200") {
              that.$message.success("删除成功");
              that.$store.dispatch("menu/GET_COMMONFILELIST");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    // 点击标签
    clickTag(tag: any) {
      this.$router.push({
        path: "/star/tag",
        query: { tagId: tag.id, tagName: tag.tagName },
      });
    },
    // 新增标签
    handleAddTag() {
      if (!this.activeKeyTag.length) {
        this.activeKeyTag = ["1"];
      }

      if (this.tagList.some((i) => i.isEdit)) {
        this.$message.warning("存在编辑的标签未提交");
      } else {
        this.tagList.unshift({
          tagName: "",
          isEdit: true,
        });
      }
    },
    // 修改标签
    handleTagUpdate(value: any) {
      if (this.tagList.some((i) => i.isEdit)) {
        this.$message.warning("存在编辑的标签未提交");
      } else {
        let tag = this.tagList.find((i) => i.id === value.id);
        this.$set(tag, "isEdit", true);
      }
    },
    // 标签操作
    handleTag(value: any, index: number) {
      if (!value[1].id) {
        if (value[0] === "") {
          // 空id为新增的标签 内容为空将其去除
          this.tagList.splice(index, 1);
        } else {
          this.$store.commit("menu/SET_TAGLOADING", true);
          this.$apis
            .documentTagAddTag({
              tagName: value[0],
              companyId: this.$store.state.user.companyId,
            })
            .then((res: any) => {
              if (res.code === "200") {
                this.$message.success("新增标签成功");
              }
            })
            .finally(() => {
              this.$store.dispatch("menu/GET_TAGLIST");
            });
        }
      } else {
        this.$store.commit("menu/SET_TAGLOADING", true);
        this.$apis
          .documentTagUpdateTag({
            id: value[1].id,
            tagName: value[0],
          })
          .then((res: any) => {
            if (res.code === "200") {
              this.$message.success("修改标签成功");
            }
          })
          .finally(() => {
            this.$store.dispatch("menu/GET_TAGLIST");
          });
      }
    },
    // 删除标签
    handleDelete(tag: any) {
      this.$store.commit("menu/SET_TAGLOADING", true);
      this.$apis
        .documentTagDelTagByIds({ tagIds: [tag.id] })
        .then((res: any) => {
          if (res.code === "200") {
            this.$message.success("标签删除成功");
          }
        })
        .finally(() => {
          this.$store.dispatch("menu/GET_TAGLIST");
        });
    },
    goToContents(value: any) {
      console.log(value);
      let params = {
        fileId: value.userFileId,
        id: value.parentId,
      };
      this.$router.push({ path: "/star/mydoc", query: params });
    },
    setMenuHeight() {
      console.log("高度变化");
      this.change = true;
      setTimeout(() => {
        this.change = false;
        let otherMenu: any = this.$refs.otherMenu;
        let teamList: any = this.$refs.teamList;
        let menuList: any = this.$refs.menuList;
        this.menuHeight.otherMenu = otherMenu
          ? getComputedStyle(otherMenu).height
          : "0px";
        this.menuHeight.teamList = teamList
          ? getComputedStyle(teamList).height
          : "0px";
        this.menuHeight.menuList = menuList
          ? getComputedStyle(menuList).height
          : "0px";
      });
    },
    setModalType(type: String) {
      this.$store.commit("menu/SET_MODALTYPE", type);
    },
    // 设置新建上传框的高度偏移值
    // setModalTop(size: number) {
    //   this.plusTop = size + 129 + "px";
    //   this.uploaderTop = size + 183 + "px";
    // },
  },
});
