
import Vue from "vue";
import Header from "./Header.vue";
import Menu from "./Menu.vue";
export default Vue.extend({
  name: "Layout",
  components: {
    Header,
    Menu,
  },
  props: {},
  data() {
    return {
      PublicmodalShow: false,
      PrivatemodalShow: false,
    };
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {},
});
