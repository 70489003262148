// 文档工具操作菜单项
export const docToolMenuList = [
  {
    type:'transform',
    text: "PDF转Word",
    name: "PDF_Word",
    func: "PDF_Word",
    disabled: false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/PDF_Word.svg"),
  },
  {
    type:'transform',
    text: "Word转PDF",
    name: "Word_PDF",
    func: "Word_PDF",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/Word_PDF.svg"),
  },
  {
    type:'transform',
    text: "Excel转PDF",
    name: "Excel_PDF",
    func: "Excel_PDF",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/Excel_PDF.svg"),
  },
  {
    type:'transform',
    text: "PPT转PDF",
    name: "PPT_PDF",
    func: "PPT_PDF",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/PPT_PDF.svg"),
  },
  {
    topLine: true,
    type:'pdfEdit',
    text: "插入图片",
    name: "pdf_edit_addImage",
    func: "pdf_edit_addImage",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_addImage.svg"),
  },
  {
    type:'pdfEdit',
    text: "涂画标注",
    name: "pdf_edit_brush",
    func: "pdf_edit_brush",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_brush.svg"),
  },
  {
    type:'pdfEdit',
    text: "PDF签名",
    name: "pdf_edit_signature",
    func: "pdf_edit_signature",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_signature.svg"),
  },
  {
    type:'pdfEdit',
    text: "添加水印",
    name: "pdf_edit_watermark",
    func: "pdf_edit_watermark",
    disabled:false,
    imgPath: require("@/assets/svg/documentTool/actionMenu/pdf_edit_watermark.svg"),
  },
];
