import { getAction, postAction, postActionUpload, queryImage } from "./method";

export default {
  // 上传素材(PDF文件)
  uploadPdfHttp(data: any, callback: Function) {
    return postActionUpload(
      "/star-doc/document/tools/file/upload",
      data,
      callback
    );
  },

    // 临时md
    mdTempHttp(data: any, header: any) {
      return postAction("/star-doc/document/userFile/resource/markdown", data, {}, header);
    },

  // 添加签名模板/水印模板
  addSignatureHttp(data: any, header: any) {
    return postAction("/star-doc/file/tools/material/create", data, {}, header);
  },
  // 编辑签名模板
  edidSignatureHttp(data: any, header: any) {
    return postAction("/star-doc/file/tools/material/update", data, {}, header);
  },


  // 删除水印/签名模板
  removeWaterListHttp(data: any, header: any) {
    return postAction("/star-doc/file/tools/material/remove", data, {}, header);
  },

  // 获取水印/签名列表
  getPdfmaterialListkHttp(data: any, header: any) {
    return postAction("/star-doc/file/tools/material/list", data, {}, header);
  },

  // 生成临时水印文件
  addPdfWatermarkHttp(data: any, header: any) {
    return postAction(
      "/star-doc/document/watermark/temporary/file/create",
      data,
      {},
      header
    );
  },

  // 最终保存
  savePdfWatermarkHttp(data: any, header: any) {
    return postAction(
      "/star-doc/document/watermark/text/add",
      data,
      {},
      header
    );
  },

  // 设置pdf文件临时水印
  getPdfWatermarkTempFileHttp(data: any) {
    return getAction(
      "/star-doc/document/watermark/temporary/file/resource",
      data
    );
  },

  // 获取pdf文件资源
  getPdfEesourceHttp(data: any) {
    return getAction("/star-doc/file/resource", data);
  },

  // 转换记录的转换重试
  converterRetryHttp(data: any, header: any) {
    return postAction("/star-doc/document/converter/retry", data, {}, header);
  },

  // 清空文件转换任务
  clearConversionListHttp(data: any) {
    return postAction("/star-doc/document/converter/current/clear",data);
  },

  // 过滤类型的文件列表
  filterFileTypeListHttp(data: any, header: any) {
    return postAction("/star-doc/user/file/document/query", data, {}, header);
  },

  // 提交文档转换
  converterApplyHttp(data: any, header: any) {
    return postAction("/star-doc/document/converter/apply", data, {}, header);
  },

  // 文档转换状态
  converterStatusHttp(data: any) {
    return postAction("/star-doc/document/converter/status",data);
  },
};
