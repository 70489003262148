
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "TagList",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class TagList extends Vue {
  public tagValue: string = "";
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop()
  readonly tag!: any;

  rechristen(): void {
    this.tagValue = this.tag.tagName;
    this.$emit("update", this.tag);
  }

  updateTag(): void {
    if (this.tag.id) {
      if (this.tagValue === "") {
        this.$message.warning("标签名不能为空");
        return;
      } else if (this.tagValue === this.tag.tagName) {
        this.tag.isEdit = false;
      } else {
        this.tag.isEdit = false;
        this.$emit("snedTag", this.tagValue, this.tag);
      }
    } else {
      this.tag.isEdit = false;
      this.$emit("snedTag", this.tagValue, this.tag);
    }
  }

  deleteTag(): void {
    let that = this;
    this.$confirm({
      title: "确认删除",
      content:
        "确定要删除吗？删除后不影响文件的正常使用，仅删除标签标记，请知悉！",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        that.$emit("delete", that.tag);
      },
    });
  }

  handleClick(): void {
    this.$emit("sendClick", this.tag);
  }

  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
