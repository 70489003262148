
import Vue from "vue";
import GlobalUploader from "@/components/GlobalUploader/index.vue";
// @ts-ignore
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

export default Vue.extend({
  data() {
    return {
      rootId: process.env.VUE_APP_WEB_ROOT_ID,
      zh_CN: zh_CN,
    };
  },
  components: {
    GlobalUploader,
  },
});
