
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
const UserModule = namespace("user");
const UserCommon = namespace("common");

interface FileType {
  icon: string;
  title: string;
  extensionName: string;
}

interface PlusData {
  visible: boolean;
  addType: string | FileType;
  title: string;
  formModel: any;
}
@Component({
  name: "PlusModal",
  filters: {
    //局部过滤器
  },
})
export default class PlusModal extends Vue {
  @UserModule.State("companyId") companyId!: string;
  @UserCommon.Mutation("TO_RELOAD") toReload!: () => void;

  public loading: boolean = false;
  public rules: any = {
    value: [{ required: true, message: "请输入名称", trigger: "blur" },
    { validator: this.checkNonEmptyString, trigger: 'change' }
    ],
  };


  checkNonEmptyString(rule: any, value: string, callback: (arg0: Error | undefined) => void) {
    if (!value || value.trim() === '') {
      callback(new Error('不能输入空字符串'));
    } else {
      // @ts-ignore 
      callback(); // 在这里调用 callback，表示验证成功  
    }
  }

  onClickOk(): void {

    // this.formModelRef.validateField('this.plusData.formModel.value')

    this.formModelRef.validate((valid: boolean) => {


      // let str=this.plusData.formModel.value.trim()
      // console.log('plusData.formModel.value',str);

      // if(!str) {
      //   alert('不能为空字符串')
      // }


      if (valid) {
        // return
        this.loading = true;
        let moduleType: string = this.$route.path.includes(
          "companyTeam/document"
        )
          ? "team"
          : "doc"; // 模块类型:doc-个人文档,team-企业团队
        if (this.plusData.addType === "folder") {
          let params = {
            parentId: this.$route.query.fileType?this.parentId:(this.$route.query.id ? this.$route.query.id : ""),
            userFileName: this.plusData.formModel.value,
            teamId: this.$route.query.teamId || undefined,
          };
          let api: string =
            moduleType === "team"
              ? "companyFileAddFolder"
              : "userFileAddFolder";
          this.$apis[api](params)
            .then((res: any) => {
              this.$emit('close')
              this.$message.success("新增成功");
              this.toReload();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          let params = {
            extensionName: (this.plusData.addType as FileType).extensionName,
            fileName: this.plusData.formModel.value,
            parentId: this.$route.query.id ? this.$route.query.id : "",
            moduleType: moduleType,
            teamId: this.$route.query.teamId || undefined,
            companyId: moduleType === "doc" ? "person" : this.companyId,
          };
          this.$apis
            .userFileAdd(params)
            .then((res: any) => {
              this.$emit('close')
              this.$message.success("新增成功");
              setTimeout(() => {
                let query = {
                  userFileId: res,
                  moduleType: moduleType,
                  fileExtension: (this.plusData.addType as FileType).extensionName,
                  fileName: this.plusData.formModel.value,
                  ot: "edit",
                };
                let routeData = this.$router.resolve({
                  name: "VIEW_OFFICE_ONLINE",
                  query,
                });
                window.open(routeData.href, "_blank"); // 新窗口打开
              }, 300);
              this.toReload();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({
    type: Object,
    required: true,
  })
  plusData!: PlusData;
  @Prop({
    type:String,
    default:''
  })
  parentId!:''
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  @Ref("formModel")
  formModelRef: any;
  //创建前钩子函数
  created(): void { }
}
