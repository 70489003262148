import { postAction, getAction } from './method'
import store from "@/store";

export default {
    // 回收站列表
    queryRecycleList(data: { isPage: number, companyId: string } = { isPage: 0, companyId: 'person' }) {
        return postAction('/star-doc/document/recycleBin/queryList', data).then((res) => res.data)
    },
    // 恢复文件
    recoverFile(data: { id: string | number }) {
        return postAction('/star-doc/document/recycleBin/recover?id=' + data.id, data)
    },
    // 批量恢复文件
    batchRecoverFile(data: { ids: string | number }) {
        return postAction('/star-doc/document/recycleBin/batch/recover?ids=' + data.ids, data)
    },
    // 删除文件
    deleteFile(data: { id: string | number }) {
        return postAction('/star-doc/document/recycleBin/delete?id=' + data.id)
    },
    // 批量删除
    batchDeleteFile(data: { ids: string | number }) {
        return postAction('/star-doc/document/recycleBin/batch/delete?ids=' + data.ids)
    },
    // 查询文件重名
    checkRepeat(data: { id: string | number }) {
        return postAction('/star-doc/document/recycleBin/checkRepeat?id=' + data.id, data)
    },
    // 批量查询文件重名
    batchCheckRepeat(data: { ids: string | number }) {
        return postAction('/star-doc/document/recycleBin/batch/checkRepeat?ids=' + data.ids, data)
    },


    /* 团队回收站 */
    // 检查操作权限
    companyTeamRecycleBinCheckPermission(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/recycleBin/checkPermission', data, {}, header)
    },
    // 查询文件重名
    companyTeamRecycleBinCheckRepeat(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/recycleBin/checkRepeat', data, {}, header)
    },
    // 删除文件
    companyTeamRecycleBinDelete(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/recycleBin/delete', data, {}, header)
    },
    // 列表查询
    companyTeamRecycleBinQueryList(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/recycleBin/queryList', data, {}, header)
    },
    // 恢复文件
    companyTeamRecycleBinRecover(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/recycleBin/recover', data, {}, header)
    },
}