
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dayjs from "dayjs";

const UserModule = namespace("user");
@Component({
  name: "MenuUserinfo",
  components: {},
  filters: {
    //局部过滤器
    hello(name: string): string {
      let hour: number = new Date().getHours();
      if (hour >= 6 && hour < 8) {
        return `亲爱的${name},早上好!`;
      } else if (hour >= 8 && hour < 11) {
        return `亲爱的${name},上午好!`;
      } else if (hour >= 11 && hour < 13) {
        return `亲爱的${name},中午好!`;
      } else if (hour >= 13 && hour < 18) {
        return `亲爱的${name},下午好!`;
      } else {
        return `亲爱的${name},晚上好!`;
      }
    },
    // 时间格式化
    timeFormat(time: string | number) {
      return dayjs(time).format("YYYY-MM-DD HH:mm");
    },
  },
})
export default class MenuUserinfo extends Vue {
  @UserModule.State("name") name!: string;
  @UserModule.State("avatarImage") avatarImage!: string;
  @UserModule.State("lastLoginTime") lastLoginTime!: string;

  goToUserInfo(): void {
    this.$router.push({
      path: "/star/menu/userinfo",
      query: { type: "system" },
    });
  }
  goTologinRecord(): void {
    this.$router.push({
      path: "/star/menu/loginRecord",
      query: { type: "system" },
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
}
