import store from '@/store'

export default {
    methods: {
        
        functionalCheck(code: string | undefined) {
            if(!code) return true
            // let functionCodes = (store.state as any).user.functionCodes
            // return functionCodes.includes(code)
        }
    },
}