import { getAction, postAction, queryImage } from "./method";

export default {
    // 获取md文档内容
    readMdHttp(data: any, header: any) {
      return postAction("/star-doc/document/userFile/resource/markdown/read", data, {}, header);
    },
    // 编辑更新md文档
    updateMdFile(data: any, header: any, ) {    
      return postAction('/star-doc/document/userFile/update', data, {}, header)
  },
};
