
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { Empty } from "ant-design-vue";
import { namespace } from "vuex-class";

// 注释ts校验
// @ts-ignore
import { publicFormatSize, publicFileView } from "@/utils/index";

const ShareModule = namespace("share");
@Component({
  name: "SearchContent",
  components: {
    [Empty.name]: Empty,
  },
  filters: {
    //局部过滤器
    calculateFileSize(size: number): string {
      const B = 1024;
      const KB = Math.pow(1024, 2);
      const MB = Math.pow(1024, 3);
      const GB = Math.pow(1024, 4);
      if (size === 0) {
        return "0KB";
      } else if (!size) {
        return "_";
      } else if (size < KB) {
        return `${(size / B).toFixed(0)}KB`;
      } else if (size < MB) {
        return (size / KB).toFixed(1) + "MB";
      } else if (size < GB) {
        return (size / MB).toFixed(2) + "GB";
      } else {
        return (size / GB).toFixed(3) + "TB";
      }
    },
  },
})
export default class SearchContent extends Vue {
  @ShareModule.State("current") current!: string;

  public searchType: any[] = [
    { title: "文档", icon: "iconDoc", type: "doc" },
    { title: "图片", icon: "iconImg", type: "image" },
    { title: "视频", icon: "iconMV", type: "video" },
    { title: "文件夹", icon: "iconFolder", type: "folder" },
    { title: "压缩包", icon: "iconZIP", type: "compress" },
    { title: "AI", icon: "iconDoc", type: "AI" },
  ];

  handleType(title: string, type: string): void {
    this.$emit("confirmType", title, type);
  }

  handleSearchResult(item: any): void {
    console.log("判断是否是文件夹item", item);

    let params;
    if (item.userFileType === "folder") {
      params = {
        // id: item.id,
        // fileId: item.id,
        id: item.id,
      };
      if (item.moduleType === "doc") {
        this.$router.push({ path: "/star/mydoc", query: params });
      } else {
        if (params.id === item.teamId) params.id = "";
        this.$router.push({
          path: "/star/companyTeam/document",
          query: { ...params, teamId: item.teamId },
        });
      }
    } else {
      console.log("搜索点击的是单文件--准备跳预览", item);
      let temp = {
        ...item,
        actionFileSize: publicFormatSize(item.userFileSize),
        actionFileid: item.id,
        actionFileFullName: `${item.userFileName}.${item.extensionName}`,
      };
      publicFileView(temp);
      // params = {
      //   fileId: item.id,
      //   id: item.parentId,
      // };
    }

    this.$emit("close");
  }

  gotoSearchAll(): void {
    this.$emit("getAll");
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop()
  readonly type: string | undefined;
  @Prop()
  readonly searchParams!: string;
  @Prop({ default: [], required: true })
  readonly searchList!: any[];
  @Prop({ default: false, required: true })
  readonly allShow!: boolean;
  //计算属性
  // get computedMsg(): string {
  //   return "";
  // }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  // @Watch("")
  //ref 引用元素 Ref('ref')
  // @Ref()
  //创建前钩子函数
  created(): void {}
}
