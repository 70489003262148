import { notification, message } from "ant-design-vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import Loading from "@/components/Loading/index";

// @ts-ignore
import actions from '@/actions'

// 自定义配置类型
declare module "axios" {
  export interface AxiosRequestConfig {
    isThrow?: Boolean; // 是否自己处理catch异常
  }
}

// 部分接口取消加载遮罩
const skipLoading: string[] = [
  '/cdms/user/detail',
  '/fs-cdms/file/preUpload',
  '/document/userFile/pageList',
  '/cdms/document/tag/queryFileTag'
]

// 放行响应码
const responseCode: string[] = [
  '9019',
  '9018',
  '9801',
  '9802',
  '9902'
]

// 部分响应码不提示警告
const notWarning: string[] = [
  '9801',
  '9902'
]

// 公共数据
const commonData = {
  // _locale: 'zh_CN',
  // employId: '1'
};

// 创建一个错误
function errorCreate(msg: string) {
  const error = new Error(msg);
  errorLog(error);
  throw error;
}

// 记录和显示错误
function errorLog(error: Error) {
  // 打印到控制台
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }
  // 显示提示
  notification.error({
    message: "错误提示",
    description: error.message,
    duration: 3,
  });
}

// 警告提示
function warningLog(msg: string) {
  // 打印到控制台
  if (process.env.NODE_ENV === "development") {
    console.warn(msg);
  }
  // 显示提示
  message.warning(msg);
}

function createId() {
  let now = Date.now();
  var d = new Date().getTime();
  var uuid = "xxyyxxy".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 10) % 10 | 0;
      d = Math.floor(d / 10);
      return r.toString();
    }
  );
  return now + uuid;
}

class OrderItem {
  id?: string;
  url?: string;
}

let requestList: Array<OrderItem> = [];

// 创建一个 axios 实例
const service = axios.create({
  baseURL: "/",
  // timeout: 60000, // 请求超时时间
  timeout: 600000, // 请求超时时间10分钟， 邮件相关接口返回时间会比较长
  // 定义公共请求头，可在@/api/[模块].ts文件中具体的请求中可覆盖
  // headers: {
  //   'Content-Type': 'application/json;charset=UTF-8',
  //   'X-Access-Token': value || '6f13ed72-7569-4a07-b92e-f7df646810af'
  // }
  // headers: {
  //   Authorization: token || "",
  // },
});

// 请求拦截器
// debugger

// 调试微应用
// const accessTokenString = localStorage.getItem('pro__Access-Token');
// const accessTokenValue = accessTokenString ? (JSON.parse(accessTokenString) as { value: string }).value : undefined;
// const tenantIdString = localStorage.getItem('pro__TENANT_ID');
// const tenantIdValue = tenantIdString ? (JSON.parse(tenantIdString) as { value: string }).value : undefined;
// console.log('ACCESS_TOKEN--',accessTokenValue);
// console.log('tenantId--',tenantIdValue);



service.interceptors.request.use(
  (config: any) => {
    if (!skipLoading.includes(config.url)) {
      // Loading.open();
    }
    // 在请求发送之前做一些处理
    // 此处定义请求头不可被覆盖
    const token = (store.state as any).user.token;
    const companyId = (store.state as any).user.companyId;
    config.headers = {
      // Authorization: token || "",
      // 'X-Access-Token':accessTokenValue,
      // 'Tenant-Id':tenantIdValue,
      'X-Access-Token': token,
      'Tenant-Id': companyId,
      requestId: createId(),
      channelType: 'web',
      ...config.headers
    }
    return config;
  },
  (error) => {
    // 发送失败
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response: any) => {
    // Loading.close();
    // requestList = cleanRequest({id: response.config.id, url: response.config.url})
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    if (!dataAxios.code) {
      return dataAxios;
    } else if
      // 应用到期 跳转界面
      (dataAxios.code === 240) {
      sessionStorage.expireAppid = dataAxios.result.appId
      // router.push('/expireApp')
      let origin = window.location.origin
      let url = `${origin}/expireApp`
      window.history.pushState({}, "", url);
    }

    // 调试微应用 暂时绕过
    // else if (dataAxios.code === "9001" || dataAxios.code === "9006") {
    //   message.warning("登录超时，请重新登录!");
    //   // 登出
    //   store.commit("user/LOG_OUT");
    //   if (router.currentRoute.path !== '/fileShare' && router.currentRoute.path !== '/cooperationInvited') {
    //     // 去到登录页 并保存当前页面的路径方便登录重新返回
    //     router.push({
    //       name: "login",
    //       query: {
    //         path: router.currentRoute.fullPath,
    //       },
    //     });
    //   }
    // } 
    else if ([510, 555, 566, 9001, '510', '555', '566', '9001'].includes(
      dataAxios.code
    )
    ) {
      // 返回指定code值时, 通知主应用跳登录页
      actions.setGlobalState({
        toLogin: true
      })
    }




    else if (dataAxios.code === "7001" || dataAxios.code === "7002") {
      warningLog(dataAxios.msg)
      // 登出
      store.commit("user/LOG_OUT");
      // 去到第三方平台登录页
      window.location.href = 'http://192.168.80.8/auth/login/page'
    } else if (dataAxios.code === "200" || dataAxios.code === 200 || responseCode.includes(dataAxios.code)) {
      if (dataAxios.code !== "200" && !notWarning.includes(dataAxios.code)) {
        if (dataAxios.code === 200) {
          return dataAxios;
        }
        warningLog(dataAxios.msg)
      }
      return dataAxios;
    }
    
    // 应用所的特殊处理 509代表锁上了
    else if ( dataAxios.code === 509 ) {
      return dataAxios;
    }
    else {


      // // 解压压缩包接口报错的话，需要移除在混入逻辑里添加在页面的spin元素，接口报错混入逻辑里也拿不到，这里直接拦截了
      // // 获取要移除的元素
      // const elementToRemove = document.querySelector('#my-spin-Div');
      // // 检查是否找到了元素
      // if (elementToRemove && elementToRemove.parentNode) {
      //   // 从父元素中移除
      //   elementToRemove.parentNode.removeChild(elementToRemove);
      // }


      errorLog({ ...dataAxios, message: (dataAxios.msg || dataAxios.message) });
      return Promise.reject(dataAxios);
    }

    // // 这个状态码是和后端约定的
    // if ((response.config && response.config.url.indexOf('download') != -1)){ // 批量下载处理
    //   let {headers} = response
    //   return {data:{blob: dataAxios, fileName: headers['content-disposition'].split('=')[1]}}
    // }else if (dataAxios.code === 200 || dataAxios.code === 0) {
    //   // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 VXAdmin 请求最新版本
    //   return dataAxios
    // } else {
    //   const errMsg = `${dataAxios.message || dataAxios.msgInfo}`
    //   if(response.config.isThrow){
    //     // 返回错误自己处理
    //     // 发送失败
    //     throw dataAxios
    //   }
    //   //  发送的接口为response.config.url，进行报错处理
    //   errorCreate(errMsg)
    // }
  },
  (error) => {
    // Loading.close();
    // requestList = cleanRequest({id: error.config.id, url: error.config.url})
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = error.response.data.message || "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
    }
    errorLog(error);
    return Promise.reject(error);
  }
);

function cleanRequest(res: any) {
  const rL = JSON.parse(JSON.stringify(requestList));
  requestList.forEach((item, index) => {
    if (item.id === res.config.id && item.url === res.config.url) {
      rL.splice(index, 1);
    }
  });
  if (rL.length === 0) {
    // Loading.close();
  }
  return rL;
}

export default service;
