
import Vue from "vue";
import SearchContent from "./SearchContent.vue";
import { Debounce } from "@/common/utils/util";
export default Vue.extend({
  name: "HeaderSearch",
  components: { SearchContent },
  props: {},
  data() {
    let input: any = undefined;
    return {
      // 搜索参数
      searchParams: "",
      // 搜索类型名称
      searchTyprTitle: undefined,
      // 搜索类型
      searchType: "",
      // 搜索框内容显示
      searchContentShow: false,
      // 是否关闭搜索框
      isClose: true,
      // 白色背景
      whiteColour: false,
      // 搜索列表
      searchList: [],
      // 显示全部
      allShow: false,
      input,
    };
  },
  computed: {
    current(): string {
      return (this.$route.query.id as string) || "";
    },

    teamList(): any[] {
      return this.$store.state.team.teamList;
    },
  },
  created() {},
  mounted() {
    this.input = (this.$refs as any).input.$el.getElementsByTagName("input")[0];
  },
  methods: {
    // 光标移入
    inputFocus(): void {
      this.searchContentShow = true;
      this.whiteColour = true;
    },
    // 关闭搜索框内容
    closeSearchContent(): void {
      this.isClose = true;
      // 异步执行判断是否关闭搜索框
      setTimeout(() => {
        if (this.isClose) {
          this.searchContentShow = false;
          this.whiteColour = false;
        }
      }, 100);
    },
    // 选择类型确定
    confirmType(title: string, type: string): void {
      (this.searchTyprTitle as undefined | string) = title;
      this.searchType = type;
      this.initSearchList();
      let num: number = title.length;
      this.input.style.paddingLeft = 15 * num + 80 + "px";
    },
    // 关闭选择类型
    closeSearchTypr(): void {
      this.searchTyprTitle = (this as any).$options.data().searchTyprTitle;
      this.searchType = (this as any).$options.data().searchType;
      this.querySearch();
      this.contentHanlde();
      this.input.style.paddingLeft = "30px";
    },
    // 搜索框区域操作
    contentHanlde(): void {
      // 搜索框区域操作重新获取光标定位,并且设置关闭为false
      setTimeout(() => {
        this.isClose = false;
        (this.input as any).focus();
      });
    },
    initSearchList() {
      this.searchList = [];
    },
    // 搜索结果获取
    handleSearch() {
      this.searchContentShow = true;
      this.whiteColour = true;
      Debounce(this.querySearch);
    },
    // 获取搜索结果
    querySearch() {
      console.log(this.searchParams);
      if (!this.searchParams) {
        return false;
      } else {
        let params = {
          keyWord: this.searchParams,
          fileType: this.searchType === "AI" ? "" : this.searchType,
          source: this.searchType === "AI" ? this.searchType : "",
          companyId: this.$store.state.user.companyId,
          isPage: 0,
        };

        this.$apis.searchUserFile(params).then((res: any) => {
          if (res.code === "200") {
            if (res?.data?.list.length > 5) {
              this.searchList = res?.data?.list.slice(0, 5);
              this.allShow = true;
            } else {
              this.allShow = false;
              this.searchList = res?.data?.list;
            }

            this.searchList &&
              this.searchList.length &&
              this.searchList.forEach((item: any) => {
                if (item.teamId && item.teamId.length) {
                  // 使用find方法来查找匹配的teamName
                  const matchedTeam = this.teamList.find(
                    (ele: any) => ele.id === item.teamId
                  );
                  if (matchedTeam) {
                    // 如果找到了匹配的项，则更新item的teamName
                    item.teamName = matchedTeam.teamName;
                    item.userFilePath =
                      `${item.teamName}${item.userFilePath}`.slice(0, -1);
                  }
                } else {
                  item.userFilePath = `个人文档${item.userFilePath}`.slice(
                    0,
                    -1
                  );
                }
              });

            setTimeout(() => {
              console.log("this.searchList", this.searchList);
            }, 500);
          }
        });
      }
    },
    gotoSearchAll(): void {
      if (this.searchList.length <= 0) return;
      let params = {
        fileType: this.searchType === "AI" ? "" : this.searchType,
        source: this.searchType === "AI" ? this.searchType : "",
        fileName: this.searchParams,
      };
      this.closeSearchContent();
      this.$router.push({ path: "/star/searchResult", query: params });
    },
    clear(): void {
      this.searchParams = "";
      console.log(
        "🚀 ~ file: HeaderSearch.vue:173 ~ clear ~ searchParams:",
        this.searchParams
      );
      this.closeSearchTypr();
      // this.searchParams = (this as any).$options.data().searchParams
    },
  },
});
