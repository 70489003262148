import apis from "@/apis/index";
// @ts-ignore
import { publicFormatTime } from "@/utils/publicFormat";
const share = {
  namespaced: true,
  state: {
    uploadSuccessForKnowledgeBasefromLoaclFlag: false, // 是否刚从本地上传文件到知识库成功了
    uploadSuccessForKnowledgeBasefromLoaclFileId: "", // 从本地上传到知识库成功了的文件id
    knowledgeBaseList: [],
    conversationListbyIdForVuex: {},
  },
  mutations: {
    set_uploadSuccessForKnowledgeBase: (state: any, val: any) => {
      state.uploadSuccessForKnowledgeBasefromLoaclFlag = val;
    },
    set__uploadSuccessForKnowledgeBaseFileId: (state: any, val: any) => {
      state.uploadSuccessForKnowledgeBasefromLoaclFileId = val;
    },
    setKnowledgeBaseList: (state: any, val: any) => {
      state.knowledgeBaseList = val;
    },
    setConversationListbyIdForVuex: (state: any, val: any) => {
      state.conversationListbyIdForVuex = val;
    },
  },
  actions: {
    async_setKnowledgeBaseList_ByHttp(store: any, companyId: string) {
      // store.commit("SET_LOADING", true);
      // 这里相当于没有分页，因为文件菜单右键加入知识库的时候需要一次展示完所有知识库，右键二级菜单不存在分页
      apis
        .getKnowledgeUserListHttp({
          isPage: 1,
          pageNum: 1,
          pageSize: 999,
        })
        .then((res: any) => {
          if (res.code == 200) {
            let tempList = res.data.list;
            tempList.forEach((item: any) => {
              if (
                item.knowledgeFileDetail &&
                item.knowledgeFileDetail.lastUpdateTime
              ) {
                item.knowledgeFileDetail.lastUpdateTime = publicFormatTime(
                  item.knowledgeFileDetail.lastUpdateTime
                );
                // item.name=item.knowledgeName
              }
            });

            // teamList.sort((a: any, b: any) =>
            //   a.isStaff === 1 ? -1 : b.isStaff === 1 ? 1 : 0
            // );
            // teamList.sort((a: any, b: any) => a.teamName === 'v回家' ? -1 : b.teamName === 'v回家' ? 1 : 0)
            console.log("初始获取的知识库列表--tempList", tempList);
            store.commit("setKnowledgeBaseList", tempList);
          }
        })
        .catch((err) => {
          // store.commit("SET_TEAMLIST", []);
          console.log("初始获取的知识库列表err", err);
        })
        .finally(() => {
          // store.commit("SET_LOADING", false);
        });
    },
  },
};
export default share;
