
import Vue from "vue";
import { Badge } from "ant-design-vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "Message",
  components: {
    [Badge.name]: Badge,
  },
  filters: {
    //局部过滤器
  },
})
export default class Message extends Vue {
  public loading: boolean = false;
  public current: Array<string> = ["all"];
  public listData: any[] = [];

  menuChange(e: any): void {
    if (e.key == "all") {
      this.queryMessage();
    } else {
      this.queryMessage(e.key);
    }
  }
  gotoMessageAll(): void {
    this.$emit("cancel");
    this.$router.push({ path: "/star/messageAll" });
  }

  queryMessage(type?: string): any {
    this.loading = true;
    let params = {
      isPage: 0,
      messageType: type,
    };
    return this.$apis
      .messagePageList(params)
      .then((res: any) => {
        if (res.code === "200") {
          this.listData = res.data.list;
          return Promise.resolve();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  setRead() {
    this.$apis.messageBatchRead().then((res: any) => {
      if (res.code === "200") {
        this.$emit("read");
      }
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {
    this.queryMessage().then(() => {
      this.setRead();
    });
  }
}
