import { getAction, postAction, postAction2, queryImage } from './method'

export default {


    
    // 邮件往来附件的转存到个人文档
    getSuperFileList(data: any, header: any) {
        return postAction('/star-doc/document/userFile/pageList', data, {}, header)
    },


    // 邮件往来附件的转存到个人文档
    AttachmentFilesCopy(data: any, header: any) {
        return postAction('/studio/mail-client-api/mailInfo/savePersonFile', data, {}, header)
    },


    // 邮件账户列表
    getMailMD5(data: any,header: any) {
        return getAction(`/studio/mail-client-api/mailInfo/getMD5?_t=${Date.now()}`, data,header)
    },


    // 邮件往来附件的下载
    // 修改 downloadAttachmentFiles 函数，将参数放在 URL 中
    downloadAttachmentFiles(data: any, header: any) {
        return postAction2('/studio/mail-client-api/mailInfo/attachmentDownload', data,{}, header);
    },



    // 邮件往来附件
    userAttachmentFiles(data: any, header: any) {
        return postAction('/studio/mail-client-api/mailInfo/listAttachment', data, {}, header)
    },

    // 邮件账户列表
    getMailAddress() {
        return getAction(`/studio/mail-client-api/mailAccount/listAllCompanyAccount?_t=${Date.now()}`,)
    },



}