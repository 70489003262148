import { getAction, postAction, queryImage } from './method'
import store from "@/store";

export default {
    potentialCustomerAdd(data: any, params: any) { return postAction('/star-doc/common/deploy/feedback', data, params) },
    // 校验行为验证码是否通过
    captchaVerify(data: any) {
        return postAction('/star-doc/common/captcha/verify', data)
    },
    // 登录
    login(data: {
        loginId: string
        password: string
    }) {
        return postAction('/star-doc/common/account/login', data)
    },
    // 获取单点登录code
    getCode() {
        return postAction('/star-doc/user/thirdParty/getCode')
    },
    // 单点登录(第三方平台获取用户信息)
    thirdPartylogin(data: { code: string }) {
        return postAction('/star-doc/user/thirdParty/login', {}, data)
    },
    // 获取小程序码
    wechatGetAppCode(data: any) {
        return postAction('/star-doc/wechat/getAppCode', data)
    },
    // 扫码登录
    qrcodeLogin(data: any) {
        return postAction('/star-doc/common/qrcode/login', {}, data)
    },
    // 验证码登录
    msgLogin(data: {
        code: string | number
        loginId: string | number
    }) {
        return postAction('/star-doc/common/msg/login', data)
    },
    // 退出登录
    logOut() {
        return postAction('/star-doc/common/logOut')
    },
    // 验证码
    kaptcha(clientId: string) {
        return queryImage('post', '/star-doc/common/kaptcha', {}, { clientId })
    },
    // 获取邮箱验证码
    getEmailCode(data: {
        loginId: string,
        modelNumber: string
    }) {
        return postAction('/star-doc/common/getVailCode', {}, data)
    },
    // 注册
    register(data: {
        code: string
        loginId: string
        password: number | string
        verifyPassword?: number | string
    }) {
        return postAction('/star-doc/common/register', data)
    },
    // 重置密码
    resetPassword(data: {
        code: string
        loginId: string
        password: number | string
    }) {
        return postAction('/star-doc/common/reset/password', {}, data)
    },
    // 文件搜索
    searchUserFile(data: {
        keyWord: string
        fileType: string
        isPage: number
        searchUserFile: string
        // moduleType: string
    }) {
        return postAction('/star-doc/document/search/userFile', {
            pageNum: 1,
            pageSize: 10,
            ...data
        })
    },
    // 上传下载记录查询
    taskHistory(data: {
        isPage: string | number,
        pageNum: string | number,
        pageSize: string | number,
        taskType: string
    }) {
        return postAction('/star-doc/document/taskHistory/queryList', data)
    },
    // 上传下载记录批量删除
    taskHistoryDelete(data: string) {
        // return postAction('/star-doc/document/taskHistory/deleteByIds', {}, { ids })
        return postAction('/star-doc/document/taskHistory/deleteByIds',  data )
    },
    // 上传下载记录全部删除
    taskHistoryDeleteAll(data: string) {
        return postAction('/star-doc/document/taskHistory/deleteAll', data)
    },
    // 新增常用文件
    commonFileAdd(data: {
        userFileId: string | number
    }) {
        return postAction('/star-doc/document/commonFile/add', data)
    },
    // 删除常用文件
    commonFileDlete(data: {
        id: string | number
    }) {
        return postAction('/star-doc/document/commonFile/delete', {}, data)
    },
    // 常用文件列表查询
    commonFilePageList() {
        return postAction('/star-doc/document/commonFile/pageList', {
            isPage: 1,
            pageNum: 1,
            pageSize: 5
        })
    },

    /* office功能管理类 */
    // 关闭页面
    // officeClose(data: any) {
    //     return getAction('/star-doc/office/close', data)
    // },
    // 显示监控信息
    officeShowMonitorInfo(data: any, header?: any) {
        return postAction('/star-doc/office/showMonitorInfo', data, {}, header)
    },

    /* 公告 */
    // 公告列表
    bulletinPageList(data: any) {
        return postAction('/star-doc/system/bulletin/pageList', data)
    },
    // 根据id查询公告信息
    bulletinQueryById(data: any) {
        return postAction('/star-doc/system/bulletin/queryById', data)
    },


    /* 其他公共接口 */
    // 根据字典类型集合查对应的字典
    dictListDictByTypeId(data: any) {
        return postAction('/star-doc/system/dict/listDictByTypeId', {}, data)
    },
    // 获取websocket地址接口
    websocketGetUrl() {
        return getAction('/star-doc/websocket/getUrl')
    },


    /* 消息通知 */
    // 批量设置已读
    messageBatchRead(data: any) {
        return postAction('/star-doc/user/message/batchRead', data, {})
    },
    // 批量修改
    messageBatchUpdate(data: any) {
        return postAction('/star-doc/user/message/batchUpdate', data, {})
    },
    // 批量删除消息
    messageDeleteByIds(data: any) {
        return postAction('/star-doc/user/message/deleteByIds', data, {})
    },
    // 查询通知分页
    messagePageList(data: any) {
        return postAction('/star-doc/user/message/pageList', data, {})
    },
    // 查询通知详情
    messageQueryById(data: any) {
        return postAction('/star-doc/user/message/queryById', data, {})
    },
    // 查询未读消息
    messageQueryUnRead(data: any) {
        return postAction('/star-doc/user/message/queryUnRead', data, {})
    },

    /* 积分任务 */
    // 积分任务列表
    taskList(data: any) {
        return postAction('/star-doc/user/task/list', data, {})
    },
    // 查询用户积分
    taskQuery(data: any) {
        return postAction('/star-doc/user/task/query', {}, data)
    },
    // 任务类型列表
    taskTypeList(data: any = { isPage: 0 }) {
        return postAction('/star-doc/user/task/type/list', data, {})
    },
}