import { Common } from "@/interface/index";

const share = {
  namespaced: true,
  state: {
    reload: 0,
    navigator: '',
    triggerFeedbackQuery: 0
  },
  mutations: {
    TO_RELOAD: (state: Common) => {
      state.reload++;
    },
    SET_TRIGGERFEEDBACKQUERY: (state: Common) => {
      state.triggerFeedbackQuery++
    },
    SET_NAVIGATOR(state: Common, payload: any) {
      state.navigator = payload
    },
  },
  actions: {},
};
export default share;
