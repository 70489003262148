import { getAction, postAction, queryImage } from './method'
import store from "@/store";

export default {
    /* 用户模块 */
    // 通过邀请链接添加成员
    companyTeamAddByInviteLink(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/add/byInviteLink', data, {}, header)
    },
    // 添加成员
    // companyTeamAddMember(data: any, header?: any) {
    //     return postAction('/star-doc/user/companyTeam/add/member', data, {}, header)
    // },

    // 添加团队成员
    addTteaMembermHttp(data: any, header?: any) {
        return postAction('/star-doc/company/team/member/add', data, {}, header)
    },
    // 删除团队成员
    deleteTeamMembeHttp(data: any, header?: any) {
        return postAction('/star-doc/company/team/member/remove', data, {}, header)
    },

    // 新建团队
    companyTeamAddTeam(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/add/team', data, {}, header)
    },

    // 查询联系人
    companyTeamContact(data: any) {
        // return postAction('/star-doc/user/companyTeam/contact', data, {}, header)
        // 主应用没有联系人了，要从主站通讯录去拿
        // return getAction('/studio/auth/sys/user/queryByOrgCodeForAddressList', data)
        return getAction('/studio//auth/sys/sysDepart/queryTreeListWithUser', data)
    },
    // 搜索联系人
    searchContact(data: any) {
        // return postAction('/star-doc/user/companyTeam/contact', data, {}, header)
        // 主应用没有联系人了，要从主站通讯录去拿
        // return getAction('/studio/auth/sys/user/queryByOrgCodeForAddressList', data)
        return getAction(`/studio//auth/sys/user/member/search?keyword=${data}`)
    },


    // 查看团队信息
    companyTeamDetail(data: any, header?: any) {
        // return postAction('/star-doc/user/companyTeam/detail', {}, data, header)
        return postAction('/star-doc/user/companyTeam/detail', data,  {},header)
    },
    // 解散团队
    companyTeamDisband(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/disband', data, {}, header)
    },
    // // 新增团队动态
    // companyTeamDynamicAdd(data: any, header?: any) {
    //     return postAction('/star-doc/user/companyTeam/dynamic/add', data, {}, header)
    // },
    // 查询团队动态
    companyTeamDynamicPageList(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/dynamic/pageList', data, {}, header)
    },
    // // 编辑团队信息
    // companyTeamEdit(data: any, header?: any) {
    //     return postAction('/star-doc/user/companyTeam/edit', data, {}, header)
    // },

    // 编辑团队信息(团队重命名)
    companyTeamRename(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/rename', data, {}, header)
    },

    // 修改成员在团队中的权限
    teamMembeRroleChangeHttp(data: any, header?: any) {
        return postAction('/star-doc/company/team/member/role/change', data, {}, header)
    },




    // 退出团队
    companyTeamExit(data: any, header?: any) {
        // return postAction('/star-doc/user/companyTeam/exit', {}, data, header)
        return postAction('/star-doc/user/companyTeam/exit',  data,{}, header)
    },
    // 生成邀请码
    companyTeamGenerateInviteCode(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/generateInviteCode', data, {}, header)
    },
    // 查看我的团队
    companyTeamPageList(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/pageList', data, {}, header)
    },

    // 查看我的团队
    companyTeamDefaultPageList(data: any, header?: any) {
        return postAction('/star-doc//user/companyTeam/add/default/team', data, {}, header)
    },

    // 查询邀请信息
    companyTeamQueryInviteInfo(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/query/inviteInfo', data, {}, header)
    },
    // 查看团队成员，不包含自己
    companyTeamQueryMember(data: any, header?: any) {
        // return postAction('/star-doc/user/companyTeam/query/member', {}, data, header)
        return postAction('/star-doc/user/companyTeam/query/member',data, {}, header)
    },
    // 查询邀请码
    companyTeamQueryInviteCode(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/queryInviteCode', data, {}, header)
    },
    // 查询最近联系人
    companyTeamRecent(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/recent', data, {}, header)
    },
    // 转让团队
    companyTeamTransfer(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/transfer', data, {}, header)
    },

    /* 企业管理 */
    // 查询企业信息
    companyList(data?: any, header?: any) {
        return postAction('/star-doc/user/company/list', {}, data, header)
    },
    // 更新用户默认企业
    companyUpdateDefault(data: any, header?: any) {
        return postAction('/star-doc/user/company/updateDefault', {}, data, header)
    },

    /* 文件操作 */
    // 创建文件夹
    companyFileAddFolder(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/addFolder', { ...data, companyId: (store.state as any).user.companyId }, {}, header)
    },
    // 批量检查文件操作权限
    companyFileCheck(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/check', data, {}, header)
    },
    // 文件复制
    companyFileCopy(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/copy', data, {}, header)
    },
    // 文件删除
    companyFileDelete(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/delete', data, {}, header)
    },
    // 根据用户文件id查询文件
    companyFileDetail(id: string, header?: any) {
        return getAction('/star-doc/user/companyTeam/file/detail', { id })
    },
    // 文件批量移动
    companyFileMove(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/move', data, {}, header)
    },
    // 企业下团队文件列表
    companyFilePageList(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/pageList', data, {}, header)
    },
    // 查询文件目录,list从上到下为目录顺序
    companyFileQueryPathDic(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/queryPathDic', {}, data, header)
    },
    // 文件重命名
    companyFileRename(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/rename', data, {}, header)
    },
    // 修改备注
    companyFileUpdateRemark(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/file/updateRemark', data, {}, header)
    },

    /* 团队文件收藏 */
    // 新增团队文件收藏
    companyTeamFileCollectionAdd(data: any, header?: any) {
        return postAction('star-doc/document/fileCollection/add', data, {}, header)
    },
    // 取消收藏的团队文件
    companyTeamFileCollectionCancel(data: any, header?: any) {
        return postAction('/star-doc/document/fileCollection/cancel', data, {}, header)
    },
    // 查询团队的收藏文件
    companyTeamFileCollectionPageList(data: any, header?: any) {
        return postAction('/star-doc/user/companyTeam/fileCollection/pageList', data, {}, header)
    },
}