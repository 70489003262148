<template>
  <div class="report-officeOnline">
    <div id="placeholder"></div>
  </div>
</template>

<script>
import { finished } from "stream";
import Vue from "vue";

export default Vue.extend({
  name: "Preload",
  components: {},
  data() {
    return {
      docEditor: null, //  文档编辑器
      platform: "desktop", //  查看平台
      finished: false, //是否加载完成
      timer: null, // 加载计时统计
    };
  },
  computed: {},
  created() {
    this.judgePlatform();
  },
  mounted() {
    this.$nextTick(() => {
      // 预览编辑在线文档都去除
      // this.showDocDetail();
      this.setTimer();
    });
  },
  destroyed() {
    this.toDestroy();
  },
  methods: {
    // 设置定时器,2分钟内未加载完将重新加载,直到加载完成
    setTimer() {
      this.timer = setTimeout(() => {
        if (!this.finished) {
          console.log("超时重新加载");
          this.toDestroy();
          // this.showDocDetail();
          this.setTimer();
        }
      }, 60 * 1000);
    },
    initDocEditor(docserviceApiUrl, config) {
      console.log(config);
      const that = this;
      this.loadOfficeOnlineAPI(docserviceApiUrl).then(() => {
        /* global DocsAPI */
        this.docEditor = new DocsAPI.DocEditor("placeholder", {
          ...config,
          editorConfig: {
            ...config.editorConfig,
            lang: "zh", //  语言设置为中文
            mode: "view",
            customization: {
              // ...config.editorConfig.customization,
              zoom: 100, //  缩放比例为 100
              hideRightMenu: true,
            },
          },
          events: {
            // 文档加载完成
            onDocumentReady(e) {
              console.log("预加载完成");
              clearTimeout(that.timer);
              that.finished = true;
              that.toDestroy();
            },
            // 发生错误
            onError(e) {
              console.error("发生错误", e);
            },
            // 出现警告
            onWarning(e) {
              console.warn("出现警告", e);
            },
          },
        });
      });
    },
    // 平台判断
    judgePlatform() {
      this.platform = "desktop"; // 浏览平台
      if (
        /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
        /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
          navigator.userAgent
        )
      ) {
        if (window.location.href.indexOf("?mobile") < 0) {
          try {
            if (
              /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
            ) {
              this.platform = "mobile";
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    // 预览/编辑文档
    // showDocDetail() {
    //   this.$apis.officeView({ moduleType: "preview" }).then((res) => {
    //     let config = {
    //       ...res,
    //       type: this.platform,
    //       editorConfig: res.documentEditParam,
    //     };
    //     this.initDocEditor(res.officeApiUrl, config);
    //   });
    // },
    // body插入外部js
    loadOfficeOnlineAPI(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        document.body.appendChild(script);
        script.onload = () => {
          resolve(0);
        };
        script.onerror = () => {
          reject();
        };
      });
    },
    toDestroy() {
      this.docEditor && this.docEditor.destroyEditor();
    },
  },
});
</script>

<style lang="less">
.report-officeOnline {
  position: relative;
  height: 100vh;
  width: 100vw;
  [name="frameEditor"] {
    display: block;
  }
}

#app .mainContent {
  height: 100%;
  width: 100%;
}
</style>
