<template>
  <div class="docToolMinimizedFloatingWindow">
    <a-card
      ref="docToolMinimizedFloatingWindowCardRef"
      class="docToolMinimizedFloatingWindow-card"
    >
      <div class="float-content">
        <div class="status">
          <svg
            style="width: 20px; height: 20px"
            class="icon"
            aria-hidden="true"
            v-if="status === 'success'"
          >
            <use xlink:href="#icon-zhengque"></use>
          </svg>
          <svg
            style="width: 20px; height: 20px"
            class="icon"
            aria-hidden="true"
            v-else-if="status === 'info'"
          >
            <use xlink:href="#icon-jiazai"></use>
          </svg>

          <svg
            style="width: 20px; height: 20px"
            class="icon"
            aria-hidden="true"
            v-else-if="status === 'error'"
          >
            <use xlink:href="#icon-jinggao"></use>
          </svg>

          <img
            v-else-if="status === 'msg'"
            style="width: 20px; height: 20px"
            src="@/assets/svg/docToolLogo.svg"
            alt=""
          />
          <div class="float-toolTip">
            <h3>{{ toolTip }}</h3>
          </div>
        </div>
        <!-- 文档-- -->
        <div>
          <!-- 展开文档工具操作窗口 -->
          <svg
            class="icon click"
            aria-hidden="true"
            style="width: 24px; height: 24px"
            @click="showDocToolAndCloseSelf"
          >
            <use xlink:href="#icon-zhankai"></use>
          </svg>

          <!-- 关闭 -->
          <svg
            class="icon click"
            aria-hidden="true"
            style="margin-left: 20px; width: 24px; height: 24px"
            @click="closeDocToolAndSelf"
          >
            <use xlink:href="#icon-guanbi"></use>
          </svg>
        </div>
      </div>
    </a-card>

    <!-- 确认切换和关闭弹窗 -->
    <confirmationDialog
      v-if="showConfirmationDialog"
      :showConfirmationDialog="showConfirmationDialog"
      :ModalText="ModalText"
      @confirmationDialog_Cancel="confirmationDialog_Cancel"
      @confirmationDialog_Ok="confirmationDialog_Ok"
    />
  </div>
</template>

<script>
import confirmationDialog from "@/components/confirmationDialog/index";
export default {
  name: "docToolMinimizedFloatingWindow",
  components: {
    confirmationDialog,
  },
  data() {
    return {
      showConfirmationDialog: false,
      ModalText: "",
      notificationQueue: [],
      resizeObserver: null,
      status: "msg",
      toolTip: "文档工具",
      allTransferStatus: "transferIng", // transferIng/allOver
    };
  },
  watch: {
    // 监听消息队列，执行通知
    // notificationQueue: {
    //   handler(newVal, oldVal) {
    //     if (newVal.length) {
    //       // 执行轮询的通知
    //       newVal.forEach((item,index) => {
    //         setTimeout(() => {
    //           this.status = item.notificationStatus;
    //           this.toolTip = item.notificationText;
    //         }, 2000);
    //         if (item.notificationOnce) {
    //           newVal.splice(index, 1); // 删除已通知的元素
    //         }
    //       });
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },

    // 监听文档转换列表的状态
    conversionFiles: {
      handler(newVal, oldVal) {
        if (newVal.length) {
          console.log(
            "判断文件转换列表的数据--全部结束了了吗",
            newVal.every(
              (item) =>
                item.status &&
                item.status !== "CONVERTING" &&
                item.status !== "await"
            )
          );
          // 每次轮询拿到新数据时，重写新的通知队列
          // this.notificationQueue = [];
          if (this.allTransferStatus !== "allOver") {
            newVal.forEach((val) => {
              let actionFileFullName = `${val.userFileName}.${val.extensionName}`;
              // 检查文件是否已通知过成功或失败
              if (val.status === "SUCCESS") {
                // 确保单个成功或失败的文件只通知一次
                if (!val.notificationOnce) {
                  // this.notificationQueue.push({
                  //   notificationText: `【${actionFileFullName}】文件转换完成`,
                  //   notificationStatus: "success",
                  //   notificationOnce: true,
                  // });
                  if (this.allTransferStatus !== "allOver") {
                    this.status = "success";
                    this.toolTip = `【${actionFileFullName}】文件转换完成`;
                  }
                  // 给文件追加属性
                  val.notificationOnce = true;
                }
              } else if (val.status === "FAIL") {
                // 确保单个成功或失败的文件只通知一次
                if (!val.notificationOnce) {
                  // this.notificationQueue.push({
                  //   notificationText: `【${actionFileFullName}】文件转换失败`,
                  //   notificationStatus: "error",
                  //   notificationOnce: true,
                  // });
                  if (this.allTransferStatus !== "allOver") {
                    this.status = "error";
                    this.toolTip = `【${actionFileFullName}】文件转换失败`;
                  }
                  // 给文件追加属性
                  val.notificationOnce = true;
                }
              } else if (val.status === "CONVERTING") {
                // 如果文件既未通知过成功也未通知过失败，则添加通知到队列
                let textInfo = "";
                let filterList = newVal.filter(
                  (file) => file.status == "CONVERTING"
                );
                if (filterList.length == 1) {
                  textInfo = `【${actionFileFullName}】文件转换中`;
                } else {
                  textInfo = `【${`${filterList[0].userFileName}.${filterList[0].extensionName}`}】等${
                    filterList.length
                  }个文件转换中`;
                }
                // 给通知队列新增转换中的消息通知
                // this.notificationQueue.push({
                //   notificationText: textInfo,
                //   notificationStatus: "info",
                // });
                if (this.status == "success" || this.status == "error") {
                  setTimeout(() => {
                    if (this.allTransferStatus !== "allOver") {
                      this.status = "info";
                      this.toolTip = textInfo;
                    }
                  }, 2000);
                } else {
                  if (this.allTransferStatus !== "allOver") {
                    this.status = "info";
                    this.toolTip = textInfo;
                  }
                }
              }
            });
          }

          // if (newVal.every((item) => item.status === "SUCCESS")) {
          if (
            newVal.every(
              (item) =>
                item.status &&
                item.status !== "CONVERTING" &&
                item.status !== "await"
            )
          ) {
            // 如果所有状态都是 SUCCESS / (开始没说有失败的情况，考虑失败的情况，只要没有还在转换中的文件切状态不是空值或带转哈，就是全部完成了) ，则整体状态为转换完成
            this.allTransferStatus = "allOver";
            // 所有文件转换完成的通知
            // this.status = "success";
            // this.toolTip = "所有文件均已转换完成";
            let successCount = 0;
            let failCount = 0;
            newVal &&
              newVal.forEach((item) => {
                if (item.status) {
                  if (item.status === "SUCCESS") {
                    successCount++;
                  } else if (item.status === "FAIL") {
                    failCount++;
                  }
                }
              });

            if (successCount === newVal.length && failCount === 0) {
              // 所有文件转换完成的通知
              this.status = "success";
              this.toolTip = "所有文件均已转换完成";
            } else if (newVal.length == 1 && failCount == 1) {
              this.status = "error"; // 假设部分成功
              let actionFileFullName = `${newVal[0].userFileName}.${newVal[0].extensionName}`;
              this.toolTip = `【${actionFileFullName}】 转换失败`;
            } else {
              // 如果有文件还在转换中或存在失败的文件，则显示成功和失败的数量
              this.status = "success"; // 假设部分成功
              this.toolTip = `转换成功${successCount}个，转换失败${failCount}个`;
            }

            setTimeout(() => {
              console.log("所有文件都转换完成了,那么2秒钟后停止轮训吧");
              // 如果需要取消轮询，可以在适当的时候调用
              this.$store.commit("docTool/stopPollingStatus");
            }, 2000);
          } else if (newVal.some((item) => item.status === "CONVERTING")) {
            // 如果有至少一个状态是 CONVERTING，则整体状态为转换中
            this.allTransferStatus = "transferIng";
          } else {
            // 否则，整体状态为待转换
            this.allTransferStatus = "allAwait"; // 或者 undefined/await，根据你的逻辑来决定
            this.status = "msg";
            this.toolTip = `文档工具`;
          }
        } else {
          // 转换列表中没有数据
          this.status = "msg";
          this.toolTip = `文档工具`;
        }
      },
      immediate: true,
      deep: true,
    },
    // 监听文档转换列表的状态--通过轮训获取的
    conversionStatusInfo: {
      handler(newVal, oldVal) {
        if (newVal.length) {
          console.log("文件转换列表的状态----", newVal);
          // 获取当前的conversionFiles状态
          // const currentConversionFiles = this.$store.state.docTool.conversionFiles;

          // 计算合并了新属性后的新数组
          const updatedConversionFiles = this.conversionFiles.map((file) => {
            const statusObj = newVal.find(
              (obj) => obj.originalUserFileId == file.id
            );
            if (statusObj) {
              // 合并属性，这里使用Object.assign或者展开运算符来合并对象
              return { ...file, ...statusObj };
            }
            // 如果没有找到匹配的项，返回原对象
            return file;
          });

          this.$store.commit(
            "docTool/changeConversionFiles",
            updatedConversionFiles
          );
          // setTimeout(() => {
          //   // TODO 调试代码，给个一分钟超时时间，最长一分钟没转换完成就暂时停止轮训吧
          //   this.$store.commit("docTool/stopPollingStatus");
          // }, 1000 * 60 * 5);
        } else {
          console.log("转换列表中没有文件", newVal);
          // setTimeout(() => {
          //   console.log("没有文件，那就停止轮训吧");
          //   // 如果需要取消轮询，可以在适当的时候调用
          //   this.$store.commit("docTool/stopPollingStatus");
          // }, 2000);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // 最小化悬浮窗的消息通知队列
    notificationList() {
      return this.$store.state.docTool.notificationList;
    },

    // 文档转换列表的状态
    conversionStatusInfo() {
      return this.$store.state.docTool.conversionStatusInfo;
    },
    // 已经添加到转换列表中的文件数组
    conversionFiles() {
      return this.$store.state.docTool.conversionFiles;
    },
  },
  mounted() {
    this.startResizeListening();

    // this.status = "warning";
    // this.toolTip = "xxx文件转换中";

    // setTimeout(() => {
    //   this.status = "success";
    //   this.toolTip = "xxx文件转换完成";
    // }, 2000);
  },
  beforeDestroy() {
    this.stopResizeListening();
  },
  methods: {
    // 关闭确认提示弹窗
    confirmationDialog_Cancel() {
      this.showConfirmationDialog = false;
    },

    // 确认切换操作菜单
    confirmationDialog_Ok() {
      this.$store.dispatch("docTool/async_clearFileList");
      this.showConfirmationDialog = false;
      // 初始化文档工具所有可维护数据及状态重置
      setTimeout(() => {
        this.$store.commit("docTool/clearAllDocToolData");
      }, 100);
    },

    // 点击关闭最小化悬浮窗(根据情况是否二次弹窗确认)
    closeDocToolAndSelf() {
      // 初始化文档工具所有可维护数据及状态重置
      // setTimeout(() => {
      //   this.$store.commit("docTool/clearAllDocToolData");
      // }, 500);
      // // 通知后端清空转换列表+清除转换列表中的文件
      // this.$store.dispatch("docTool/async_clearFileList");

      if (
        this.conversionFiles.some(
          (item) =>
            item.status === "await" ||
            item.status === "CONVERTING" ||
            !item.status
        )
      ) {
        this.showConfirmationDialog = true;
        this.ConfirmationDialogType = "closeDocTool";
        this.ModalText =
          "关闭后，正在转换中的文件将会在后台进行转换，未转换的文件将直接移除，请知悉!";
      } else {
        this.$store.dispatch("docTool/async_clearFileList");
        // 初始化文档工具所有可维护数据及状态重置
        setTimeout(() => {
          this.$store.commit("docTool/clearAllDocToolData");
        }, 100);
      }
    },
    // 展开文档工具，关闭最小化悬浮窗
    showDocToolAndCloseSelf() {
      this.$store.commit(
        "docTool/setShowDocToolMinimizedFloatingWindow",
        false
      );
      this.$store.dispatch("docTool/async_changeDocTransformDialogFlag", true); // 展开文档转换列表的弹窗
    },
    startResizeListening() {
      const uploaderCard = document.querySelector(".uploader-card");
      if (uploaderCard) {
        this.resizeObserver = new ResizeObserver(this.adjustToolPosition);
        this.resizeObserver.observe(uploaderCard);
      }
    },
    stopResizeListening() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    adjustToolPosition() {
      const uploaderCard = document.querySelector(".uploader-card");
      if (uploaderCard) {
        // const height = uploaderCard.offsetHeight;
        let num = uploaderCard.getBoundingClientRect().height;
        // 调整你的tool组件位置
        console.log("上传组件高度有变化", num);
        let tool = this.$refs.docToolMinimizedFloatingWindowCardRef.$el;
        if (tool) {
          tool.style.bottom = num + 25 + "px";
        }
      }
    },
  },
};
</script>

<style lang="less">
.docToolMinimizedFloatingWindow-card {
  position: fixed !important;
  // 设定固定高度
  height: 60px;
  width: 520px;
  bottom: 20px;
  min-height: 60px;
  right: 20px;
  z-index: 10;
  // display: flex;
  // align-items: center;

  .float-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .status {
      height: 60px;
      display: flex;
      align-items: center;
      .float-toolTip {
        height: 60px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        h3 {
          max-width: 340px;
          font-size: 15px;
          color: #262626 !important;
          margin-bottom: 0 !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .ant-card-body {
    // padding: 0;
    height: 100% !important;
  }
}
</style>
