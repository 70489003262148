import { Icon } from 'ant-design-vue'
import { Component, Vue } from 'vue-property-decorator'
import './csii-icon.less'

const csiiFont = Icon.createFromIconfontCN({
  // csii font
  scriptUrl: 'https://at.alicdn.com/t/font_2562209_vo7rs9gbeem.js', // 在 iconfont.cn 上生成
});
const colors = {
  '1': '#027aff',
  '2': '#ff9102',
  '3': '#00b853',
  '0': '#fb602c'
}
@Component({
  components: {
    'c-icon': csiiFont
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    custTxt: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  }
})
export default class CsiiIcon extends Vue {
  render() {
    if(this.custTxt){
      return <i class="txt-icon" style={`background: ${colors[(this.index+1)%4+'']}`}>{this.custTxt}</i>
    } else
      return <c-icon type={this.type}></c-icon>
  }
}
