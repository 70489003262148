import { getAction, postAction } from "./method";

export default {
  renameFile(params: any) {
    return postAction("/star-doc/document/userFile/rename", params, "post");
  },

  // 知识库文档搜索--支持全文检索
  KnowledgeGetFileNameHttp(params: any) {
    return getAction("/star-doc/knowledge/user/file/getFileName", params);
  },

  // 知识库文档搜索--支持全文检索
  KnowledgeFileSearchHttp(params: any) {
    return getAction("/star-doc/knowledge/user/search", params);
  },

  // 知识库问答--数据来源-衍生问题等
  answerCorrelationHttp(params: any) {
    return getAction("/meta-human-api/chatMessage/answerCorrelation", params);
  },

  // 知识库问答--发送消息
  chatMessageSendHttp(data: any, header: any, callBack: any) {
    return postAction(
      "/meta-human-api/chatMessage/send",
      data,
      {},
      header,
      callBack
    );
  },

  // 添加文件到知识库
  filterFileTypeHttp(params: any) {
    return postAction("/star-doc/user/file/document/query", params);
  },

  // 删除用户知识库文件
  delKnowledgeFileHttp(params: any) {
    return getAction("/star-doc/knowledge/user/deleteUserFile", params);
  },

  // 移除知识库文件
  removeKnowledgeFileHttp(params: any) {
    return getAction("/star-doc/knowledge/user/file/remove", params);
  },

  // 获取知识库文件列表
  getKnowledgeFileListHttp(params: any) {
    return getAction("/star-doc/knowledge/user/file/list", params);
  },

  // 重命名知识库
  renameKnowledgeHttp(params: any) {
    return getAction("/star-doc/knowledge/user/update", params);
  },

  // 删除知识库
  delKnowledgeListHttp(params: any) {
    return getAction("/star-doc/knowledge/user/delete", params);
  },

  // 知识库列表
  getKnowledgeUserListHttp(params: any) {
    return getAction("/star-doc/knowledge/user/list", params);
  },

  // 新增知识库
  addKnowledgeHttp(params: any) {
    return getAction("/star-doc/knowledge/user/add", params);
  },

  // 添加文件到知识库
  addFileToKnowledgeHttp(params: any) {
    return postAction("/star-doc/knowledge/user/file/processBatch", params);
  },
};
