type state={
    HistoryIds:Array<string>,
    historyindex:number
}
const cloud={
    state:{
        HistoryIds:[],
        historyindex:0 // 当前处于第几级
    } as state,
    mutations:{
        SET_addHistoryIds(state:state,data:string){
            if(state.HistoryIds.findIndex(i=>i===data)===-1){
                state.HistoryIds.push(data)
                state.historyindex = state.HistoryIds.length-1
            }else{
                state.historyindex = state.HistoryIds.findIndex(i=>i===data)
            }
        },
        SET_delHistoryIds(state:state){
            state.HistoryIds.pop()
        },
        SET_clearHistoryIds(state:state){
            state.HistoryIds = []
        },
        SET_appointHistoryIds(state:state,data:string){
            const end = state.HistoryIds.findIndex(i=>i===data)+1
            state.HistoryIds = state.HistoryIds.slice(0,end)
        }
    },
    actions: {},
    getters:{
        historyindex:(state:state)=> state.historyindex
    }
}
export default cloud