import dayjs from 'dayjs'

/**
 *  转换文件的大小
 */
function formatDateSize (values:any) {
  if (values) {
    values = Number(values)
    let size = ''
    if (values < 0.1 * 1024) { // 如果小于0.1MB转化成KB
      size = values.toFixed(2) + 'KB'
    } else if (values < 0.1 * 1024 * 1024) { // 如果小于0.1GB转化成MB
      size = (values / 1024).toFixed(2) + 'MB'
    } else { // 其他转化成GB
      size = (values / (1024 * 1024)).toFixed(2) + 'GB'
    }
    const sizestr = size + ''
    const len = sizestr.indexOf('\.')
    const dec = sizestr.substr(len + 1, 2)
    if (dec === '00') { // 当小数点后为00时 去掉小数部分
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
    }
    return sizestr
  }
}
/**
 * @文字多余省略号
 * @param {string} str 字符串
 * @param {number} num 长度
 * */
function formateString(str: string, num: number = 10){
  if (str && str.length > num){
    str = str.substr(0,num) + '...'
  }
  return str
}

// 时间格式化
function formatTime(time: string | number) {
  return dayjs(time).format("YYYY-MM-DD HH:mm");
}

const filters:any = {
  formatDateSize,
  formateString,
  formatTime
}

export default filters
