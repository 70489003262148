import axios from "@/common/utils/request";
import Vue from 'vue'



export function postActionUpload(url: string, formData: FormData, onUploadProgress?:any) {  
    return axios({  
        url: url,  
        method: 'post',  
        headers: {  
            'Content-Type': 'multipart/form-data' // 通常不需要显式设置，因为 FormData 会自动设置它  
        },  
        data: formData,  
        onUploadProgress: onUploadProgress, // 监听上传进度  
    });  
}

//post
export function postAction(url: string, parameter?: any, params?: any, header?: any,onDownloadProgress?:any) {
    return axios({
        url: url,
        method: 'post',
        params: params,
        data: parameter,
        headers: header,
        onDownloadProgress:onDownloadProgress,
    })
}



//get
export function getAction(url: string, parameter?: any,header?: any) {
    return axios({
        url: url,
        method: 'get',
        params: parameter,
        headers: header,
    })
}




export function postActionStream(url: string, parameter?: any, params?: any, header?: any) {
    return axios({
        url: url,
        method: 'post',
        params: params,
        data: parameter,
        headers: header,
        responseType: 'stream'
    })
}



// 邮件往来附件的下载用
export function postAction2(url: string, parameter?: any, params?: any, header?: any) {
    return axios({
        url: url,
        method: 'post',
        params: params,
        data: parameter,
        headers: header,
        responseType: 'blob'
    })
}



//图片获取
export function queryImage(method: any, url: string, parameter?: any, params?: any) {
    return axios({
        url: url,
        method: method,
        params: params,
        data: parameter,
        responseType: 'blob'
    })
}

/**
 * 下载文件
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url: string, data: any, parameter: any, method: any = "POST") {
    return axios({
        url: url,
        data: data,
        params: parameter,
        method: method,
        responseType: 'blob'
    })
}


/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url: string, fileName: string, data: any, parameter: any, method: any = "POST") {
    return downFile(url, data, parameter, method).then((data: any) => {
        blobToText(data).then((res: any) => {
            let name = fileName ? fileName : res.fileName
            if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
                (window.navigator as any).msSaveBlob(new Blob([res]), name)
            } else {
                let url = window.URL.createObjectURL(new Blob([res]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) //下载完成移除元素
                window.URL.revokeObjectURL(url) //释放掉blob对象
            }
        }).catch(err => {
            Vue.prototype['$message'].error(err.msg || '文件下载失败')
        })
    })
}
// 检测下载接口是否失败
function blobToText(blob: any) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsText(blob);
        fileReader.onload = function () {
            try {
                const result = JSON.parse((this as any).result);
                if (result && result.code !== '200') {
                    reject(result);
                } else {
                    resolve(blob);
                }
            } catch (e) {
                resolve(blob);
            }
        }
    })
}