import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// 动态读取modules下的文件 并自动注册
const files = require.context('./modules', false, /\.ts$/)
interface moduleObject {
  [key: string]: any
}
const modules: moduleObject = {}
files.keys().forEach(key => {
  modules[key.replace(/(\.\/|\.ts)/g, '')] = files(key).default
})

import createPersistedState from 'vuex-persistedstate';

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules,
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  plugins: [  
    createPersistedState({  
      storage: window.localStorage, // 使用localStorage作为存储介质，也可以使用sessionStorage  
      key: 'ai-doc-vuex-state', // 存储状态的key，默认值为'vuex'  
    }),  
  ],  
});
