// import axios from "@/common/utils/request";

// @ts-nocheck
// import config from "../../config/proxy.config.js";

// const context = process.env.VUE_APP_API;

export default {
  // 文件操作相关
  userFilePageList: `/star-doc/document/userFile/pageList`,
  // fileWeightPageList: `/star-doc/document/fileWeight/pageList`,
  fileWeightPageList: `/star-doc/document/userFile/recent/files`,
  fileEditWeightPageList: `/star-doc/document/fileEditWeight/pageList`,
  starPageList: `/star-doc/coop/star/pageList`,

  userFileAdd: `/star-doc/file/add`,
  userFileAddFolder: `/star-doc/document/userFile/addFolder`,

  userFileBatchDelete: `/star-doc/document/userFile/delete`,

  userFileCopy: `/star-doc/document/userFile/copy`,
  userFileMoves: `/star-doc/document/userFile/move`,

  userFileRename: `/star-doc/document/userFile/rename`,

  userFileUpdateRemark: `/star-doc/document/userFile/updateRemark`,

  // 在线文档
  officeEdit: `/star-doc/office/edit`,
  // officeView: `/star-doc/office/view`,
  officeView: `/star-doc/document/userFile/preview`,
  officeForceSave: `/star-doc/office/forceSave`,
  officeCallback: `/star-doc/office/callback`,

  // 查询文件目录
  queryPathDic: `/star-doc/document/userFile/queryPathDic`,
};
