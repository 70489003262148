// import { Team } from "@/interface/index";
// import apis from "@/apis/index"
import apis from "@/apis";
import { settings } from "nprogress";
const docTool = {
  namespaced: true,
  state: {
    timer: null, // 转换的--轮询计时器
    // 文档工具转换来源
    docToolFrom:'fileMenu', // fileMenu--文件右键菜单 / topRightMenu   
    conversionStatusInfo: [], // 转换--转换文件列表状态
    docTransformDialogFlag: false, // 文档转换列表的弹窗
    showDocToolMinimizedFloatingWindow: false, // 是否显示最小化窗口
    activeMenu: "", // 当前在操作的文档工具菜单
   
    // // 从云文档选择的待转换的文件
    // selectFiles: [],
    // 已经添加到转换列表中的文件
    conversionFiles: [],
    notificationList:[] // 最小化悬浮窗的消息通知队列
    // selectListFilesObj: {
    //   items: [],
    //   ids: [],
    //   time: 0,
    // },
    // #endregion
  },
  mutations: {
    // 清空文档工具的所有缓存状态，数据等，初始化重置所有维护数据
    clearAllDocToolData(state:any) {
      clearInterval(state.timer);
      state.timer=null
      state.docToolFrom=''
      state.conversionStatusInfo=[]
      state.docTransformDialogFlag=false
      state.showDocToolMinimizedFloatingWindow=false
      state.activeMenu=''
      state.conversionFiles=[]
      state.notificationList=[]
    },

    // 更改文档工具转换来源
    changeDocToolFrom(state:any, newVal:any) {   
      state.docToolFrom=newVal
    },
    // 覆盖--最小化悬浮窗的消息通知队列的数据
    coverNotificationList(state:any, newVal:any) {  
      state.notificationList=newVal
    }, 
    // 追加--最小化悬浮窗的消息通知队列的数据
    addNotificationList(state:any, newVal:any) {  
      state.notificationList.push(newVal)
    }, 


    // 设置轮询计时器
    startPollingStatus(state: any, val: any) {
      state.timer = val;
    },
    // 修改转换文件列表的状态
    updateConversionStatusInfo(state: any, val: any) {
      state.conversionStatusInfo = val;
    },

    // 结束轮询
    stopPollingStatus(state: any) {
      if (state.timer) {
        console.log("初始化计时器一下");
        clearInterval(state.timer);
        state.timer = null;
      }
    },

    // 设置正在操作的文档工具菜单
    setActiveMenu(state: any, val: any) {
      state.activeMenu = val;
    },

    // 显示和关闭文档转换列表的弹窗
    changeDocTransformDialogFlag(state: any, val: any) {
      state.docTransformDialogFlag = val;
    },

    // 是否显示文档工具转换列表的最小化悬浮窗口
    setShowDocToolMinimizedFloatingWindow: (state: any, val: any) => {
      state.showDocToolMinimizedFloatingWindow = val;
    },

    // Clear file list清空转换列表中的文件
    clearFileList: (state: any) => {
      state.conversionFiles = [];
    },


    // 替换转换列表中的文件
    changeConversionFiles: (state: any,ArrayValue:any) => {
      console.log('合并新数据');
      state.conversionFiles = ArrayValue;
    },

    // 添加转换列表中的文件
    addSelectFiles: (state: any, itemList: any[]) => {
      // 新添加的文件在前面，过滤由添加文件时候的禁用规则来处理，已勾选但无禁用的才可以再次添加进来，避免同文件覆盖的情况
      const newItemList = itemList.filter(item=>!item.disabled)
      console.log('newItemList是啥',newItemList);
      state.conversionFiles=state.conversionFiles.concat(newItemList)
      console.log('数据添加成功', state.conversionFiles);
      state.timer=null // 初始化轮询的计时器
    },

    // 删除转换列表中的某个文件
    filterConversionFilesById: (state: any, row: any) => {
      state.conversionFiles = state.conversionFiles.filter((item: any) => item.id !== row.id);
    },
  },
  actions: {

    // 清空转换文件中的列表，发送请求通知后端
   async async_clearFileList(store: any) {
      let params={
        converterSource:'PC'
      }
      try {
        const res = await apis.clearConversionListHttp(params);
        console.log("清空转换文件中的列表--接口res:", res);
        store.commit("clearFileList");

        console.log("清空转换文件列表状态:");
        store.commit("updateConversionStatusInfo",[]);

        
      } catch (error) {
        console.log("获取转换接口信息报错:", error);
        setTimeout(() => {
          store.commit("clearFileList");
        }, 2000);
      }

    },


    // 开始轮询
    async_startPollingStatus(store: any) {
      const getConverterStatusInfo = async () => {
        let params={
          converterSource:'PC'
        }
        try {
          const res = await apis.converterStatusHttp(params);
          console.log("接口res:", res);
          store.commit("updateConversionStatusInfo", res?.data);
        } catch (error) {
          console.log("获取转换接口信息报错:", error);
        }
      };
      if (!store.state.timer) {
        console.log("进入轮询设置");
        getConverterStatusInfo();
        console.log("初始调用一次");
        store.commit(
          "startPollingStatus",
          setInterval(() => {
            // 计时器内调用
            getConverterStatusInfo();
          }, 3000) // 每3秒检查一次状态
        );
      } else {
        console.log('结束了轮训---');
        store.commit("stopPollingStatus");
      }
    },
    // 结束轮询
    async_stopPollingStatus(store: any) {
      store.commit("stopPollingStatus");
    },

    // 设置正在操作的文档工具菜单
    async_setActiveMenu(store: any, val: any) {
      store.commit("setActiveMenu", val);
    },

    // 显示和关闭文档转换列表的弹窗
    async_changeDocTransformDialogFlag(store: any, val: any) {
      store.commit("changeDocTransformDialogFlag", val);
    },
    // 是否显示文档工具转换列表的最小化悬浮窗口
    async_setShowDocToolMinimizedFloatingWindow(store: any, val: any) {
      store.commit("setShowDocToolMinimizedFloatingWindow", val);
    },
  },
};
export default docTool;
