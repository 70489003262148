import { getAction, postAction, postActionUpload, downloadFile } from './method'

export default {
    // 下载
    download(params: {
        userFileId: string
        fileName: string
    }) {
        return downloadFile('/star-doc/file/download', params.fileName, {}, {
            userFileId: params.userFileId
        }, "GET")
    },
    // 添加客户端id
    // addClientId(data: {
    //     clientId: string
    // }) {
    //     return postAction('/star-doc/file/addClientId', {}, data)
    // },
    // 文件预上传
    filePreUpload(data: any) {
        return postAction('/star-doc/file/preUpload', {}, data)
    },
    // 文件上传
    fileUpload(data: any) {
        return postActionUpload('/star-doc/file/upload', data)
    }
}