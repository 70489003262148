<template>
  <div class="documentTool-addFile-box">
    <a-modal
      wrapClassName="documentTool-addFile"
      title=""
      :width="1100"
      :visible="showAddFile"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :closable="false"
      :footer="null"
      :maskClosable="false"
      okText="添加"
    >
      <div class="content">
        <div class="title">
          <span class="txt">选择文件</span>
          <div class="search-box">
            <a-input-search
              ref="userNameInput"
              v-model="userFileName"
              @search="searchFile"
              placeholder="搜索文件"
            >
              <!-- <a-icon slot="prefix" type="search" /> -->
            </a-input-search>
          </div>
          <div class="img_box">
            <img
              @click="closeDocTool"
              style="width: 24px; height: 24px"
              src="@/assets/images/documentTool/close.png"
              alt=""
            />
          </div>
        </div>
        <div
          style="min-height: 470px; background-color: #fff; user-select: none"
        >
          <!-- :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
              columnTitle: ' ',
              getCheckboxProps: getCheckboxProps,
            } -->
          <a-table
            ref="addFileTableRefs"
            :rowSelection="tableRowSelection"
            :rowKey="(record) => record.id"
            :columns="columns"
            :pagination="false"
            :scroll="{ y: 430 }"
            :loading="loading"
            :data-source="datas"
            :customRow="customRow"
          >
            <!-- 自定义文件名称 -->
            <template slot="name" slot-scope="val, row">
              <div style="display: flex; width: 100%; align-items: center">
                <h-icon class="list-img" :type="row.extensionName" alt="" />
                <a-tooltip
                  placement="bottom"
                  overlayClassName="doc-tool-table-toolTip-addFile"
                >
                  <template slot="title">
                    <span>{{ val }}.{{ row.extensionName }}</span>
                  </template>
                  <span style="margin-left: 10px" class="name-text"
                    >{{ val }}.{{ row.extensionName }}</span
                  >
                </a-tooltip>
              </div>
            </template>
            <!-- 自定义位置 -->
            <template slot="actionFilePath" slot-scope="val, row">
              <div style="display: flex; width: 100%; align-items: center">
                <a-tooltip
                  placement="bottom"
                  overlayClassName="doc-tool-table-toolTip-addFile"
                >
                  <template slot="title">
                    <span>{{ row.actionFilePath }}</span>
                  </template>
                  <span style="margin-left: 10px" class="name-text">{{
                    row.actionFilePath
                  }}</span>
                </a-tooltip>
              </div>
            </template>
          </a-table>
          <div class="loading-spinner-box">
            <!-- <div v-if="loading && datas.length" class="loading-spinner-ing">
              加载中...
            </div> -->
            <div class="loading-spinner-end" v-if="showAllDataLoadedMessage">
              没有更多了
            </div>
          </div>
        </div>
        <div class="foooter">
          <span
            class="toolTip"
            :class="!maxSelecitonFlag ? 'transparency' : ''"
          >
            最多可选择20个文件
          </span>

          <!-- 文档转换或者编辑知识库的底部 -->
          <div
            class="btn"
            v-if="addFileType == 'converter' || addFileType == 'editKnowledge'"
          >
            <a-button @click="closeDocTool()">取消</a-button>
            <a-button
              type="primary"
              style="margin-left: 10px"
              v-if="
                selectedFiles.filter((item) => item.disabled !== true)?.length
              "
              @click="addFilesFn"
            >
              <!-- 添加({{ selectedRowKeys.length }}) -->
              添加({{
                selectedFiles.filter((item) => item.disabled !== true)?.length
              }})
            </a-button>
            <a-button disabled type="primary" style="margin-left: 10px" v-else>
              添加
            </a-button>
          </div>
          <!-- pdf编辑的底部 -->
          <div class="btn" v-if="addFileType == 'pdfEdit'">
            <a-button @click="closeDocTool()">取消</a-button>
            <a-button
              type="primary"
              style="margin-left: 10px"
              @click="confirmEdit"
            >
              确定
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    showAddFile: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    addFileType: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      total: 0,
      showAllDataLoadedMessage: false,
      allDataLoaded: false,
      isPage: 1,
      pageNum: 1,
      pageSize: 10,
      isSingleSelect: true, // 控制是否为单选
      extensionNames: [], // 需要传递给后端的文件类型数组，获取对应的文件列表
      maxSeleciton: 20,
      userFileName: "",
      loading: false,
      ModalText: "Content of the modal",
      confirmLoading: false,
      selectedFiles: [],
      datas: [],
      selectedRowKeys: [],
      columns: [
        {
          title: "文件名称",
          dataIndex: "userFileName",
          // width: 200,
          scopedSlots: { customRender: "name" },
          // ellipsis: true,
        },
        {
          title: "文件位置",
          dataIndex: "actionFilePath",
          scopedSlots: { customRender: "actionFilePath" },
          // ellipsis: true,
        },
      ],
    };
  },
  watch: {
    addFileType: {
      handler(newVal) {
        console.log("addFileType是啥", newVal);
        if (newVal == "converter" || newVal == "editKnowledge") {
          this.isSingleSelect = true;
        } else {
          this.isSingleSelect = false;
        }
      },
      immediate: true,
    },
    activeMenu: {
      handler(newVal) {
        if (newVal && newVal.length) {
          switch (newVal) {
            case "PDF_Word":
              this.extensionNames = ["pdf"];
              break;
            case "Word_PDF":
              this.extensionNames = ["docx", "doc"];
              break;
            case "Excel_PDF":
              this.extensionNames = ["xlsx", "xls"];
              break;
            case "PPT_PDF":
              this.extensionNames = ["ppt", "pptx"];
              break;
            // 特殊情况--编辑知识库
            case "editKnowledge":
              this.extensionNames = ["doc", "docx", "pdf", "txt"];
              break;

            default:
              // 如果不匹配任何情况，extensionNames保持为空数组
              return (this.extensionNames = []);
              break;
          }
        }
      },
      immediate: true,
    },
    selectedRowKeys: {
      handler(newVal) {
        if (this.addFileType == "editKnowledge") {
          this.maxSelecitonFlag = false;
        } else {
          if (newVal.length) {
            console.log("触发更新禁用规则等");
            this.datas = this.datas.map((item) => {
              return {
                ...item, // 保留原始对象的其他属性
                disabled:
                  this.conversionFilesIds.includes(item.id) ||
                  (!newVal.includes(item.id) &&
                    this.selectedRowKeys.length >= this.maxSeleciton), // 根据条件设置disabled属性
              };
            });
            if (this.selectedRowKeys.length >= this.maxSeleciton) {
              this.maxSelecitonFlag = true;
            } else {
              this.maxSelecitonFlag = false;
            }
          } else {
            this.selectedFiles = [];
            this.datas = this.datas.map((item) => {
              return {
                ...item,
                disabled: false,
              };
            });
            this.maxSelecitonFlag = false;
          }
        }
      },
      immediate: true,
    },

    conversionFiles: {
      handler(newVal) {
        if (newVal.length) {
          let ids = newVal.map((item) => item.id);
          console.log("之前已选择文件id数组", ids);
          this.selectedRowKeys = ids;
          // console.log("this.datas---", this.datas);
          // 遍历datas数组，根据selectedRowKeys设置disabled属性
          // setTimeout(() => {
          //   this.datas = this.datas.map((item) => {
          //     return {
          //       ...item, // 保留原始对象的其他属性
          //       disabled: this.selectedRowKeys.includes(item.id),
          //     };
          //   });
          //   console.log("this.datas", this.datas);
          // }, 700);
        }
      },
      immediate: true,
      deep: true, // 如果newVal是一个对象或数组，监听其内部的变化
    },
  },
  computed: {
    tableRowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        columnTitle: " ",
        getCheckboxProps: this.getCheckboxProps,
        type: this.isSingleSelect ? "checkbox" : "radio", // 如果 isSingleSelect 为 true，则为单选
      };
    },

    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    // 当前在操作的文档工具菜单
    activeMenu() {
      return this.$store.state.docTool.activeMenu;
    },
    // 已经添加到转换列表中的文件数组
    conversionFiles() {
      return this.$store.state.docTool.conversionFiles;
    },
    // 已经添加到转换列表中的文件id数组
    conversionFilesIds() {
      if (this.$store.state.docTool.conversionFiles.length) {
        return this.$store.state.docTool.conversionFiles.map((item) => item.id);
      } else {
        return [];
      }
    },
  },
  created() {
    this.getFilterFileTypeListFn();
  },
  mounted() {
    this.$nextTick(() => {
      const tableBody =
        this.$refs.addFileTableRefs.$el.querySelector(".ant-table-body");
      tableBody.addEventListener("scroll", this.handleScroll);
    });
  },
  methods: {
    handleScroll(event) {
      // alert('999')
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollHeight - scrollTop === clientHeight) {
        if (!this.allDataLoaded) {
          this.getFilterFileTypeListFn(); // 当滚动到底部时加载下一页数据
        } else {
          this.showAllDataLoadedMessage = true; // 显示“已加载完全部数据”消息
          // setTimeout(() => {
          //   this.showAllDataLoadedMessage = false; // 2秒后隐藏该消息
          // }, 2000);
        }
      } else {
        this.showAllDataLoadedMessage = false;
        console.log("没有触底");
      }
    },
    customRow(record) {
      return {
        on: {
          // // 点击行
          // dblclick: () => {
          //   return this.hadnleFile(record);
          // },
          click: () => {
            let index = this.selectedRowKeys.indexOf(record.id);
            if (this.addFileType !== "pdfEdit") {
              if (!record.disabled) {
                if (index !== -1) {
                  this.selectedRowKeys.splice(index, 1);
                  this.selectedFiles.splice(index, 1);
                } else {
                  this.selectedRowKeys.push(record.id);
                  this.selectedFiles.push(record);
                }
              }
            } else {
              if (index == -1) {
                this.selectedRowKeys = [];
                this.selectedRowKeys.push(record.id);
              }
            }
          },
        },
      };
    },

    // 确定编辑
    confirmEdit() {
      const id = this.selectedRowKeys[0];
      let checkedItem = this.datas.find((item) => item.id == id);
      // let query={
      //   file:''
      // }
      // 跳转编辑页面
      // let routeDatas = this.$router.resolve({
      //   name: "PDF_EDIT",
      //   query,
      // });
      this.$router.push({
        name: "PDF_EDIT",
        query: {
          id,
          activeMenu: this.activeMenu,
          userFileName: encodeURIComponent(checkedItem.userFileName),
        },
      });
      // 初始化文档工具所有可维护数据及状态重置
      this.$store.commit("docTool/clearAllDocToolData");
      console.log("确定编辑-正在操作的菜单", this.activeMenu);
    },

    // 添加文件
    addFilesFn() {
      // if (this.conversionFiles && this.conversionFiles.length) {
      //   items = [...new Set([...this.selectedFiles, ...this.conversionFiles])];
      //   console.log("选择后的文件001", items);
      // } else {
      //   items = this.selectedFiles;
      //   console.log("选择后的文件002", items);
      // }

      let items = [];
      items = this.selectedFiles;

      // 编辑知识库的情况特殊处理
      if (this.addFileType == "editKnowledge") {
        this.$emit("addFilesFn", items);
      } else {
        this.$store.commit("docTool/addSelectFiles", items);
        this.$emit("addFilesFn");
        setTimeout(() => {
          this.$emit("closeAddFile");
          this.selectedRowKeys = [];
        }, 200);
      }
    },

    // 搜索文件
    searchFile(val) {
      this.pageNum = 1;
      this.total = 0;
      console.log("搜索了啥", val);
      this.getFilterFileTypeListFn();
    },

    // 关闭选择文件弹窗
    closeDocTool() {
      // this.visible = false;
      this.$emit("closeAddFile");
    },

    async getFilterFileTypeListFn() {
      this.loading = true;
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        fileName: this.userFileName,
        extensionNames: this.extensionNames.length
          ? this.extensionNames
          : ["pdf"],
      };
      if (this.total && this.datas?.length >= this.total) {
        this.allDataLoaded = true;
        this.loading = false;
        console.log("没有更多了--总数量", this.total);
        return;
      }

      try {
        const res = await this.$apis.filterFileTypeListHttp(params);
        this.total = res?.data?.total;
        console.log("过滤类型的文件列表:", res);
        const listTemp = res.data.list.map((item) => {
          return {
            ...item, // 保留原始对象的其他属性
            actionFilePath: this.getActionFilePath(item),
            disabled: this.conversionFilesIds.includes(item.id),
          };
        });

        this.pageNum === 1
          ? (this.datas = listTemp)
          : (this.datas = this.datas.concat(listTemp));
        this.loading = false;
        this.pageNum += 1;
      } catch (error) {
        this.loading = false;
        console.log("🚀 ~ getFilterFileTypeListFn ~ error:", error);
      }
    },
    // start() {
    //   this.loading = true;
    //   // ajax request after empty completing
    //   setTimeout(() => {
    //     this.loading = false;
    //     this.selectedRowKeys = [];
    //   }, 1000);
    // },
    getActionFilePath(item) {
      let str = item.userFilePath;
      if (item.moduleType == "doc") {
        if (str === "/") {
          return "个人文档";
        }
        // 如果有多个部分，去掉最后一个斜杠，并在第一个斜杠前加上"个人文档"
        const parts = str.split("/");
        parts.shift(); // 去掉第一个空字符串（如果存在）
        return "个人文档/" + parts.join("/");
      } else {
        if (str === "/") {
          return item.companyTeamName;
        }
        const parts = str.split("/");
        parts.shift(); // 去掉第一个空字符串（如果存在）
        return item.companyTeamName + "/" + parts.join("/");
      }
    },
    onSelectChange(selectedRowKeys, items) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedFiles = items;
      //
      //   console.log("选中的所有文件id", this.selectedRowKeys);
      console.log("选中的所有项--组件触发", this.selectedFiles);
      //   console.log("总列表数据", this.datas);
    },

    getCheckboxProps(record) {
      return {
        props: {
          disabled: record.disabled,
        },
      };
    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      console.log("Clicked cancel button");
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.loading-spinner-box {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-spinner-end {
    width: 100%;
    height: 100%;
    color: #999;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
  }
}

.content {
  padding: 0 20px;
  background-color: #f1f2f5 !important;
  border-radius: 16px !important;
  // 表格文件名
  .name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    justify-content: space-between;
    .search-box {
      width: 300px;
    }
    .img_box {
      margin-right: 10px;
      img {
        cursor: pointer;
      }
      .min {
        margin-right: 20px;
      }
    }
  }
  .foooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    .transparency {
      opacity: 0;
    }
    .toolTip {
      color: red;
      font-size: 12px;
    }
    .btn {
      margin-right: 0 auto;
    }
  }
}
</style>

<style lang="less">
// 表格文件名称和位置的悬浮提示
.doc-tool-table-toolTip-addFile {
  max-width: 500px !important;
}

.documentTool-addFile {
  .ant-table-row {
    cursor: pointer !important;
  }

  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-body {
    padding: 0 !important;
    border-radius: 16px !important;
  }
  .ant-modal-footer {
    background-color: #f1f2f5 !important;
    border-radius: 16px !important;
  }

  //  选择文件表格部分
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    background-color: #f5f7f9 !important;
  }

  .ant-table-placeholder {
    height: 470px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-thead {
    background-color: #f5f7f9 !important;
    .ant-table-row-cell-last {
      padding-left: 28px !important;
    }
    th {
      background-color: #f5f7f9 !important;
      .ant-table-selection-column {
        display: none;
        margin-right: 60px;
      }
    }
  }
}
</style>
