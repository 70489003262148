/**
 * @author pengxiaobao
 * @description 发送请求，可以对具体某个进行自定义请求头，请求参数，也可进行返回数据格式化处理成页面想要的数据格式
 */
import knowledgeBase from "./knowledgeBase"; // 知识库相关
import docTool from "./docTool";
import apiConfig from "./apiConfig";
import axios from "@/common/utils/request";
import store from "@/store";
import router from "@/router";
import common from "./common";
import user from "./user";
import mdEditor from "./mdEditor";
import address from "./address";
// import aiCreat from "./aiCreat";
import ai from "./ai";
import memberOrDep from "./memberOrDep";
import uploadDownload from "./uploadDownload";
import recycleBin from "./recycleBin";
import tag from "./tag";
import share from "./share";
import file from "./file";
import companyTeam from "./companyTeam";
import cooperation from "./cooperation";
interface result {
  code?: string; //  状态码
  data: any; //  数据
  msg?: string; //  返回值描述文字
}
/**
 *
 * @param parameter
 * @returns Promise
 */
export default {
  ...knowledgeBase, // 知识库相关
  ...mdEditor,
  ...docTool,
  ...ai,
  // ...aiCreat,
  ...memberOrDep,
  ...common,
  ...user,
  ...uploadDownload,
  ...recycleBin,
  ...tag,
  ...share,
  ...file,
  ...companyTeam,
  ...cooperation,
  ...address,
  // 新增文件
  userFileAdd(data: {
    extensionName: string; // 后缀名 !
    userFileName: string; // 文件名 !
    userFilePath: string; // 文件路径 !
    userFileType: string; // 文件类型 !
    parentId?: string; // 父级id
    userFileDescription?: string; //  文件描述
  }) {
    return axios({
      url: apiConfig.userFileAdd,
      method: "post",
      data,
    }).then((res: result) => res.data);
  },

  // 新增文件夹
  userFileAddFolder(data: {
    userFileName: string; // 文件名 !
    userFilePath: string; // 文件路径 !
    parentId: string; // 父级id !
  }) {
    return axios({
      url: apiConfig.userFileAddFolder,
      method: "post",
      data: {
        ...data,
        companyId: "person",
      },
    }).then((res: result) => res);
  },

  // 根据文件ids批量删除
  userFileBatchDelete(data: {
    ids: string; // 文件id, 逗号分隔  !
  }) {
    return axios({
      url: apiConfig.userFileBatchDelete,
      method: "post",
      // data,
      params: data,
    }).then((res: result) => res.data);
  },

  // 复制文件
  userFileCopy(data: {
    id: string; // 文件id !
    userFilePath: string; // 目标路径id !
  }) {
    return axios({
      url: apiConfig.userFileCopy,
      method: "post",
      data,
    }).then((res: result) => res);
  },

  // 文件批量移动
  userFileMoves(data: {
    ids: string; // 文件id, 逗号分隔  !
    targetId: string; // 目标路径id !
  }) {
    return axios({
      url: apiConfig.userFileMoves,
      method: "post",
      data,
    }).then((res: result) => res);
  },

  // 文件重命名
  userFileRename(data: {
    id: string; // 文件id !
    userFileName: string; // 新文件名 !
  }) {
    return axios({
      url: apiConfig.userFileRename,
      method: "post",
      data,
    }).then((res: result) => res.data);
  },

  // 修改文件备注
  userFileUpdateRemark(data: {
    id: string; // 文件id !
    remark: string; // 备注 !
  }) {
    return axios({
      url: apiConfig.userFileUpdateRemark,
      method: "post",
      data,
    }).then((res: result) => res.data);
  },

  // 用户文件展示
  userFilePageList(
    // 父级id和模块类型
    data: { id: string | number; moduleType: string } = {
      id: "",
      moduleType: "",
    }
  ) {
    return axios({
      url: apiConfig.userFilePageList,
      method: "post",
      data: {
        ...data,
        isPage: 0,
        companyId: "person",
        parentId: data?.id ?? "",
        moduleType: "doc",
      },
    }).then((res: result) => res.data);
  },

  // 最近查看
  fileWeightPageList(
    data: { id: string | number } = { id: "" } // 文件夹id
  ) {
    return axios({
      url: apiConfig.fileWeightPageList,
      method: "post",
      data: {
        ...data,
        isPage: 0,
        companyId: "",
        parentId: "",
        moduleType: "doc",
      },
    }).then((res: result) => res.data);
  },

  // 最近编辑
  fileEditWeightPageList(
    data: { id: string | number } = { id: "" } // 文件夹id
  ) {
    return axios({
      url: apiConfig.fileEditWeightPageList,
      method: "post",
      data: { ...data, isPage: 0, companyId: "person" },
    }).then((res: result) => res.data);
  },
  // 星标
  starPageList(
    data: { id: string | number } = { id: "" } // 文件夹id
  ) {
    return axios({
      url: apiConfig.starPageList,
      method: "post",
      data: { ...data, isPage: 0 },
    }).then((res: result) => res.data);
  },

  // 在线文档编辑
  officeEdit(
    data: {
      fileId: string | number;
      userId: string | number;
      userFileExtension: string;
      userFileName?: string;
      userName?: string;
      fileHashCode?: string;
    },
    header?: any
  ) {
    return axios({
      url: apiConfig.officeEdit,
      method: "post",
      data,
      headers: header,
    }).then((res: result) => res.data);
  },
  // 在线文档预览
  // 文件预览
  officeView(
    data: {
      fileId: string | number;
      userId: string | number;
      userFileExtension: string;
      userFileName?: string;
      userName?: string;
    },
    header?: any
  ) {
    return axios({
      url: apiConfig.officeView,
      // method: "post",
      method: "get",
      params: data,
      headers: header,
    }).then((res: result) => res.data);
  },
  // 保存更改
  officeForceSave(data: {
    c: string | number;
    documentKey: string | number;
    userData: {
      id: string | number;
      name?: string | number;
    };
  }) {
    return axios({
      url: apiConfig.officeForceSave,
      method: "post",
      data,
    }).then((res: result) => res.data);
  },

  // 预览/编辑回调
  officeCallback(data: {
    status: string | number;
    url: string;
    userData: any;
    documentKey: string | number;
  }) {
    return axios({
      url: apiConfig.officeCallback,
      method: "post",
      data,
    }).then((res: result) => res.data);
  },
  // 查询文件目录
  queryPathDic(data: { id: string | number } = { id: "" }) {
    return axios({
      url: apiConfig.queryPathDic,
      method: "post",
      params: data,
    }).then((res: result) => res);
  },
};
