
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { Carousel } from "ant-design-vue";
@Component({
  name: "Announcement",
  components: {
    [Carousel.name]: Carousel,
  },
  filters: {
    //局部过滤器
  },
})
export default class Announcement extends Vue {
  public index: number = 0;
  public message: string = "";
  public visible: boolean = false;
  public showAnnouncement: any = {};

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ required: true })
  readonly messageList!: any[];

  //   setMessage() {
  //     this.message = this.messageList[this.index];
  //     this.$nextTick(() => {
  //       this.runMarquee();
  //     });
  //   }

  /* 公告左右滚动 */
  //   runMarquee() {
  //     // 获取文字 计算后宽度
  //     var width: number = (document as any)
  //         .getElementById("marquee")
  //         .getBoundingClientRect().width,
  //       marquee: any = document.getElementById("marquee-box");
  //     //设置位移
  //     // console.log(width, marquee);
  //     let time = Math.round((width + 10) / 30);
  //     marquee.style.transition = "all " + time + "s linear 1s";
  //     marquee.style.left = -(width + 10) + "px";
  //     setTimeout(() => {
  //     //   console.log(this);
  //       marquee.style.transition = "none";
  //       marquee.style.left = "0px";
  //       this.index >= this.messageList.length - 1
  //         ? (this.index = 0)
  //         : this.index++;
  //       this.setMessage();
  //     }, (time + 1) * 1000);
  //   }

  //查看全部公告
  announcementAll(): void {
    this.$router.push({
      path: "/star/menu/announcement",
      query: { type: "announcement" },
    });
  }

  announcementModalShow() {
    if (this.messageList.length > 0) {
      this.showAnnouncement = this.messageList[0];
      this.visible = true;
    }
  }

  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {}
  mounted(): void {
    // 延时滚动
    // setTimeout(() => {
    //   this.setMessage();
    // }, 1000);
  }
}
