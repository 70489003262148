import { getAction, postAction, queryImage } from "./method";

export default {
  // 获取是否开启审核
  getIsAuditApi() {
    return getAction(`/star-doc/app/isAudit`);
  },

  // 获取企业邀请码
  getEnterpriseInvitationCodeApi(data: { userId: string }) {
    return getAction(
      `/auth/sys/userInvitation/queryInvitationCode?_t=${Date.now()}&orgId=`,
      data
    );
  },

  // 邀请码认证
  codeAuthApi(data: any, header: any) {
    return postAction("/auth/sys/userInvitation/auth", data, {}, header);
  },

  // 是否开启了应用所
  getShowApp(data: any, header: any) {
    return postAction("/auth/sys/applocker/check", data, {}, header);
  },

  // 应用所密码输入
  getShowAppPwsWord(data: any, header: any) {
    return postAction("/auth/sys/applocker/open", data, {}, header);
  },

  // 用户身份
  userRole(data: any, header: any) {
    return postAction("/star-doc/user/roles", data, {}, header);
  },

  // 文件解压
  userFileUnzip(data: any, header: any) {
    return postAction("/star-doc/document/userFile/unzip", data, {}, header);
  },

  // 查询用户信息
  getUserDetail(data: { userId: string }) {
    return getAction("/star-doc/user/detail", data);
  },

  // 获取用户容量信息
  getUserCapacity() {
    return getAction("/star-doc/capacity");
  },

  // 上传头像
  uploadProfilePhoto(data: any, header: any) {
    return postAction("/star-doc/user/upload", data, {}, header);
  },
  // 修改用户名称
  userEditName(data: { id: string | number; nickname: string }) {
    return postAction("/star-doc/user/edit", data);
  },
  // 手机号绑定
  bindPhone(data: { code: string; loginId: string }) {
    return postAction("/star-doc/user/bind/phone", data);
  },
  // 手机号更换
  resetPhone(data: { code: string; loginId: string; modelNumber: string }) {
    return postAction("/star-doc/user/reset/phone", data);
  },
  // 手机号解绑
  unbindPhone(data: { code: string; loginId: string; modelNumber: string }) {
    return postAction("/star-doc/user/unbind/phone", data);
  },
  // 邮箱更换
  resetEmail(data: { code: string; loginId: string; modelNumber: string }) {
    return postAction("/star-doc/user/reset/email", data);
  },
  // 身份认证
  userAuth(data: { code: string; loginId: string; modelNumber: string }) {
    return postAction("/star-doc/user/auth", data);
  },
  // 修改密码
  modifyPassword(data: {
    id: string | number;
    oldPassword: string | number;
    password: string | number;
  }) {
    return postAction("/star-doc/user/modifyPassword", data);
  },
  // 登录信息列表
  loginLog(current: number) {
    return postAction("/star-doc/user/loginLog/list", {
      isPage: 1,
      pageNum: current,
      pageSize: 4,
    });
  },

  /* 反馈 */
  // 新增反馈信息
  feedbackAdd(data: any, header: any) {
    return postAction("/star-doc/feedback/add", data, {}, header);
  },
  // 反馈列表
  feedbackPageList(data: any, header: any) {
    return postAction("/star-doc/feedback/pageList", data, {}, header);
  },
  // 根据id查询反馈信息
  feedbackQueryById(data: any, header: any) {
    return postAction("/star-doc/feedback/queryById", data, {}, header);
  },
};
