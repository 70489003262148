import dayjs from "dayjs";
const publicFormatName = (fileName, type) => {
  if (type && type !== "other" && type !== "folder")
    return `${fileName}.${type}`;
  else return fileName;
};

const publicFormatTime = (val) => {
  return dayjs(val).format("YYYY-MM-DD HH:mm");
};

const publicFormatSize = (size) => {
  let valTemp = "";
  if (size < 1024) {
    valTemp = `${size}B`;
  } else if (size < 1024 * 1024) {
    valTemp = size / 1024;
    valTemp = `${valTemp.toFixed(2)}KB`;
  } else if (size < 1024 * 1024 * 1024) {
    valTemp = size / (1024 * 1024);
    valTemp = `${valTemp.toFixed(2)}MB`;
  } else {
    valTemp = size / (1024 * 1024 * 1024);
    valTemp = `${valTemp.toFixed(2)}GB`;
  }
  return valTemp;
};

const publicFormatExpire = (val) => {
  if (!val) return "永久有效";
  if (val === 1) return "7天有效";
  if (val === 2) return "1天有效";
};

// 截取文件扩展名
const publicFormatFileType = (fileName) => {
  if (fileName) {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex !== -1) return fileName.substring(lastDotIndex + 1);
  }
  return "undefined"; // 默认的类型，你需要根据实际情况设定
};

export {
  publicFormatName,
  publicFormatTime,
  publicFormatSize,
  publicFormatExpire,
  publicFormatFileType,
};
