export default [
    {
        type: 'system',
        title: '个人中心',
        menuList: [
            {
                title: "个人信息",
                menuType: 'system',
                iconType: 'h-icon',
                icon: "menuTeam",
                action: false,
                path: "/star/menu/userinfo",
                routeNmae: "VIEW_ACCOUNT_SECURITY",
            },
            {
                title: "登录记录",
                menuType: 'system',
                iconType: 'h-icon',
                icon: "menuLoginRecord",
                action: false,
                path: "/star/menu/loginRecord",
                routeNmae: "VIEW_LOGIN_RECORD",
            },
            {
                title: "我的反馈",
                menuType: 'system',
                iconType: 'h-icon',
                icon: "menuFeedback",
                action: false,
                path: "/star/menu/feedback",
                routeNmae: "VIEW_FEEDBACK",
            },
            {
                title: "积分任务",
                menuType: "system",
                iconType: "h-icon",
                icon: "menuTask",
                action: false,
                path: "/star/menu/task",
                routeNmae: "VIEW_TASK",
              },
        ]
    },
    {
        type: 'announcement',
        title: '公告',
        menuList: [
            {
                title: "公告列表",
                menuType: "announcement",
                iconType: 'h-icon',
                icon: "announcement",
                action: false,
                path: "/star/menu/announcement",
                routeNmae: "VIEW_ANNOUNCEMENT",
            }
        ]
    }
]
