import { Cooperation } from "@/interface/index";

const share = {
  namespaced: true,
  state: {
    invitedLoginShow: false,
    triggerListquery: 0
  },
  mutations: {
    SET_INVITEDLOGINSHOW: (state: Cooperation, payload: boolean) => {
      state.invitedLoginShow = payload
    },
    SET_TRIGGERLISTQUERY: (state: Cooperation) => {
      state.triggerListquery++
    },
  },
  actions: {},
};
export default share;
