
import Vue from "vue";
// @ts-ignore
import { publicFormatSize, publicFileView } from "@/utils/index";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { Pagination } from "ant-design-vue";
import dayjs from "dayjs";
import { setInterval } from "timers";

@Component({
  name: "TransferList",
  components: {
    [Pagination.name]: Pagination,
  },
  filters: {
    //局部过滤器
    suffix(value: string): string {
      let arr = value.split(".");
      if (arr.length > 0) {
        return arr[arr.length - 1];
      } else {
        return "";
      }
    },
    calculateFileSize(size: number): string {
      const B = 1024;
      const KB = Math.pow(1024, 2);
      const MB = Math.pow(1024, 3);
      const GB = Math.pow(1024, 4);
      if (size === 0) {
        return "0KB";
      } else if (!size) {
        return "_";
      } else if (size < KB) {
        return `${(size / B).toFixed(0)}KB`;
      } else if (size < MB) {
        return (size / KB).toFixed(1) + "MB";
      } else if (size < GB) {
        return (size / MB).toFixed(2) + "GB";
      } else {
        return (size / GB).toFixed(3) + "TB";
      }
    },
    // 时间格式化
    timeFormat(time: string | number) {
      return dayjs(time).format("MM-DD HH:mm");
    },
  },
})
export default class TransferList extends Vue {
  public loading: boolean = false;
  public requestParams: any = {
    isPage: 1,
    pageNum: 1,
    pageSize: 6,
    taskType: "",
  };
  public recordList: any[] = [];
  public total: number = 0;

  public converterTimer: any = null; // 重试转换的计时器

  // 获取数据
  queryList() {
    this.loading = true;
    this.$apis
      .taskHistory(this.requestParams)
      .then((res: any) => {
        if (res.code === "200") {
          this.recordList = res.data.list;
          this.total = res.data.total;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // 继续上传
  continueUpload(value: any): void {
    this.$store.commit("upload/SET_UPLOADCLICK", value.parentId);
  }

  // 转换失败的重试
  async retry(row: any) {
    const params = {
      historyId: row.id,
    };

    try {
      const res = await this.$apis.converterRetryHttp(params);
      console.log("重试转换列表的返回--", res);
      this.queryList();
    } catch (error) {
      console.log("重试转换失败", error);
    }
    console.log("---重试转换的参数-------------", params);
  }

  // 启用converterTimer计时器--用于文件转换失败重试的需求
  starConverterTimerFn() {
    if (!this.converterTimer) {
      this.converterTimer = setInterval(() => {
        // 计时器内调用
        this.queryList();
      }, 7000); // 每3秒检查一次状态
    }
  }
  // 关闭converterTimer计时器--用于文件转换失败重试的需求
  endConverterTimerFn() {
    if (this.converterTimer) {
      clearInterval(this.converterTimer); // 清除计时器
      this.converterTimer=null
    }
  }

  // 文件预览
  // 预览结果文件
  viewFile(val: any) {
    // actionFileFullName:`${val.userFileName}.${val.userFileSize}`,
    let tempFile = {
      ...val,
      moduleType: "doc",
      actionFileid: val.moduleId,
      actionFileSize: publicFormatSize(val.fileSize),
      actionFileFullName: val.fileName,
    };
    publicFileView(tempFile);
  }

  // 去到所在文件夹
  goToFolder(value: any): void {
    console.log("----value", value);

    // this.$router.push({ path: "/star/mydoc", query: params });

    let params = {
      id: value.parentId,
      fileId: value.moduleId,
      _t: Date.now(),
    };
    let data = {
      teamId: value.teamId,
      id: value.parentId,
      fileId: value.moduleId,
      _t: Date.now(),
    };

    console.log("--去到所在目录----------params", params);

    // 这时候表明是在企业团队里
    if (value.moduleType == "team") {
      //@ts-ignore
      this.$router.push({ name: "VIEW_COMPANYTEAM_DOCUMENT", query: data });
      // this.$router.push({ path: "/star/mydoc", query: params });
    } else {
      //@ts-ignore
      this.$router.push({ path: "/star/mydoc", query: params });
    }

    this.$store.commit("menu/SET_TRANSFERSHOW", false);
  }
  // 分页更变
  paginationChange(page: any, pageSize: any) {
    console.log(page, pageSize);
    this.requestParams.pageNum = page;
    this.queryList();
  }

  // 删除
  handleDelete(value: any): void {
    let that = this;
    this.$confirm({
      title: "提示",
      content: "是否要删除该记录 ?",
      okText: "是",
      cancelText: "否",
      onOk() {
        that.$apis
          .taskHistoryDelete({
            ids: [value.id],
          })
          .then((res: any) => {
            if (res.code === "200") {
              that.$message.success("删除成功");
              that.queryList();
            }
          });
      },
    });
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop()
  readonly type!: string;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数

  @Watch("recordList", { deep: true, immediate: true })
  handler(newVal: any) {
        console.log('this.type === "converter"',this.type === 'converter');
        
        if(newVal.length && this.type === 'converter') {
            // 类别中数据的状态至少有一个是转换中 
            if(newVal.some((item:any) => item.taskStatus === "uncompleted")) {
              // 这种情况开启计时器
              this.starConverterTimerFn() // 开启计时器
            } else {
              this.endConverterTimerFn() // 销毁计时器
            }
        }
  }
  

  created(): void {
    this.requestParams.taskType = this.type;
    this.queryList();
  }
  // 销毁前的逻辑  
  onUnmounted():void {
    this.endConverterTimerFn() // 销毁计时器
  }
  
}
