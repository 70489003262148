const splitChatMsg = (resText) => {
  if (!resText || typeof resText !== "string") return "";
  const lastIndex = resText.lastIndexOf("\n", resText.length - 2);
  let chunk = resText;
  let message = "";
  if (lastIndex !== -1) chunk = resText.substring(lastIndex);

  try {
    const data = JSON.parse(chunk);
    message = data.text;
    return message || "";
  } catch {
    return "";
  }
};

const splitChatAllData = (resText) => {
  if (!resText || typeof resText !== "string") return "";
  const lastIndex = resText.lastIndexOf("\n", resText.length - 2);
  let chunk = resText;
  let message = "";
  if (lastIndex !== -1) chunk = resText.substring(lastIndex);

  try {
    const data = JSON.parse(chunk);
    message = data;
    return message || "";
  } catch {
    return "";
  }
};

export { splitChatMsg, splitChatAllData };
