import { Team } from "@/interface/index";
import apis from "@/apis/index"

const share = {
  namespaced: true,
  state: {
    teamInvitedLoginShow: false,
    currentTeamAuthority: 'user',
    loading: false,
    teamList: [],
  },
  mutations: {
    SET_TEAMINVITEDLOGINSHOW: (state: Team, payload: boolean) => {
      state.teamInvitedLoginShow = payload
    },
    SET_CURRENTTEAMAUTHORITY: (state: Team, payload: string) => {
      state.currentTeamAuthority = payload
    },
    SET_LOADING(state: Team, payload: boolean) {
      state.loading = payload
    },
    SET_TEAMLIST(state: Team, status: any) {
      state.teamList = status
    },
  },
  actions: {
    GET_TEAMLIST(store: any, companyId: string) {
      store.commit('SET_LOADING', true)
      apis.companyTeamPageList({
        companyId,
        isPage: 0,
      }).then((res: any) => {
        if (res.code === '200') {
          let teamList=res.data.list
          teamList.sort((a: any, b: any) => a.isStaff === 1 ? -1 : b.isStaff === 1 ? 1 : 0)
          // teamList.sort((a: any, b: any) => a.teamName === 'v回家' ? -1 : b.teamName === 'v回家' ? 1 : 0)
          store.commit('SET_TEAMLIST', teamList)
        }
      }).catch((err)=>{
        store.commit('SET_TEAMLIST', [])
        console.log('获取企业团队报错err',err);
        
      })
      .finally(() => {
        store.commit('SET_LOADING', false)
      })
    },
  },
};
export default share;
