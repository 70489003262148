import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import common from "./common/index";
import "@/assets/iconfont/iconfont.js";
import "video.js/dist/video-js.css";
import "@/assets/iconfont/iconfont.css";
import "./assets/css/antd.less";
import uploader from "vue-simple-uploader";
import VueClipboard from "vue-clipboard2"; //复制到剪贴板插件
import functionalCheckMixins from "@/mixins/functionalCheckMixins";
// import "postcss-pxtorem";

// 引入AI智能功能
import '@/utils/danaAi/recorder/recorder-core.js'
import '@/utils/danaAi/recorder/pcm.js'
import '@/utils/danaAi/recorder/wav.js'

// 判断是否是移动端还是桌面端的插件
import VueDeviceDetector from "vue-device-detector";
Vue.use(VueDeviceDetector);

// @ts-ignore
import actions from "./actions";

declare module "vue/types/vue" {
  interface Vue {
    $apis: any;
  }
}

Vue.use(common);
Vue.use(VueClipboard);
Vue.use(uploader);
Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;
Vue.mixin(functionalCheckMixins);

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

let instance: Vue | undefined = undefined;

let route = null;

// const rootElement = document.querySelector(':root');
// if (rootElement) {
//   // @ts-ignore
//   rootElement.style.setProperty('font-size', '14px');
// }
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
localStorage.isMobile = isMobile();
function render(props: any | undefined = {}) {
  // 获取设备型号
  // const os = (function () {
  //   var ua = navigator.userAgent,
  //     isWindowsPhone = /(?:Windows Phone)/.test(ua),
  //     isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
  //     isAndroid = /(?:Android)/.test(ua),
  //     isFireFox = /(?:Firefox)/.test(ua),
  //     isChrome = /(?:Chrome|CriOS)/.test(ua),
  //     isTablet =
  //       /(?:iPad|PlayBook)/.test(ua) ||
  //       (isAndroid && !/(?:Mobile)/.test(ua)) ||
  //       (isFireFox && /(?:Tablet)/.test(ua)),
  //     isPhone = /(?:iPhone)/.test(ua) && !isTablet,
  //     isPc = !isPhone && !isAndroid && !isSymbian;
  //   return {
  //     isTablet: isTablet,
  //     isPhone: isPhone,
  //     isAndroid: isAndroid,
  //     isPc: isPc,
  //   };
  // })();

  // if (os.isAndroid || os.isPhone) {
  //   store.commit("common/SET_NAVIGATOR", "Phone");
  // } else if (os.isTablet) {
  //   store.commit("common/SET_NAVIGATOR", "Tablet");
  // } else if (os.isPc) {
  //   store.commit("common/SET_NAVIGATOR", "Pc");
  // }

  // 判断是否为子应用方式启动

  Object.defineProperty(Vue.prototype, "IS_MICRO_APP", {
    // @ts-ignore
    value: window.__POWERED_BY_QIANKUN__ || false,
  });

  if ((window as any).__POWERED_BY_QIANKUN__ && props) {
    actions.setActions(props);
  }

  // 改为微应用嵌入365
  // 从365的缓存里获取部分用户信息
  store.dispatch("user/USER_KEEP");

  console.log(
    "是否个人企业",
    (store.state as any).user.personalMailboxEnterprise
  );

  // 获取用户个人和企业容量
  // store.dispatch('user/GET_CAPACITY')

  // 获取挂载容器dom
  const { container } = props;
  const containerId = `#${process.env.VUE_APP_WEB_ROOT_ID}`;
  route = router;
  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
    // }).$mount(container ? container.querySelector('#app') : '#app') // id改为跟index.html中的相同
  }).$mount(container ? container.querySelector(containerId) : containerId);
}
// debugger
// @ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

// export async function unmount() {
//   // @ts-ignore
//   instance.$destroy()
//   instance = undefined
//   route = undefined
// }

export async function bootstrap() {
  // const rootElement = document.querySelector(':root');
  // if (rootElement) {
  //   // @ts-ignore
  //   rootElement.style.setProperty('font-size', '14px');
  // }
  console.log("子应用 引导 成功");
  // TODO 子应用 引导 成功
  const rootElement = document.querySelector(":root");
  if (rootElement) {
    // 设置根元素的字体大小为14px
    // @ts-ignore
    rootElement.style.setProperty("font-size", "14px");
  }
  // TODO 子应用 引导 成功
}

// @ts-ignore
export async function mount(props) {
  // TODO 子应用 挂载 成功
  // 挂载成功，设置定时刷新容量

  // TODO 暂时不调用
  // syncStorage()

  // 初始进来获取一次容量
  store.dispatch("user/GET_CAPACITY");

  // 启用容量定时器
  store.commit("user/startTimer");

  // 初始化文档工具所有可维护数据及状态重置
  store.commit("docTool/clearAllDocToolData");
  console.log("子应用挂载成功");

  // 调用应用锁
  setTimeout(() => {
    store.commit("appLock/setAppLock", true);
    console.log("调用应用锁");

    // 获取是否开启了敏感词审核
    store.dispatch("user/async_setIsAuditValue");
    console.log("调用否开启了敏感词审核");

    store.dispatch("knowledgeBase/async_setKnowledgeBaseList_ByHttp");
  }, 10);

  store.commit("user/setContainer", props.container);
  render(props);
}

export async function unmount() {
  console.log("子应用 销毁 成功");
  // 离开这个子应用了，销毁定时器

  // @ts-ignore
  if (store.state?.user?.capacityTimer) {
    console.log("123456");
    // 销毁容量定时器
    store.commit("user/stopTimer");
  }

  // TODO 子应用 销毁 成功
  // @ts-ignore
  instance.$destroy();
  // @ts-ignore
  instance = null;
  route = null;

  // 初始化文档工具所有可维护数据及状态重置
  store.commit("docTool/clearAllDocToolData");
}

// @ts-ignore
export async function update(props) {
  // TODO 子应用 更新 成功
  console.log("子应用 更新 成功");
  store.commit("user/setProjectInfo", props.project);
}
