// import { Team } from "@/interface/index";
// import apis from "@/apis/index"

const appLock = {
  namespaced: true,
  state: {
    appLockFlag: false,
  },
  mutations: {
    setAppLock: (state: any, val:any) => {
      state.appLockFlag = val
    },
  },
  actions: {

  },
};
export default appLock;


