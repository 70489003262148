// watermark.js

function generateWatermark(params) {
  let adjustedOpacity; // 适当修正透明度
  if (params.opacity < 0.5) {
    adjustedOpacity = params.opacity + Math.abs(params.opacity - 0.5) * 0.4;
  } else {
    adjustedOpacity = params.opacity - Math.abs(params.opacity - 0.5) * 0.4;
  }

  adjustedOpacity = Math.max(0, Math.min(1, adjustedOpacity));

  const targetDom = document.getElementById(params.targetDomId);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = targetDom && targetDom.offsetWidth;
  canvas.height =targetDom && targetDom.offsetHeight;

  ctx.font = `${params.fontSize}px Microsoft YaHei`; // Default to Microsoft YaHei
  ctx.fillStyle = `rgba(20, 20, 20, ${adjustedOpacity})`;
  ctx.globalAlpha = adjustedOpacity;
  let x = 0;
  let y = 0;
  while (y < canvas.height) {
    x = 0;
    while (x < canvas.width) {
      ctx.save();
      ctx.translate(x, y);
      // ctx.rotate(-params.rotation);
      ctx.rotate(-params.rotation * (Math.PI / 180));
      ctx.fillText(params.watermarkText, 0, 0);
      ctx.restore();
      x += params.widthSpacer + ctx.measureText(params.watermarkText).width;
    }
    y += params.heightSpacer + (params.fontSize)*(params.fontSize/40);
  }

  // const imageData = canvas.toDataURL();
  // targetDom.style.background = `url(${imageData})`;
  // targetDom.style.backgroundRepeat = "repeat";
  // targetDom.style.backgroundSize = "100% 100%";

  const imageData = canvas.toDataURL();
  targetDom.style.background = `url(${imageData}) center center`;
  targetDom.style.backgroundRepeat = "no-repeat";
  targetDom.style.backgroundSize = "auto";
}

export { generateWatermark };
